import FetchWrapper from './FetchWrapper';
import TokenManager from './TokenManager';
import {
    ChangePasswordRequest,
    LoginRequest,
    LoginResponse,
    SignupRequest,
    VerificationOptions,
} from '../types/networkTypes';
import { ReminderOptions, User } from '../types/userTypes';

export const loginUser = async (request: LoginRequest): Promise<User | null> => {
    const res = await FetchWrapper({
        path: 'auth/login',
        method: 'POST',
        body: request,
    });
    const { jwt, user }: LoginResponse = await res.json();
    await setUserTokensAndExpirationHandler({ jwt });
    return user;
};

export const signupUser = async (request: SignupRequest): Promise<User | null> => {
    const res = await FetchWrapper({
        path: 'auth/signup',
        method: 'POST',
        body: request,
    });

    const { user, jwt }: LoginResponse = await res.json();
    await setUserTokensAndExpirationHandler({ jwt });
    return user;
};

export const logoutUser = async (): Promise<void> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');
    await FetchWrapper({
        path: 'auth/logout',
        method: 'POST',
        token,
    });
};

export const requestPasswordReset = async (email: string): Promise<void> => {
    await FetchWrapper({
        path: 'auth/forgotpassword',
        method: 'POST',
        body: { email },
    });
};

export const changePassword = async (request: ChangePasswordRequest): Promise<void> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');
    await FetchWrapper({
        path: 'auth/changepassword',
        method: 'POST',
        token,
        body: request,
    });
};

export const verificationRequest = async (attribute: VerificationOptions): Promise<void> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');
    await FetchWrapper({
        path: 'auth/verificationrequest',
        method: 'POST',
        token,
        body: { attribute },
    });
};

export const verificationConfirm = async (verificationCode: string): Promise<ReminderOptions> => {
    const res = await FetchWrapper({
        path: 'auth/verificationconfirm',
        method: 'POST',
        body: { verificationCode },
    });
    const { attribute } = await res.json();
    return attribute;
};

async function setUserTokensAndExpirationHandler({ jwt }: Pick<LoginResponse, 'jwt'>): Promise<void> {
    const { accessToken, refreshToken } = jwt;
    await Promise.all([
        TokenManager.setAccessToken(accessToken),
        TokenManager.setRefreshToken(refreshToken),
        TokenManager.setAccessTokenExpirationHandler(async () => {
            await logoutUser();
            TokenManager.clearTokens();
        }),
    ]);
}
