export enum EditProfileScenarios {
    ADDING_PROFILE_IMAGE = 'ADDING_PROFILE_IMAGE',
    CHANGING_PROFILE_IMAGE = 'CHANGING_PROFILE_IMAGE',
    REMOVING_PROFILE_IMAGE = 'REMOVING_PROFILE_IMAGE',
    CHOOSING_AVATAR_COLOR = 'CHOOSING_AVATAR_COLOR',
    RESET_EDIT_PROFILE_SCENARIO = 'RESET_EDIT_PROFILE_SCENARIO',
}

export const initialEditProfileState = {
    [EditProfileScenarios.ADDING_PROFILE_IMAGE]: false,
    [EditProfileScenarios.CHANGING_PROFILE_IMAGE]: false,
    [EditProfileScenarios.REMOVING_PROFILE_IMAGE]: false,
    [EditProfileScenarios.CHOOSING_AVATAR_COLOR]: false,
};

export default (
    state = initialEditProfileState,
    action: { type: EditProfileScenarios; callback?: () => void }
) => {
    if (action.type === EditProfileScenarios.RESET_EDIT_PROFILE_SCENARIO) {
        return initialEditProfileState;
    } else {
        action.callback?.();
        return { ...initialEditProfileState, [action.type]: true };
    }
};
