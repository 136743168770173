import React, { useContext } from 'react';
import { UserContext } from '../context/UserContextProvider';
import NonAuthNav from './NonAuthNav';
import AuthNav from './AuthNav';

const NavBar: React.FC = (): JSX.Element | null => {
    const { currentUser } = useContext(UserContext);

    if (currentUser === null) {
        return <NonAuthNav />;
    } else if (currentUser) {
        return <AuthNav />;
    }
    return null;
};

export default NavBar;
