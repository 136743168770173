const useURLParams = (params: string[] | string): string[] => {
    const results: string[] = [];
    const deriveParamRegex = (param: string) => new RegExp(`${param}=([^&]*)`, 'ig');
    Array.isArray(params)
        ? params.forEach(param => {
              results.push(window.location.href.match(deriveParamRegex(param))?.[0].split('=')[1] ?? '');
          })
        : results.push(window.location.href.match(deriveParamRegex(params))?.[0].split('=')[1] ?? '');
    return results;
};

export default useURLParams;
