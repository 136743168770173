import React from 'react';
import styled, { keyframes } from 'styled-components';
import { RFC } from '../types/generalTypes';

type PageSpinnerProps = {
    type: 'page';
    className?: string;
};
type ButtonSpinnerProps = {
    type: 'button';
    loading: number;
    color?: string;
    className?: string;
};

//if type button, ensure that loading is passed
const LoadingSpinner: RFC<PageSpinnerProps | ButtonSpinnerProps> = props =>
    props.type === 'button' ? (
        <ButtonSpinner loading={props.loading ? 1 : 0} className={props.className} />
    ) : (
        <PageSpinner>
            <circle className="path" cx="50" cy="50" r="40" fill="none" strokeWidth="3" />
        </PageSpinner>
    );

export default LoadingSpinner;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }`;

const ButtonSpinner = styled.div<Pick<ButtonSpinnerProps, 'loading' | 'color'>>`
    border: 4px solid ${({ color = 'rgba(255, 255, 255, 0.5)' }) => color};
    border-radius: 50%;
    border-top-color: white;
    opacity: ${({ loading }) => (!!loading ? 1 : 0)};
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    width: 24px;
    height: 24px;
    transition: opacity 200ms;
    animation: ${rotate} 1s linear;
    animation-iteration-count: infinite;
    transition-delay: ${({ loading }) => (!!loading ? '200ms' : '0ms')};
`;

const PageSpinner = styled.svg`
    animation: rotate 1s linear infinite;
    margin: 40px auto;
    width: 100px;
    height: 100px;

    & .path {
        stroke: #63b3ed;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 300;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 180, 300;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 180, 200;
            stroke-dashoffset: -124;
        }
    }
`;
