import { User } from './userTypes';
export enum NavStateKeys {
    ENCOURAGE_GROUP_CREATION = 'ENCOURAGE_GROUP_CREATION',
    INVITE_ACCEPTED = 'INVITE_ACCEPTED',
    EMAIL_VERIFIED = 'EMAIL_VERIFIED'
}

export enum URLParams {
    INVITE_ID = 'id',
    GROUP_ID = 'groupId',
    PASSWORD = 'password',
    EMAIL = 'email',
    VERIFICATION_CODE = 'verificationCode',
}

export type FetchRequest<T, E = unknown> = { fetching: boolean; data: T; error: E | null };
export interface Error {
    code: ErrorCodes;
    message: string;
}
export interface LoginRequest {
    email: string;
    password: string;
    newPassword?: string;
}

export interface LoginResponse {
    jwt: {
        idToken: string;
        accessToken: string;
        refreshToken: string;
        expiresIn: number;
    };
    user: User;
}

export interface RefreshRequest {
    refreshToken: string;
}

export interface RefreshResponse {
    jwt: {
        token: string;
        accessToken: string;
        expiresIn: number;
    };
}

export interface SignupRequest {
    email: string;
    password: string;
    displayName: string;
    timeZone: number;
}

export interface ChangePasswordRequest {
    password: string;
    newPassword: string; // must be a minimum of 8 characters long
}

export enum VerificationOptions {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
}

export interface VerificationConfirmRequest {
    attribute: VerificationOptions;
    verificationCode: string;
}

export enum ErrorCodes {
    //400
    DATA_VALIDATION = 'DATA_VALIDATION',
    BAD_USERNAME = 'BAD_USERNAME',
    PASSWORD_RESET = 'PASSWORD_RESET',
    DUPLICATE_DATA = 'DUPLICATE_DATA',
    INVALID_INVITATION = 'INVALID_INVITATION',
    TOO_MANY_ENTRIES = 'TOO_MANY_ENTRIES',
    LAST_MODERATOR = 'LAST_MODERATOR',

    //401
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    AUTH_FAILURE = 'AUTH_FAILURE',

    //403
    UNAUTHORIZED = 'UNAUTHORIZED',
    INVALID_REFRESH_TOKEN = 'INVALID_REFRESH_TOKEN',

    //404
    NOT_FOUND = 'NOT_FOUND',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}
