import { useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContextProvider';
import TokenManager from '../api/TokenManager';
import { navigate } from '@reach/router';
import { NonAuthRoutes } from '../routes';

export default () => {
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            const validAuthToken = await TokenManager.getAccessToken();
            try {
                if (!validAuthToken) {
                    await navigate(NonAuthRoutes.LOGIN);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [currentUser]);
};
