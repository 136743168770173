import React, { useContext, useEffect, useState } from 'react';
import { RFC } from '../types/generalTypes';
import { Error, FetchRequest } from '../types/networkTypes';
import { Entry as EntryType } from '../types/entryTypes';
import { getApprovedPublicEntries } from '../api/entry';
import PublicEntry from '../components/PublicEntry';
import { Link } from '@reach/router';
import { NonAuthRoutes } from '../routes';
import { UserContext } from '../context/UserContextProvider';
import MoreEntriesLoader from '../components/MoreEntriesLoader';
import LoadingSpinner from '../components/LoadingSpinner';
import Entry from '../components/Entry';
// @ts-ignore
import Logo from '../img/3GT-logo.png';

const PositivityFeed: RFC = () => {
    const [entries, setEntries] = useState<FetchRequest<EntryType[], Error>>({
        fetching: true,
        data: [],
        error: null,
    });
    const [page, setPage] = useState<{ current: number; totalPages?: number }>({ current: 1 });
    const [loadingMoreEntries, setLoadingMoreEntries] = useState<boolean>(false);
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            try {
                const { entries, pages } = await getApprovedPublicEntries({ page: page.current });
                setEntries(prev => ({
                    ...prev,
                    data: page.current === 1 ? entries : [...prev.data, ...entries],
                }));
                setPage(prev => ({ ...prev, totalPages: pages }));
            } catch (error) {
                error = error.json ? await error.json() : error;
                console.log(error);
                setEntries(prev => ({ ...prev, error }));
            }
            setEntries(prev => ({ ...prev, fetching: false }));
        })();
    }, [page.current]);

    const loadMoreEntries = () => {
        setLoadingMoreEntries(true);
        setPage(prev => ({ ...prev, current: prev.current + 1 }));
        setTimeout(() => {
            setLoadingMoreEntries(false);
        }, 1000);
    };

    return (
        <div className="p-5">
            <>
                {currentUser === null && (
                    <div className="mx-auto text-center">
                        <img
                            src={Logo}
                            alt="The 3 good things logo"
                            className="mx-auto mb-4 h-24 md:h-32"
                        />
                    </div>
                )}
                <div className="w-full flex flex-wrap font-semibold flex-col justify-center items-center md:pl-3">
                    <h2 className="text-3xl text-blue-900 block mb-2">Positivity Feed</h2>
                    <p className="text-lg text-gray-600">
                        Curated public entries from members of The Three Good Things community
                    </p>
                    {currentUser === null && (
                        <div className="w-full md:max-w-3xl flex flex-col justify-center mt-3 text-left">
                            <p className="text-gray-600 font-normal">
                                The 3 Good Things is all about taking time once a day to write down
                                three good things that happened today. Studies have shown this
                                simple exercise, practiced daily, leads to higher satisfaction and
                                sense of fulfillment. This page is a sample of the entries that
                                people have made today and chosen to share with the world. Anyone
                                can join and begin reflecting on and sharing the things they are
                                grateful for each day.
                            </p>
                            <Link to={NonAuthRoutes.REGISTER} className="self-center mt-5">
                                <button
                                    className="rounded-lg font-semibold hover:bg-blue-600 px-2 py-1 bg-blue-500 flex flex-row items-center text-white shadow-md"
                                    style={{ left: '-20px', bottom: '-40px', fontSize: '1rem' }}
                                >
                                    <svg
                                        width="26"
                                        height="26"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5 3V7M3 5H7M6 17V21M4 19H8M13 3L15.2857 9.85714L21 12L15.2857 14.1429L13 21L10.7143 14.1429L5 12L10.7143 9.85714L13 3Z"
                                            stroke="#fff"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <span className="text-lg">Start your journal</span>
                                </button>
                            </Link>
                        </div>
                    )}
                </div>
                <div className="w-full md:max-w-3xl mx-auto md:p-5 mt-4">
                    {entries.fetching ? (
                        <LoadingSpinner type="page" />
                    ) : (
                        entries.data.map(entry =>
                            currentUser ? (
                                <Entry
                                    key={entry._id}
                                    entry={entry}
                                    updateEntryInLocalState={(updatedEntry: EntryType) =>
                                        setEntries(prev => ({
                                            ...prev,
                                            data: prev.data.map(entry =>
                                                entry._id === updatedEntry._id
                                                    ? updatedEntry
                                                    : entry
                                            ),
                                        }))
                                    }
                                />
                            ) : (
                                <PublicEntry key={entry._id} entry={entry} />
                            )
                        )
                    )}
                </div>
                <MoreEntriesLoader
                    initialPageFetching={entries.fetching}
                    loadingMoreEntries={loadingMoreEntries}
                    loadMoreEntries={loadMoreEntries}
                    activePage={page.current}
                    totalPages={page.totalPages}
                />
            </>
        </div>
    );
};

export default PositivityFeed;
