import React from 'react';
import { RFC } from '../types/generalTypes';

const LoadMoreButton: RFC<{ onClick: () => void }> = ({ onClick }) => (
    <button
        onClick={onClick}
        className="bg-blue-500 hover:bg-blue-700 text-white rounded-md px-2 py-1 font-semibold border-none"
    >
        Load More
    </button>
);

export default LoadMoreButton;
