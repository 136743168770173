export enum ProfileModalScenarios {
    CHANGING_PASSWORD = 'CHANGING_PASSWORD',
    DELETING_ENTRY = 'DELETING_ENTRY',
    EDITING_ENTRY_PRIVACY = 'EDITING_ENTRY_PRIVACY',
    DELETING_PROFILE_IMAGE = 'DELETING_PROFILE_IMAGE',
    RESET_MODAL_STATE = 'RESET_MODAL_STATE',
}

export const initialProfileModalState = {
    [ProfileModalScenarios.CHANGING_PASSWORD]: false,
    [ProfileModalScenarios.DELETING_ENTRY]: false,
    [ProfileModalScenarios.EDITING_ENTRY_PRIVACY]: false,
    [ProfileModalScenarios.DELETING_PROFILE_IMAGE]: false,
};

export default (
    state = initialProfileModalState,
    action: { type: ProfileModalScenarios; callback?: () => void }
) => {
    if (action.type === ProfileModalScenarios.RESET_MODAL_STATE) {
        return initialProfileModalState;
    } else {
        action.callback?.();
        return { ...initialProfileModalState, [action.type]: true };
    }
};
