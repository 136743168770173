import React from 'react';
import { RFC } from '../types/generalTypes';

const SuccessAlert: RFC<{ message: string }> = ({ message }) => (
    <div className="flex flex-col justify-center">
        <h3 className="text-lg text-gray-700 text-center">{message}</h3>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="120"
            height="120"
            fill="#00BF71"
            className="mx-auto"
        >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z " />
        </svg>
    </div>
);

export default SuccessAlert;
