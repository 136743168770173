import React, { useContext, useEffect, useState } from 'react';
import { Error, FetchRequest } from '../types/networkTypes';
import { GroupResponse } from '../types/groupTypes';
import { createGroup, getGroups, redeemInvitation, rejectInvitation } from '../api/group';
import { Link } from '@reach/router';
import { AuthRoutes } from '../routes';
import PageHeader from '../components/PageHeader';
import { FormSubmission, RFC } from '../types/generalTypes';
import Modal from '../components/Modal';
import SubmitButton from '../components/SubmitButton';
import ErrorMessage from '../components/ErrorMessage';
import AutoGrid from '../components/AutoGrid';
import { UserContext } from '../context/UserContextProvider';
import { getInvitations } from '../api/user';
import { GroupInvitationType } from '../types/invitationTypes';
import { truncate } from '../utils';
import { ReminderOptions } from '../types/userTypes';
import { LAST_GROUP_ID_VIEWED_BY_USER, USER_DISMISSED_REMINDERS_ENCOURAGEMENT } from '../globals';
import InfoBanner from '../components/InfoBanner';
import { getEntries } from '../api/entry';
import LoadingSpinner from '../components/LoadingSpinner';
import useSessionStorage from '../hooks/useSessionStorage';
import IconManager, { IconType } from '../components/IconManager';
import { Analytics } from '../util/Analytics';
import isAuthed from '../hooks/isAuthed';

const Groups: RFC = () => {
    isAuthed();
    const [groupsData, setGroupsData] = useState<
        FetchRequest<{ groups: GroupResponse[]; invitations: GroupInvitationType[] }, Error>
    >({
        fetching: true,
        data: { groups: [], invitations: [] },
        error: null,
    });
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [newGroupName, setNewGroupName] = useState<string>('');
    const [submittingNewGroup, setSubmittingNewGroup] = useState<boolean>(false);
    const [submitNewGroupError, setSubmitNewGroupError] = useState<Error | null>(null);
    const { currentUser } = useContext(UserContext);
    const [userHasDismissedReminderNotif, setUserHasDismissedReminderNotif] = useSessionStorage(
        USER_DISMISSED_REMINDERS_ENCOURAGEMENT
    );
    const [userHasZeroEntries, setUserHasZeroEntries] = useState<boolean>();

    const getGroupData = async () => {
        // get groups user is part of and invitations to groups a user is invited to
        try {
            const [groups, invitations, { entries }] = await Promise.all([
                getGroups(),
                getInvitations(),
                getEntries(),
            ]);
            setUserHasZeroEntries(!entries.length);
            setGroupsData(prev => ({ ...prev, data: { groups, invitations } }));
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setGroupsData(prev => ({ ...prev, error }));
        }
    };

    const handleAcceptInvitation = async (invitationId: string) => {
        Analytics.Event.InvitationAccepted.track();
        await redeemInvitation(invitationId);
        await getGroupData();
    };

    const handleDeclineInvitation = async (invitationId: string) => {
        Analytics.Event.InvitationDeclined.track();
        await rejectInvitation(invitationId);
        await getGroupData();
    };

    useEffect(() => {
        (async () => {
            await getGroupData();
            //ensure the last group viewed id is cleared from storage
            sessionStorage.setItem(LAST_GROUP_ID_VIEWED_BY_USER, '');
            setGroupsData(prev => ({ ...prev, fetching: false }));
        })();
    }, []);

    const createNewGroup = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        setSubmittingNewGroup(true);
        try {
            const newGroup = await createGroup(newGroupName);
            setGroupsData(prev => ({
                ...prev,
                data: { ...prev.data, groups: [...prev.data.groups, newGroup] },
            }));
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setSubmitNewGroupError(error);
        }
        setModalOpen(false);
        setSubmittingNewGroup(false);
    };

    return (
        <div className="p-5">
            {modalOpen && (
                <Modal isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
                    <h2 className="text-center text-xl font-bold">Create Group</h2>
                    <div className="w-full px-5 py-3">
                        <form onSubmit={createNewGroup}>
                            <label htmlFor="groupName" className="block">
                                <span className="text-gray-700 text-xl mb-2 text-center px-5 block">
                                    What would you like your new group to be called?
                                </span>
                                <span className="block text-sm text-gray-500 text-center mt-2">
                                    You will become moderator of this group.
                                </span>
                                <input
                                    type="text"
                                    name="groupName"
                                    className="form-input mt-1 block w-full outline-none rounded border-gray-200"
                                    onChange={e => setNewGroupName(e.target.value)}
                                />
                            </label>
                            <SubmitButton
                                loading={submittingNewGroup}
                                disabled={!newGroupName}
                                className="bg-green-500 hover:bg-green-700 mt-5 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                            >
                                CREATE
                            </SubmitButton>
                            {(submitNewGroupError || groupsData.error) && (
                                <ErrorMessage
                                    code={submitNewGroupError?.code || groupsData.error?.code}
                                />
                            )}
                        </form>
                    </div>
                </Modal>
            )}
            <PageHeader title="Your groups" />
            {groupsData.fetching ? (
                <LoadingSpinner type="page" />
            ) : (
                <>
                    {currentUser?.reminders === ReminderOptions.NONE &&
                        !userHasDismissedReminderNotif &&
                        !!groupsData.data.groups.length && (
                            <InfoBanner
                                dismiss={() => setUserHasDismissedReminderNotif(true)}
                                emphasis="We see you don't have any reminders set"
                                className="md:ml-4"
                            >
                                <>
                                    <Link
                                        to={AuthRoutes.REMINDERS}
                                        className="underline text-blue-500 hover:text-blue-600 font-semibold"
                                    >
                                        Set a reminder
                                    </Link>{' '}
                                    so you won't forget about your daily entry of your three good
                                    things.
                                </>
                            </InfoBanner>
                        )}
                    <GroupInvitations
                        invitations={groupsData.data.invitations}
                        acceptInvitation={handleAcceptInvitation}
                        declineInvitation={handleDeclineInvitation}
                    />

                    {(submitNewGroupError || groupsData.error) && (
                        <ErrorMessage code={submitNewGroupError?.code || groupsData.error?.code} />
                    )}
                    {!groupsData.data?.groups.length && !groupsData.error ? (
                        <div className={`md:ml-2 mt-3 px-3`}>
                            <p className="text-gray-600">
                                You currently aren't a member of any groups.{' '}
                                <button
                                    className="bg-transparent text-blue-500 hover:text-blue-700 font-semibold"
                                    onClick={() => setModalOpen(true)}
                                >
                                    Create a group
                                </button>{' '}
                                {userHasZeroEntries && (
                                    <>
                                        to begin sharing your three good things with others. You'll
                                        also be able to read their entries. <br />
                                        <span className="block mt-2">
                                            If you prefer to{' '}
                                            <Link
                                                to={AuthRoutes.CREATE_ENTRY}
                                                className="bg-transparent text-blue-500 hover:text-blue-700 font-semibold"
                                            >
                                                create an entry
                                            </Link>{' '}
                                            without being in a group, your entries will remain
                                            private and you also won't be able to read entries from
                                            others.
                                        </span>
                                    </>
                                )}
                            </p>
                        </div>
                    ) : (
                        <AutoGrid className="border border-gray-400 bg-gray-100 py-4 px-5 rounded-md mt-2">
                            {groupsData.data.groups.map(group => (
                                <Link
                                    to={AuthRoutes.GROUP_LIST + `/${group._id}`}
                                    className="no-underline max-w-lg"
                                    key={group._id}
                                >
                                    <div className="bg-white w-full py-5 px-4 flex flex-row justify-start items-center border border-gray-200 rounded-md shadow">
                                        <h3 className="text-lg font-bold">
                                            {truncate(group.name, 24)}
                                        </h3>
                                        <div className="ml-auto flex flex-row items-center">
                                            {group.members.find(
                                                ({ _id }) => _id === currentUser?._id
                                            )?.moderator && (
                                                <IconManager
                                                    type={IconType.GROUP_MODERATOR}
                                                    className="mr-1"
                                                    stroke="#009AE7"
                                                />
                                            )}
                                            <IconManager type={IconType.GROUPS} stroke="#9DAEC2" />
                                            <span className="text-gray-500 ml-1">
                                                {group.members.length}
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </AutoGrid>
                    )}
                    <div className="w-full flex flex-row justify-end items-center mx-auto">
                        <button
                            className="mt-2 px-2 py-1 text-lg font-semibold rounded-md shadow flex flex-row items-center bg-blue-500 hover:bg-blue-700 text-white"
                            onClick={() => setModalOpen(true)}
                        >
                            <IconManager
                                type={IconType.PLUS_SIGN}
                                stroke="#fff"
                                strokeWidth={3}
                                size={20}
                                className="mr-1"
                            />
                            Create group
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Groups;

type GroupInvitationsProps = {
    invitations: GroupInvitationType[];
    acceptInvitation: (invitationId: string) => void;
    declineInvitation: (invitationId: string) => void;
};

const GroupInvitations = ({
    invitations,
    acceptInvitation,
    declineInvitation,
}: GroupInvitationsProps) => (
    <>
        {invitations.map(invitation => (
            <div
                key={invitation.id}
                className="bg-green-100 border border-green-500 rounded-md md:ml-4 w-full md:w-2/3 mt-3 mb-2 text-green-900 px-4 py-2 shadow-md"
                role="alert"
            >
                <div className="flex flex-wrap">
                    <div className="py-1">
                        <svg
                            className="fill-current h-6 w-6 text-green-500 mr-4"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18 9V12M18 12V15M18 12H21M18 12H15M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7ZM3 20C3 16.6863 5.68629 14 9 14C12.3137 14 15 16.6863 15 20V21H3V20Z"
                                stroke="#49BB78"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div>
                        <p>
                            You have been invited to{' '}
                            <span className="font-semibold">{invitation.groupName}</span>
                            {/*by{' '}*/}
                            {/*<span className="font-semibold">{invitation.user.displayName}</span>*/}
                        </p>
                        <div className="flex flex-row justify-start items-center p-2">
                            <button
                                onClick={() => acceptInvitation(invitation.id)}
                                type="button"
                                className="font-semibold text-green-700 mr-3 hover:text-green-900"
                            >
                                Accept
                            </button>
                            <button
                                onClick={() => declineInvitation(invitation.id)}
                                type="button"
                                className="font-semibold text-green-700 hover:text-green-900"
                            >
                                Decline
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </>
);
