export enum AddEntryModalScenarios {
    DELETING_ENTRY = 'DELETING_ENTRY',
    EDITING_ENTRY_PRIVACY = 'EDITING_ENTRY_PRIVACY',
    RESET_MODAL_STATE = 'RESET_MODAL_STATE',
}

export const initialAddEntryModalState = {
    [AddEntryModalScenarios.DELETING_ENTRY]: false,
    [AddEntryModalScenarios.EDITING_ENTRY_PRIVACY]: false,
};

export default (
    state = initialAddEntryModalState,
    action: { type: AddEntryModalScenarios; callback?: () => void }
) => {
    if (action.type === AddEntryModalScenarios.RESET_MODAL_STATE) {
        return initialAddEntryModalState;
    } else {
        action.callback?.();
        return { ...initialAddEntryModalState, [action.type]: true };
    }
};
