import React, { ReactNode } from 'react';
import { RFC } from '../types/generalTypes';

interface Props {
    title?: string;
    children?: ReactNode;
}

const PageHeader: RFC<Props> = ({ title, children }) => (
    <div className="w-full flex flex-wrap font-semibold flex-row justify-start items-center py-5 pl-3">
        <div className="flex flex-row justify-start items-center">
            <h2 className="text-3xl text-blue-900">{title}</h2>
        </div>
        {children && <div>{children}</div>}
    </div>
);

export default PageHeader;
