import React from 'react';
import { RFC } from '../types/generalTypes';
import { ErrorCodes } from '../types/networkTypes';
import { presentErrorBasedOnCode } from '../utils';

interface Props {
    code?: ErrorCodes;
    className?: string;
}

const ErrorMessage: RFC<Props> = ({ code, className }) => (
    <p className={`text-red-500 text-md font-bold text-center mt-3 ${className}`}>
        {code
            ? presentErrorBasedOnCode(code)
            : 'An unknown error has occurred. We apologize for the inconvenience. Please check your internet connection & try again.'}
    </p>
);

export default ErrorMessage;
