import React, { useEffect } from 'react';
import { globalHistory, HistoryListenerParameter, Router } from '@reach/router';
import Login from './pages/Login';
import Register from './pages/Register';
import { AuthRoutes, NonAuthRoutes } from './routes';
import Groups from './pages/Groups';
import GroupDetails from './pages/GroupDetails';
import ForgotPassword from './pages/ForgotPassword';
import AddEntry from './pages/AddEntry';
import Profile from './pages/Profile';
import NotFound from './pages/NotFound';
import MemberDetails from './pages/MemberDetails';
import RedeemInvitation from './pages/RedeemInvitation';
import About from './pages/About';
import Reminders from './pages/Reminders';
import VerificationSuccess from './pages/VerificationSuccess';
import BugReport from './pages/BugReport';
import PositivityFeed from './pages/PositivityFeed';
import ApprovalQueue from './pages/ApprovalQueue';
import SinglePublicEntry from './pages/SinglePublicEntry';
import EmailUnsubscribe from './pages/EmailUnsubscribe';
import NavBar from './components/NavBar';
import PageContainer from './components/PageContainer';
import GroupMembers from './pages/GroupMembers';
import { Analytics } from './util/Analytics';

const App = () => {
    useEffect(() => {
        globalHistory.listen((event: HistoryListenerParameter) => {
            // console.log(`Pathname changed: ${event.location.pathname}`)
            Analytics.pageview(event.location.pathname);
        });
    }, []);

    return (
        <PageContainer>
            <NavBar />
            <Router>
                <About path={NonAuthRoutes.ABOUT} />
                <Login path={NonAuthRoutes.LOGIN} />
                <Login path={NonAuthRoutes.ADMIN_LOGIN} isAdmin={true} />
                <ForgotPassword path={NonAuthRoutes.FORGOT_PASSWORD} />
                <Register path={NonAuthRoutes.REGISTER} />
                <Groups path={AuthRoutes.GROUP_LIST} />
                <GroupDetails path={AuthRoutes.GROUP_LIST + '/:id'} />
                <GroupMembers path={AuthRoutes.GROUP_LIST + '/:id/members'} />
                <AddEntry path={AuthRoutes.CREATE_ENTRY} />
                <Profile path={AuthRoutes.PROFILE} />
                <Reminders path={AuthRoutes.REMINDERS} />
                <VerificationSuccess path={NonAuthRoutes.VERIFICATION_SUCCESS} />
                <MemberDetails path={AuthRoutes.MEMBER_DETAILS + '/:id'} />
                <RedeemInvitation path={NonAuthRoutes.REDEEM_INVITATION} />
                <PositivityFeed path={NonAuthRoutes.POSITIVITY_FEED} />
                <ApprovalQueue path={AuthRoutes.APPROVAL_QUEUE} />
                <SinglePublicEntry path={NonAuthRoutes.ENTRIES + '/:id'} />
                <BugReport path={AuthRoutes.BUG_REPORT} />
                <EmailUnsubscribe path={NonAuthRoutes.EMAIL_UNSUBSCRIBE} />
                <NotFound default />
            </Router>
        </PageContainer>
    );
};
export default App;
