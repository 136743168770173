import React, { memo } from 'react';
import ReactSelect, { components, ControlProps } from 'react-select';
import styled from 'styled-components';
import { RFC, ReactSelectChangeEvent, ReactSelectOption } from '../types/generalTypes';
const { Control } = components;

interface Props {
    options: ReactSelectOption[];
    isLoading?: boolean;
    isDisabled?: boolean;
    onChange: ReactSelectChangeEvent;
    placeholder?: string;
    label?: string;
    selectedOption?: ReactSelectOption;
}

const Select: RFC<Props> = ({
    options,
    isLoading = false,
    isDisabled = isLoading,
    label,
    onChange,
    placeholder,
    selectedOption,
}) => {
    const ControlLabel: React.FC<ControlProps<any>> = (props): JSX.Element | null =>
        label ? (
            <div>
                <Label>{label}</Label>
                <Control {...props} />
            </div>
        ) : null;
    const controlStyles = {
        border: '1px solid #D2D2D2',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #D2D2D2',
            boxShadow: 'none',
            cursor: 'pointer',
        },
    };
    // very annoying, can't conditionally pass Control for label
    return label ? (
        <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={placeholder ? undefined : options[0]}
            value={placeholder ? null : selectedOption}
            options={options}
            placeholder={placeholder}
            components={{ Control: ControlLabel }}
            isLoading={isLoading}
            onChange={onChange}
            isDisabled={isDisabled}
            isSearchable={true}
            styles={{
                container: base => ({ ...base, flex: 1, transitionDuration: '200ms' }),
                control: base => ({
                    ...base,
                    ...controlStyles,
                }),
                option: base => ({ ...base, '&:hover': { cursor: 'pointer' } }),
            }}
        />
    ) : (
        <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={placeholder ? undefined : options[0]}
            value={placeholder ? null : selectedOption}
            options={options}
            placeholder={placeholder}
            isLoading={isLoading}
            onChange={onChange}
            isDisabled={isDisabled}
            isSearchable={true}
            styles={{
                container: base => ({ ...base, flex: 1 }),
                control: base => ({
                    ...base,
                    ...controlStyles,
                }),
                option: base => ({ ...base, '&:hover': { cursor: 'pointer' } }),
            }}
        />
    );
};

export default memo(Select);

const Label = styled.label`
    font-size: 0.9em;
    font-weight: 600;
`;
