export enum RemindersModalScenarios {
    ADDING_PHONE_NUMBER = 'ADDING_PHONE_NUMBER',
    TEXT_MESSAGE_VERIFICATION_SENT = 'TEXT_MESSAGE_VERIFICATION_SENT',
    TEXT_MESSAGE_VERIFICATION_ERROR = 'EMAIL_VERIFICATION_ERROR',
    VERIFYING_EMAIL_CONFIRMATION = 'VERIFYING_EMAIL_CONFIRMATION',
    EMAIL_VERIFICATION_SENT = 'EMAIL_VERIFICATION_SENT',
    EMAIL_VERIFICATION_ERROR = 'EMAIL_VERIFICATION_ERROR',
    DELETING_REMINDER = 'DELETING_REMINDER',
    SUGGEST_REMINDER_TIME_OF_DAY = 'SUGGEST_REMINDER_TIME_OF_DAY',
    RESET_MODAL_STATE = 'RESET_MODAL_STATE',
}

export const initialRemindersModalState = {
    [RemindersModalScenarios.ADDING_PHONE_NUMBER]: false,
    [RemindersModalScenarios.TEXT_MESSAGE_VERIFICATION_SENT]: false,
    [RemindersModalScenarios.TEXT_MESSAGE_VERIFICATION_ERROR]: false,
    [RemindersModalScenarios.VERIFYING_EMAIL_CONFIRMATION]: false,
    [RemindersModalScenarios.EMAIL_VERIFICATION_SENT]: false,
    [RemindersModalScenarios.EMAIL_VERIFICATION_ERROR]: false,
    [RemindersModalScenarios.SUGGEST_REMINDER_TIME_OF_DAY]: false,
};

export default (
    state = initialRemindersModalState,
    action: { type: RemindersModalScenarios; callback?: () => void }
) => {
    if (action.type === RemindersModalScenarios.RESET_MODAL_STATE) {
        return initialRemindersModalState;
    } else {
        action.callback?.();
        return { ...initialRemindersModalState, [action.type]: true };
    }
};
