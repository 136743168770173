import React, { useContext, useEffect, useState } from 'react';
import SplashPageContainer from '../components/SplashPageContainer';
import SubmitButton from '../components/SubmitButton';
import ErrorMessage from '../components/ErrorMessage';
import { Error, NavStateKeys, SignupRequest, URLParams } from '../types/networkTypes';
import { UserContext } from '../context/UserContextProvider';
import { signupUser } from '../api/auth';
import {
    ButtonClick,
    FormSubmission,
    InputChange,
    ReactSelectOption,
    ReactSelectValue,
    RFC,
} from '../types/generalTypes';
import Select from '../components/Select';
import { timeZones } from '../timeZones';
import { Link, navigate } from '@reach/router';
import { AuthRoutes, NonAuthRoutes } from '../routes';
import { redeemInvitation } from '../api/group';
import useURLParams from '../hooks/useURLParams';
import { getTimeZone, validateEmail } from '../utils';
import { minPasswordLength } from '../globals';
import InlineHint from '../components/InlineHint';
import { Analytics } from '../util/Analytics';

enum StateKeys {
    email = 'email',
    password = 'password',
    passwordConfirm = 'passwordConfirm',
    displayName = 'displayName',
    timeZone = 'timeZone',
}

interface RegisterForm {
    email: string;
    password: string;
    passwordConfirm: string;
    displayName: string;
    timeZone: ReactSelectOption;
}

const initialFormState: RegisterForm = {
    [StateKeys.email]: '',
    [StateKeys.password]: '',
    [StateKeys.passwordConfirm]: '',
    [StateKeys.displayName]: '',
    [StateKeys.timeZone]: timeZones[0],
};

const Register: RFC = () => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [form, setForm] = useState<RegisterForm>(initialFormState);
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
    const [passwordTooShort, setPasswordTooShort] = useState<boolean>();
    const [emailValid, setEmailValid] = useState<boolean>();
    const [page, setPage] = useState<number>(1);
    const [error, setError] = useState<Error | null>(null);
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [invitationId] = useURLParams(URLParams.INVITE_ID);

    const handleInput = (e: InputChange) => {
        e.persist();
        error && setError(null);
        //remove error messages and UI while user types -- reinstate in on input blur
        if (e.target.name === StateKeys.email) {
            setEmailValid(undefined);
        } else if (e.target.name === StateKeys.password) {
            setPasswordTooShort(undefined);
        }
        setForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    // TODO: This should probably be put elsewhere
    const convertFormToRequest = (): SignupRequest => {
        const timeZoneStr = form[StateKeys.timeZone].value.split(':')[0];
        const timeZone = timeZoneStr.startsWith('-')
            ? Number(timeZoneStr)
            : Number(timeZoneStr.slice(0));

        return {
            email: form.email,
            password: form.password,
            displayName: form.displayName,
            timeZone: timeZone,
        };
    };

    const handleSignup = async (e: FormSubmission): Promise<void> => {
        Analytics.Event.UserRegistered.track();
        e.preventDefault();
        try {
            setSubmitting(true);
            const request = convertFormToRequest();
            const user = await signupUser(request);
            setCurrentUser(user);
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setError(error);
        }
        setSubmitting(false);
    };

    useEffect(() => {
        (async () => {
            if (currentUser) {
                //if the user has uploaded a profile image, ensure the image is uploaded and set on user object before navigating
                if (invitationId) {
                    const groupIdUserWasInvitedTo = await redeemInvitation(invitationId);
                    await navigate(AuthRoutes.GROUP_LIST + `/${groupIdUserWasInvitedTo}`, {
                        state: { [NavStateKeys.INVITE_ACCEPTED]: true },
                    });
                } else {
                    await navigate(AuthRoutes.GROUP_LIST);
                }
            }
        })();
    }, [currentUser]);

    useEffect(() => {
        setFormValid(
            !!form.email &&
                !!form.password &&
                !!form.passwordConfirm &&
                passwordsMatch &&
                form.password.length >= minPasswordLength &&
                !!form.displayName &&
                form.timeZone !== undefined
        );
    }, [form.displayName, form.password, form.email, form.passwordConfirm, form.timeZone]);

    useEffect(() => {
        setPasswordsMatch(form.passwordConfirm === form.password);
    }, [form.password, form.passwordConfirm]);

    return (
        <SplashPageContainer>
            <form className="px-8 pt-6 pb-8 mb-4" onSubmit={handleSignup}>
                <h4 className="text-center font-bold text-2xl text-blue-800 mb-3">Welcome!</h4>
                {page === 1 ? (
                    <PageOne
                        form={form}
                        handleInput={handleInput}
                        nextPage={() => setPage(2)}
                        passwordsMatch={passwordsMatch}
                        passwordTooShort={passwordTooShort}
                        setPasswordTooShort={() =>
                            setPasswordTooShort(form.password.length < minPasswordLength)
                        }
                        setEmailValid={() => setEmailValid(validateEmail(form.email))}
                        emailValid={emailValid}
                    />
                ) : (
                    <PageTwo
                        form={form}
                        goBack={() => setPage(1)}
                        disabled={!formValid}
                        handleInput={handleInput}
                        submitting={submitting}
                        setTimeZone={(timeZone: ReactSelectOption) =>
                            setForm(prev => ({ ...prev, [StateKeys.timeZone]: timeZone }))
                        }
                    />
                )}
                {error && <ErrorMessage code={error?.code} />}
            </form>
            <div className="mx-auto mt-4 text-center text-blue-800">
                Already have an account?{' '}
                <Link
                    to={NonAuthRoutes.LOGIN}
                    className="font-bold text-blue-600 hover:text-blue-700"
                >
                    Login
                </Link>
            </div>
        </SplashPageContainer>
    );
};

interface PageProps {
    form: RegisterForm;
    handleInput: (e: InputChange) => void;
}

interface PageOneProps extends PageProps {
    nextPage: () => void;
    passwordsMatch: boolean;
    setEmailValid: () => void;
    emailValid?: boolean;
    passwordTooShort?: boolean;
    setPasswordTooShort: () => void;
}

const PageOne: RFC<PageOneProps> = ({
    form,
    handleInput,
    nextPage,
    passwordsMatch,
    setEmailValid,
    emailValid,
    passwordTooShort,
    setPasswordTooShort,
}) => (
    <div className="flex flex-wrap mx-3 mb-6">
        <div className="w-full px-3">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={StateKeys.email}>
                <span className="text-gray-700">Email</span>
                <input
                    className={`shadow appearance-none border ${
                        emailValid === false ? 'border-red-500' : ''
                    } rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
                    type="text"
                    name={StateKeys.email}
                    value={form?.email}
                    onChange={handleInput}
                    onBlur={setEmailValid}
                />
                {emailValid === false && (
                    <p className="text-red-500 italic text-sm font-normal">
                        Please enter a valid email address
                    </p>
                )}
            </label>
        </div>
        <div className="w-full px-3">
            <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor={StateKeys.password}
            >
                <span className="text-gray-700">Password</span>
                <input
                    className={`shadow appearance-none border ${
                        passwordTooShort ? 'border-red-500' : ''
                    } rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
                    type="password"
                    name={StateKeys.password}
                    value={form?.password}
                    onChange={handleInput}
                    onBlur={setPasswordTooShort}
                />
                {passwordTooShort === true && (
                    <p className="text-red-500 text-sm italic font-normal">
                        Passwords must be at least {minPasswordLength} characters
                    </p>
                )}
            </label>
        </div>
        <div className="w-full px-3">
            <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor={StateKeys.passwordConfirm}
            >
                <span className="text-gray-700">Confirm Password</span>
                <input
                    className={`shadow appearance-none border ${
                        !!form.passwordConfirm && !passwordsMatch
                            ? 'border-red-500'
                            : !!form.passwordConfirm
                            ? 'border-green-500'
                            : ''
                    } rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline`}
                    type="password"
                    disabled={passwordTooShort}
                    name={StateKeys.passwordConfirm}
                    value={form?.passwordConfirm}
                    onChange={handleInput}
                />
                {!!form.passwordConfirm && (
                    <p
                        className={`text-${
                            passwordsMatch ? 'green' : 'red'
                        }-500 italic font-normal text-sm`}
                    >
                        {passwordsMatch ? 'Passwords match!' : 'Passwords must match'}
                    </p>
                )}
            </label>
        </div>
        <div className="text-center w-full mt-2">
            <SubmitButton
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                disabled={
                    !(
                        !!form.email &&
                        !!form.password &&
                        !!form.passwordConfirm &&
                        form.password === form.passwordConfirm
                    )
                }
                onClick={(e: ButtonClick) => {
                    e.preventDefault();
                    nextPage && nextPage();
                }}
            >
                CONTINUE
            </SubmitButton>
        </div>
    </div>
);

interface PageTwoProps extends PageProps {
    goBack: () => void;
    submitting: boolean;
    disabled: boolean;
    setTimeZone: (timeZone: ReactSelectOption) => void;
}

const PageTwo: RFC<PageTwoProps> = ({
    form,
    handleInput,
    submitting,
    disabled,
    setTimeZone,
    goBack,
}) => {
    const handleTimeZone = (timeZone: ReactSelectValue) => {
        setTimeZone && setTimeZone(timeZone as ReactSelectOption);
    };

    useEffect(() => {
        const predictedTimezone = timeZones.find(({ value }) => value === getTimeZone());
        if (predictedTimezone) {
            handleTimeZone(predictedTimezone);
        }
    }, []);

    return (
        <div className="flex flex-wrap mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mb-0">
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor={StateKeys.displayName}
                >
                    <span className="text-gray-700">Name</span>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        onChange={handleInput}
                        name={StateKeys.displayName}
                        value={form?.displayName}
                    />
                    <InlineHint hint="Your name shows up in your profile and groups." />
                </label>
            </div>
            <div className="w-full px-3">
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor={StateKeys.timeZone}
                >
                    <span className="text-gray-700">Time Zone</span>
                    <Select
                        onChange={handleTimeZone}
                        selectedOption={form.timeZone}
                        options={timeZones}
                    />
                    <InlineHint
                        hint={`You can create one \"Three Good Things\" entry per day - your timezone tells us when your day starts and ends.`}
                        additionalClassNames="mt-2"
                    />
                </label>
            </div>
            <div className="text-center w-full">
                <SubmitButton
                    loading={submitting}
                    className="bg-blue-500 hover:bg-blue-700 mt-5 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                    disabled={disabled}
                >
                    CREATE ACCOUNT
                </SubmitButton>
                <button
                    onClick={goBack}
                    className="bg-transparent text-blue-500 hover:text-blue-700 block mt-2 mx-auto text-center"
                >
                    Go back
                </button>
            </div>
        </div>
    );
};

export default Register;
