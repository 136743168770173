import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { RFC } from '../types/generalTypes';
import { navigate, RouteComponentProps } from '@reach/router';
import { Error, FetchRequest } from '../types/networkTypes';
import { GroupMember, GroupResponse } from '../types/groupTypes';
import { getGroup, updateGroup } from '../api/group';
import GroupMemberList from '../components/GroupMemberList';
import LoadingSpinner from '../components/LoadingSpinner';
import { UserContext } from '../context/UserContextProvider';
import { AuthRoutes } from '../routes';
import modalReducer, {
    GroupSidePanelModalScenarios,
    GroupSidePanelModalScenarios as ModalScenarios,
    initialGroupSidePanelModalState,
    ModalScenarioResponse,
} from '../reducers/groupSidePanelModalReducer';
import GroupMembersModal from '../components/GroupMembersModal';
import useViewport from '../hooks/useViewport';
import isAuthed from '../hooks/isAuthed';

const GroupMembers: RFC<{ id?: string } & RouteComponentProps> = ({ id }) => {
    isAuthed();
    const [group, setGroup] = useState<FetchRequest<GroupResponse | undefined, Error>>({
        fetching: true,
        data: undefined,
        error: null,
    });
    const [targetMember, setTargetMember] = useState<GroupMember>();
    const [userIsModerator, setUserIsModerator] = useState<boolean>(false);
    const { currentUser } = useContext(UserContext);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentModalScenario, setCurrentModalScenario] = useState<ModalScenarioResponse>();
    const [groupName, setGroupName] = useState<{ editing: boolean; value?: string }>({
        editing: false,
    });
    const [modalScenario, dispatch] = useReducer(modalReducer, initialGroupSidePanelModalState);
    const [{ width: windowWidth }] = useViewport();

    const groupNameInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (group) {
            setGroupName(prev => ({ ...prev, value: group.data?.name }));
        }
    }, [group]);

    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    const data = await getGroup(id);
                    setGroup(prev => ({ ...prev, data }));
                    setUserIsModerator(
                        data.members.find(({ _id }) => _id === currentUser?._id)?.moderator ?? false
                    );
                } catch (error) {
                    console.log(error);
                    setGroup(prev => ({ ...prev, error }));
                }
                setGroup(prev => ({ ...prev, fetching: false }));
            }
        })();
    }, [id]);

    useEffect(() => {
        setCurrentModalScenario(
            translateModalScenario(
                Object.keys(modalScenario).filter(
                    scenario => modalScenario[scenario]
                )[0] as ModalScenarios
            )
        );
    }, [...Object.values(modalScenario)]);

    useEffect(() => {
        (async () => {
            try {
                if (windowWidth > 768) {
                    await navigate(AuthRoutes.GROUP_LIST + `/${id}`);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [windowWidth]);

    const removeUserFromGroup = async (): Promise<void> => {
        try {
            const updatedGroup = await updateGroup({
                groupId: group.data!._id,
                request: { userIdToRemove: targetMember?._id },
            });
            setGroup(prev => ({ ...prev, data: updatedGroup }));
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
        }
        setModalOpen(false);
    };

    const toggleModeratorStatus = async (): Promise<void> => {
        try {
            const updatedGroup = await updateGroup({
                groupId: group.data!._id,
                request: { userIdToToggleModerator: targetMember?._id },
            });
            setGroup(prev => ({ ...prev, data: updatedGroup }));
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
        }
        setModalOpen(false);
    };

    const updateGroupName = async (): Promise<void> => {
        try {
            const updatedGroup = await updateGroup({
                groupId: group.data!._id,
                request: { name: groupName.value },
            });
            setGroup(prev => ({ ...prev, data: updatedGroup }));
            setGroupName(prev => ({ ...prev, editing: false }));
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
        }
    };

    const translateModalScenario = (scenario: ModalScenarios): ModalScenarioResponse => {
        switch (scenario) {
            case ModalScenarios.REMOVING_USER_FROM_GROUP:
                return {
                    verbiage: `Are you sure you want to remove ${
                        targetMember?.displayName ?? 'this user'
                    } from this group?`,
                    action: removeUserFromGroup,
                };
            case ModalScenarios.REVOKING_USER_MODERATOR_STATUS:
                return {
                    verbiage: `Are you sure you want to revoke ${
                        targetMember?.displayName ?? 'this user'
                    }'s moderator status?`,
                    action: toggleModeratorStatus,
                };
            default:
                return {
                    verbiage: `Are you sure you want to grant ${
                        targetMember?.displayName ?? 'this user'
                    } moderator status?`,
                    action: toggleModeratorStatus,
                };
        }
    };

    const openModal = (type: ModalScenarios) =>
        dispatch({ type, callback: () => setModalOpen(true) });

    const PageContent: RFC = () => {
        if (!!group.data?.members.length) {
            return (
                <>
                    {userIsModerator && (
                        <div className="w-full px-3 pb-2 flex flex-row justify-between items-center">
                            {!groupName.editing && (
                                <button
                                    className={`text-${
                                        groupName.editing ? 'gray' : 'blue'
                                    }-500 hover:text-${
                                        groupName.editing ? 'gray' : 'blue'
                                    }-700 font-semibold`}
                                    onClick={() =>
                                        setGroupName(prev => ({ ...prev, editing: !prev.editing }))
                                    }
                                >
                                    Edit group name
                                </button>
                            )}
                            {groupName.editing && (
                                <div className="flex flex-col w-full justify-center">
                                    <input
                                        ref={groupNameInputRef}
                                        type="text"
                                        className="shadow appearance-none border block rounded w-2/3 mx-auto py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                        value={groupName.value}
                                        onChange={e => {
                                            e.persist();
                                            setGroupName(prev => ({
                                                ...prev,
                                                value: e.target.value,
                                            }));
                                        }}
                                    />
                                    <div className="flex flex-row items-center justify-center">
                                        <button
                                            className="bg-transparent font-bold text-gray-500 mr-2 px-2 py-1"
                                            onClick={() =>
                                                setGroupName(prev => ({ ...prev, editing: false }))
                                            }
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white px-2 py-1"
                                            onClick={updateGroupName}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="border border-gray-600 p-3 rounded-md bg-gray-100">
                        <GroupMemberList
                            members={group.data.members}
                            setTargetMember={setTargetMember}
                            initiateRevokeModeratorStatus={() =>
                                openModal(ModalScenarios.REVOKING_USER_MODERATOR_STATUS)
                            }
                            initiateGrantModeratorStatus={() =>
                                openModal(
                                    GroupSidePanelModalScenarios.GRANTING_USER_MODERATOR_STATUS
                                )
                            }
                            initiateRemoveUser={() =>
                                openModal(GroupSidePanelModalScenarios.REMOVING_USER_FROM_GROUP)
                            }
                            userIsModerator={userIsModerator}
                        />
                    </div>
                </>
            );
        } else if (!group.data?.members.length && !group.fetching) {
            return (
                <div className="mx-auto mt-5 p-2 text-center">
                    <h3 className="text-lg">
                        There aren't any members in this group.{' '}
                        <button className="text-blue-500 bg-transparent hover:text-blue-700 font-semibold">
                            Invite some
                        </button>
                    </h3>
                </div>
            );
        }
        return <LoadingSpinner type="page" />;
    };
    return (
        <div className="px-5">
            {group.data && (
                <h3 className="text-left my-2 pl-2 text-lg">
                    Members of <span className="font-semibold">{group.data?.name}</span>
                </h3>
            )}
            <PageContent />
            {modalOpen && (
                <GroupMembersModal
                    isOpen={modalOpen}
                    closeModal={() => setModalOpen(false)}
                    currentModalScenario={currentModalScenario}
                />
            )}
        </div>
    );
};

export default GroupMembers;
