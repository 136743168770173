import React, { useRef, useState } from 'react';
import { InputChange, RFC } from '../types/generalTypes';
import { Privacy } from '../types/entryTypes';
import useOutsideClick from '../hooks/useOutsideClick';
import ToolTip from './ToolTip';
import IconManager, { IconType } from './IconManager';
import { mobileBreakPoint } from '../globals';
import { Link } from '@reach/router';
import { NonAuthRoutes } from '../routes';
const initialToolTipState = {
    [Privacy.SHARED]: false,
    [Privacy.PRIVATE]: false,
    [Privacy.PUBLIC]: false,
};
interface Props {
    privacySelection: Privacy;
    handlePrivacySelection: (e: InputChange) => void;
}

const EntryPrivacySection: RFC<Props> = ({ privacySelection, handlePrivacySelection }) => {
    const [privacyToolTipsVisible, setPrivacyToolTipsVisible] = useState<
        { [key in Privacy]: boolean }
    >(initialToolTipState);
    const togglePrivacyToolTip = (privacy: Privacy) => {
        setPrivacyToolTipsVisible(prev => ({ ...initialToolTipState, [privacy]: !prev[privacy] }));
    };
    const privatePrivacyToolTipRef = useRef<HTMLDivElement>(null);
    const sharedPrivacyToolTipRef = useRef<HTMLDivElement>(null);
    const publicPrivacyToolTipRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
        ref: privatePrivacyToolTipRef,
        callback: () => setPrivacyToolTipsVisible(prev => ({ ...prev, [Privacy.PRIVATE]: false })),
    });
    useOutsideClick({
        ref: sharedPrivacyToolTipRef,
        callback: () => setPrivacyToolTipsVisible(prev => ({ ...prev, [Privacy.SHARED]: false })),
    });
    useOutsideClick({
        ref: publicPrivacyToolTipRef,
        callback: () => setPrivacyToolTipsVisible(prev => ({ ...prev, [Privacy.PUBLIC]: false })),
    });
    return (
        <div className="mx-auto text-center">
            <label className="block text-gray-700 text-sm mb-2" htmlFor={Privacy.PRIVATE}>
                <div className="mt-4 w-full">
                    <span className="text-gray-700">Who should be able to view your entry?</span>
                    <div className="mt-2">
                        <label
                            className="flex flex-row items-center mb-2 cursor-pointer justify-center relative"
                            htmlFor={Privacy.PRIVATE}
                        >
                            <input
                                type="radio"
                                className="form-radio mr-3 cursor-pointer"
                                checked={privacySelection === Privacy.PRIVATE}
                                name={Privacy.PRIVATE}
                                value={Privacy.PRIVATE}
                                onChange={handlePrivacySelection}
                            />
                            <div
                                className="flex flex-row justify-center items-center"
                                ref={privatePrivacyToolTipRef}
                            >
                                <span className="mr-2">Private</span>
                                <IconManager
                                    type={IconType.INFO}
                                    size={16}
                                    className="cursor-pointer"
                                    stroke="#C2C2C2"
                                    onMouseEnter={() => togglePrivacyToolTip(Privacy.PRIVATE)}
                                    onMouseLeave={() => togglePrivacyToolTip(Privacy.PRIVATE)}
                                    onClick={() => togglePrivacyToolTip(Privacy.PRIVATE)}
                                />
                            </div>
                            <ToolTip
                                verbiage="This entry will only be visible to you, on your profile page. It won’t be shared with any of your groups."
                                visible={privacyToolTipsVisible[Privacy.PRIVATE]}
                            />
                        </label>
                        <label
                            className="flex flex-row items-center mb-2 cursor-pointer justify-center relative"
                            htmlFor={Privacy.SHARED}
                        >
                            <input
                                type="radio"
                                className="form-radio mr-3 cursor-pointer"
                                checked={privacySelection === Privacy.SHARED}
                                name={Privacy.SHARED}
                                value={Privacy.SHARED}
                                onChange={handlePrivacySelection}
                            />
                            <div
                                className="flex flex-row justify-center items-center"
                                ref={sharedPrivacyToolTipRef}
                            >
                                <span className="mr-2">Groups</span>
                                <IconManager
                                    type={IconType.INFO}
                                    size={16}
                                    className="cursor-pointer"
                                    stroke="#C2C2C2"
                                    onMouseEnter={() => togglePrivacyToolTip(Privacy.SHARED)}
                                    onMouseLeave={() => togglePrivacyToolTip(Privacy.SHARED)}
                                    onClick={() => togglePrivacyToolTip(Privacy.SHARED)}
                                />
                            </div>
                            <ToolTip
                                verbiage="Friends you share a group with will be able to see this entry in the group, and see it if they view your profile page."
                                visible={privacyToolTipsVisible[Privacy.SHARED]}
                            />
                        </label>
                        <label
                            className="flex flex-row items-center cursor-pointer justify-center relative"
                            htmlFor={Privacy.PUBLIC}
                        >
                            <input
                                type="radio"
                                className="form-radio mr-4 cursor-pointer"
                                checked={privacySelection === Privacy.PUBLIC}
                                name={Privacy.PUBLIC}
                                value={Privacy.PUBLIC}
                                onChange={handlePrivacySelection}
                            />
                            <div
                                className="flex flex-row justify-end items-center"
                                ref={publicPrivacyToolTipRef}
                            >
                                <span className="mr-2">Public</span>
                                <IconManager
                                    type={IconType.INFO}
                                    size={16}
                                    className="cursor-pointer"
                                    stroke="#C2C2C2"
                                    onMouseEnter={() => togglePrivacyToolTip(Privacy.PUBLIC)}
                                    onMouseLeave={() => togglePrivacyToolTip(Privacy.PUBLIC)}
                                    onClick={() => togglePrivacyToolTip(Privacy.PUBLIC)}
                                />
                            </div>
                            <div
                                className={`${
                                    privacyToolTipsVisible.PUBLIC
                                        ? 'text-sm text-gray-600'
                                        : 'hidden'
                                } bg-gray-200 text-left rounded-md shadow-md p-4 z-50 absolute right-0`}
                                style={{ bottom: '20px' }}
                            >
                                <span className="font-semibold flex flex-row items-center">
                                    <IconManager
                                        type={IconType.INFO}
                                        size={window.innerWidth <= mobileBreakPoint ? 40 : 24}
                                        stroke="#00BDF7"
                                        className="mr-1"
                                    />
                                    Public entries:
                                </span>
                                <ul className="list-disc ml-5">
                                    <li>are shared with your groups</li>
                                    <li>can be shared on social media</li>
                                    <li>
                                        are eligible to be included in{' '}
                                        <Link
                                            to={NonAuthRoutes.POSITIVITY_FEED}
                                            className="text-blue-500 hover:text-red-600 underline"
                                        >
                                            Positivity Feed
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </label>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default EntryPrivacySection;
