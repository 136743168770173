import { RFC } from '../types/generalTypes';
import React from 'react';
import useViewport from '../hooks/useViewport';
import { mobileBreakPoint } from '../globals';
import IconManager, { IconType } from './IconManager';

const ToolTip: RFC<{ verbiage: string; visible: boolean }> = ({ verbiage, visible }) => {
    const [{ width: windowWidth }] = useViewport();
    return (
        <span
            className={`${
                visible
                    ? 'flex flex-row text-sm text-gray-600 justify-start items-center'
                    : 'hidden'
            } bg-gray-200 text-left rounded-md shadow-md p-4 z-50 absolute right-0`}
            style={{ bottom: '20px' }}
        >
            <IconManager
                type={IconType.INFO}
                size={windowWidth <= mobileBreakPoint ? 40 : 24}
                stroke="#00BDF7"
                className="mr-3"
            />
            {verbiage}
        </span>
    );
};

export default ToolTip;
