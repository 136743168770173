import React, { useContext, useEffect, useReducer, useState } from 'react';
import { ButtonClick, FormSubmission, InputChange, RFC } from '../types/generalTypes';
import { CreateEntryRequest, Entry as EntryType, Privacy } from '../types/entryTypes';
import { Error, FetchRequest } from '../types/networkTypes';
import SubmitButton from '../components/SubmitButton';
import { createEntry, deleteEntry, editEntryPrivacy, getEntries } from '../api/entry';
import { Link, navigate } from '@reach/router';
import { AuthRoutes } from '../routes';
import { Theme } from '../theme';
import TextareaAutosize from 'react-textarea-autosize';
import ErrorMessage from '../components/ErrorMessage';
import { UserContext } from '../context/UserContextProvider';
import Entry from '../components/Entry';
import LoadingSpinner from '../components/LoadingSpinner';
import InlineHint from '../components/InlineHint';
import Modal from '../components/Modal';
import addEntryModalReducer, {
    AddEntryModalScenarios as ModalScenarios,
    initialAddEntryModalState,
} from '../reducers/addEntryModalReducer';
import EntryPrivacySection from '../components/EntryPrivacySection';
import { LAST_GROUP_ID_VIEWED_BY_USER } from '../globals';
import { Analytics } from '../util/Analytics';
import isAuthed from '../hooks/isAuthed';

enum Things {
    ONE = 'ONE',
    TWO = 'TWO',
    THREE = 'THREE',
}

const AddEntry: RFC = () => {
    isAuthed();
    const today = `${
        new Date().getMonth() + 1
    }/${new Date().getDate()}/${new Date().getFullYear()}`;
    const [loadingCurrentUser, setLoadingCurrentUser] = useState<boolean>(true);
    const [things, setThings] = useState<{ [key in Things]: string }>({
        [Things.ONE]: '',
        [Things.TWO]: '',
        [Things.THREE]: '',
    });
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [privacy, setPrivacy] = useState<Privacy>(Privacy.SHARED);
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [error, setError] = useState<Error | null>(null);
    const [previousEntry, setPreviousEntry] = useState<FetchRequest<EntryType | undefined, Error>>({
        fetching: true,
        data: undefined,
        error: null,
    });
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalScenario, dispatch] = useReducer(addEntryModalReducer, initialAddEntryModalState);
    const [missingThingsAlertVisible, setMissingThingsAlertVisible] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                if (currentUser) {
                    const { entries } = await getEntries();
                    setPreviousEntry(prev => ({ ...prev, data: entries?.[0] }));
                    setLoadingCurrentUser(false);
                }
            } catch (error) {
                console.log(error);
                setPreviousEntry(prev => ({ ...prev, error }));
            }
            setPreviousEntry(prev => ({ ...prev, fetching: false }));
        })();
    }, [currentUser]);

    useEffect(() => {
        setPrivacy(previousEntry.data?.privacy ?? Privacy.SHARED);
    }, [previousEntry.data]);

    const handleInput = (e: InputChange<HTMLTextAreaElement>) => {
        e.persist();
        if (missingThingsAlertVisible) {
            setMissingThingsAlertVisible(false);
        }
        setThings(prev => ({ ...prev, [e.target.name as Things]: e.target.value }));
    };

    const openModal = (type: ModalScenarios) =>
        dispatch({ type, callback: () => setModalOpen(true) });
    const handlePrivacySelection = (e: InputChange) => setPrivacy(e.target.value as Privacy);

    const alertUserOfMissingThings = (e: ButtonClick) => {
        e.preventDefault();
        setMissingThingsAlertVisible(true);
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        Analytics.Event.EntryCreated.track();
        const groupIdUserWasViewing = sessionStorage.getItem(LAST_GROUP_ID_VIEWED_BY_USER);
        setSubmitting(true);
        try {
            const entry: CreateEntryRequest = {
                items: [things[Things.ONE], things[Things.TWO], things[Things.THREE]],
                privacy,
            };
            await createEntry(entry);
            setCurrentUser({ ...currentUser!, postEligible: false });
            groupIdUserWasViewing
                ? await navigate(AuthRoutes.GROUP_LIST + `/${groupIdUserWasViewing}`)
                : await navigate(AuthRoutes.PROFILE);
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setError(error);
        }
        setSubmitting(false);
    };

    const lengthColor = (length: number): 'gray' | 'yellow' | 'red' => {
        if (length >= 265) {
            return 'red';
        } else if (length > 220 && length < 265) {
            return 'yellow';
        } else {
            return 'gray';
        }
    };

    useEffect(() => {
        setFormValid(!!things[Things.ONE] && !!things[Things.TWO] && !!things[Things.THREE]);
    }, [things[Things.ONE], things[Things.TWO], things[Things.THREE]]);

    const deleteTodaysEntry = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        try {
            Analytics.Event.EntryDeleted.track();
            await deleteEntry(previousEntry.data!._id);
            setPreviousEntry(prev => ({ ...prev, data: undefined }));
            setCurrentUser({ ...currentUser!, postEligible: true });
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setError(error);
        }
    };

    const editTodaysEntry = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        try {
            setPreviousEntry(prev => ({ ...prev, fetching: true }));
            Analytics.Event.EntryPrivacyUpdated.track();
            const data = await editEntryPrivacy({ entryId: previousEntry.data!._id, privacy });
            setPreviousEntry(prev => ({ ...prev, data }));
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setPreviousEntry(prev => ({ ...prev, error }));
        }
        setPreviousEntry(prev => ({ ...prev, fetching: false }));
    };

    useEffect(() => {
        if (!modalOpen) {
            dispatch({ type: ModalScenarios.RESET_MODAL_STATE });
        }
    }, [modalOpen]);

    return (
        <div className="p-5">
            {loadingCurrentUser ? (
                <LoadingSpinner type="page" />
            ) : (
                <div className="w-full text-center mb-3">
                    {currentUser?.postEligible ? (
                        <>
                            <h3 className="text-2xl text-left md:text-center text-blue-800 font-bold">
                                What are three good things that happened to you today?
                            </h3>
                            <form className="max-w-lg mx-auto mt-5" onSubmit={handleSubmit}>
                                <div className="mb-4 mx-auto w-11/12 flex flex-col text-gray-700 text-left justify-center">
                                    <h3 className="mb-2 block">
                                        The Three Good things is all about taking time once a day to
                                        write down three good things about your day, such as what
                                        went well, what you're grateful for, or what you're hopeful
                                        about
                                    </h3>
                                    <p className="mt-2">
                                        Your entries can be private or you can{' '}
                                        <Link
                                            className="text-blue-500 hover:text-blue-600 font-semibold"
                                            to={AuthRoutes.GROUP_LIST}
                                        >
                                            create groups of friends or family
                                        </Link>
                                        , to allow you to share your positive moments with each
                                        other.
                                    </p>
                                </div>
                                <div className="w-full mb-5 flex flex-col relative">
                                    <TextareaAutosize
                                        type="text"
                                        className={`form-input mt-1 block w-full outline-none rounded border-${
                                            missingThingsAlertVisible && !things.ONE
                                                ? 'red-500'
                                                : 'gray-200'
                                        } bg-${Theme.offWhite}`}
                                        name={Things.ONE}
                                        maxLength={280}
                                        onChange={handleInput}
                                    />
                                    <span
                                        className={`ml-auto text-${lengthColor(
                                            things[Things.ONE].length
                                        )}-500`}
                                    >
                                        {things[Things.ONE].length}/280
                                    </span>
                                </div>
                                <div className="w-full mb-5 flex flex-col relative">
                                    <TextareaAutosize
                                        type="text"
                                        className={`form-input mt-1 block w-full outline-none rounded border-${
                                            missingThingsAlertVisible && !things.TWO
                                                ? 'red-500'
                                                : 'gray-200'
                                        } bg-${Theme.offWhite}`}
                                        name={Things.TWO}
                                        maxLength={280}
                                        onChange={handleInput}
                                    />
                                    <span
                                        className={`ml-auto text-${lengthColor(
                                            things[Things.TWO].length
                                        )}-500`}
                                    >
                                        {things[Things.TWO].length}/280
                                    </span>
                                </div>
                                <div className="w-full flex flex-col relative mb-5">
                                    <TextareaAutosize
                                        type="text"
                                        className={`form-input mt-1 block w-full outline-none rounded border-${
                                            missingThingsAlertVisible && !things.THREE
                                                ? 'red-500'
                                                : 'gray-200'
                                        } bg-${Theme.offWhite}`}
                                        name={Things.THREE}
                                        maxLength={280}
                                        onChange={handleInput}
                                    />
                                    <span
                                        className={`ml-auto text-${lengthColor(
                                            things[Things.THREE].length
                                        )}-500`}
                                    >
                                        {things[Things.THREE].length}/280
                                    </span>
                                </div>
                                <EntryPrivacySection
                                    privacySelection={privacy}
                                    handlePrivacySelection={handlePrivacySelection}
                                />
                                {missingThingsAlertVisible && (
                                    <p className="text-red-500 block text-sm text-center">
                                        It looks like you haven't filled out all of your three good
                                        things
                                    </p>
                                )}
                                <SubmitButton
                                    loading={submitting}
                                    onClick={e =>
                                        formValid ? undefined : alertUserOfMissingThings(e)
                                    }
                                    className="bg-green-500 hover:bg-green-700 mt-5 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                                >
                                    SUBMIT
                                </SubmitButton>
                                <InlineHint
                                    hint="Remember, you can only create one entry per day."
                                    additionalClassNames="mt-2"
                                />
                                {error && <ErrorMessage code={error?.code} />}
                            </form>
                        </>
                    ) : (
                        <div className="max-w-3xl mx-auto">
                            <div className="bg-blue-400 border rounded-md p-3 shadow-md mb-2">
                                <h3 className="text-2xl text-white font-bold mb-2">
                                    You've already written your entry for today
                                </h3>
                            </div>
                            <span className="text-gray-500 mb-3 block">{today}</span>
                            {/*todo: clean up this render logic*/}
                            {previousEntry.fetching ? (
                                <LoadingSpinner type="page" />
                            ) : previousEntry.error ? (
                                <ErrorMessage code={previousEntry.error?.code} />
                            ) : (
                                previousEntry.data && (
                                    <Entry
                                        entry={previousEntry.data}
                                        initiateDelete={() =>
                                            openModal(ModalScenarios.DELETING_ENTRY)
                                        }
                                        initiateEdit={() =>
                                            openModal(ModalScenarios.EDITING_ENTRY_PRIVACY)
                                        }
                                        preventLinking={true}
                                    />
                                )
                            )}
                        </div>
                    )}
                </div>
            )}
            {modalOpen && (
                <Modal isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
                    <form
                        onSubmit={async e => {
                            modalScenario[ModalScenarios.DELETING_ENTRY]
                                ? await deleteTodaysEntry(e)
                                : await editTodaysEntry(e);
                            setModalOpen(false);
                        }}
                    >
                        {modalScenario[ModalScenarios.DELETING_ENTRY] && (
                            <>
                                <h3 className="font-semibold text-center text-gray-700">
                                    Are you sure you want to delete your entry for today?
                                </h3>
                                <div className="mt-2 mx-auto flex flex-row justify-center items-center w-full p-2">
                                    <button
                                        className="bg-transparent font-bold text-gray-500 mr-2 px-2 py-1"
                                        onClick={() => setModalOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white px-2 py-1"
                                    >
                                        Yes
                                    </button>
                                </div>
                            </>
                        )}
                        {modalScenario[ModalScenarios.EDITING_ENTRY_PRIVACY] && (
                            <div className="text-center">
                                <EntryPrivacySection
                                    privacySelection={privacy}
                                    handlePrivacySelection={handlePrivacySelection}
                                />
                                <button
                                    type="submit"
                                    className="px-2 py-1 text-lg font-bold text-white bg-green-500 hover:bg-green-700 border-none rounded-md w-full md:w-2/3 mx-auto my-2"
                                >
                                    SAVE CHANGES
                                </button>
                            </div>
                        )}
                    </form>
                </Modal>
            )}
        </div>
    );
};

export default AddEntry;
