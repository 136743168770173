import React, { forwardRef, Ref } from 'react';
import styled from 'styled-components';
import { deriveInitialsFromDisplayName } from '../utils';
import { ImageFilePreview } from '../types/generalTypes';

interface Props {
    displayName?: string;
    backgroundColor?: string;
    avatarSize?: string;
    onClick?: () => void;
    className?: string;
    uploadedProfileImage?: ImageFilePreview;
    profileImageUrl?: string;
    viewingProfile?: boolean;
}

const UserAvatar = forwardRef(
    (
        {
            displayName,
            viewingProfile,
            avatarSize = '4em',
            onClick,
            backgroundColor = '#EEF4F8',
            className,
            profileImageUrl,
            uploadedProfileImage,
        }: Props,
        ref: Ref<HTMLDivElement>
    ) => {
        return profileImageUrl || uploadedProfileImage ? (
            <div ref={ref} onClick={onClick} className={className}>
                <ImagePreview
                    src={profileImageUrl || uploadedProfileImage?.preview}
                    viewingProfile={viewingProfile}
                    preview={!!uploadedProfileImage?.preview}
                    avatarSize={avatarSize}
                />
            </div>
        ) : (
            <Circle
                ref={ref}
                backgroundColor={backgroundColor}
                avatarSize={avatarSize}
                onClick={onClick}
                className={onClick ? `${className ?? ''} cursor-pointer` : className}
            >
                <Initials color="white" avatarSize={avatarSize}>
                    {displayName && deriveInitialsFromDisplayName(displayName)}
                </Initials>
            </Circle>
        );
    }
);

export default UserAvatar;

const Circle = styled.div<Omit<Props, 'displayName'>>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 50%;
    height: ${({ avatarSize }) => avatarSize};
    width: ${({ avatarSize }) => avatarSize};
    text-align: center;
`;

const Initials = styled.span<Pick<Props, 'avatarSize'> & { color: string }>`
    font-size: calc(${({ avatarSize }) => avatarSize} / 2);
    line-height: 1;
    left: 0;
    position: relative;
    color: ${({ color }) => color};
    font-weight: bold;
    top: 25%;
`;

const ImagePreview = styled.img<Pick<Props, 'viewingProfile' | 'avatarSize'> & { preview?: boolean }>`
    width: ${({ preview, viewingProfile, avatarSize }) => (preview || viewingProfile ? '175px' : avatarSize)};
    height: ${({ preview, viewingProfile, avatarSize }) => (preview || viewingProfile ? '175px' : avatarSize)};
    object-position: center;
    object-fit: cover;
    border-radius: 50%;
`;
