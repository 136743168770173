import React, { useEffect, useReducer, useRef, useState } from 'react';
import { RFC } from '../types/generalTypes';
import { GroupMember, GroupResponse } from '../types/groupTypes';
import modalReducer, {
    GroupSidePanelModalScenarios as ModalScenarios,
    initialGroupSidePanelModalState,
    ModalScenarioResponse,
} from '../reducers/groupSidePanelModalReducer';
import { truncate } from '../utils';
import { Theme } from '../theme';
import IconManager, { IconType } from './IconManager';
import { updateGroup } from '../api/group';
import LoadingSpinner from './LoadingSpinner';
import GroupMemberList from './GroupMemberList';
import GroupMembersModal from './GroupMembersModal';

interface Props {
    group?: GroupResponse;
    userIsModerator?: boolean;
    updateGroupInLocalState: ({ group, removedMemberId }: { group: GroupResponse; removedMemberId?: string }) => void;
    loading?: boolean;
    //not ideal
    topSpacing: string;
}

const GroupSidePanel: RFC<Props> = ({ loading, group, updateGroupInLocalState, userIsModerator, topSpacing }) => {
    const [groupName, setGroupName] = useState<{ editing: boolean; value?: string }>({ editing: false, value: group?.name });
    const groupNameInputRef = useRef<HTMLInputElement>(null);
    const [modalScenario, dispatch] = useReducer(modalReducer, initialGroupSidePanelModalState);
    const [targetMember, setTargetMember] = useState<GroupMember>();
    const [currentModalScenario, setCurrentModalScenario] = useState<ModalScenarioResponse>();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (group) {
            setGroupName(prev => ({ ...prev, value: group.name }));
        }
    }, [group]);

    const toggleModeratorStatus = async (): Promise<void> => {
        try {
            const updatedGroup = await updateGroup({ groupId: group!._id, request: { userIdToToggleModerator: targetMember?._id } });
            updateGroupInLocalState({ group: updatedGroup });
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
        }
        setModalOpen(false);
    };

    useEffect(() => {
        if (!modalOpen) {
            dispatch({ type: ModalScenarios.RESET_MODAL_STATE });
        }
    }, [modalOpen]);

    useEffect(() => {
        if (groupName.editing) {
            groupNameInputRef.current?.focus();
        }
    }, [groupName.editing]);

    useEffect(() => {
        setCurrentModalScenario(translateModalScenario(Object.keys(modalScenario).filter(scenario => modalScenario[scenario])[0] as ModalScenarios));
    }, [...Object.values(modalScenario)]);

    const removeUserFromGroup = async (): Promise<void> => {
        try {
            const updatedGroup = await updateGroup({ groupId: group!._id, request: { userIdToRemove: targetMember?._id } });
            updateGroupInLocalState({ group: updatedGroup, removedMemberId: targetMember?._id });
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
        }
        setModalOpen(false);
    };

    const updateGroupName = async (): Promise<void> => {
        try {
            const updatedGroup = await updateGroup({ groupId: group!._id, request: { name: groupName.value } });
            updateGroupInLocalState({ group: updatedGroup });
            setGroupName(prev => ({ ...prev, editing: false }));
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
        }
    };

    const openModal = (type: ModalScenarios) => dispatch({ type, callback: () => setModalOpen(true) });

    const translateModalScenario = (scenario: ModalScenarios): ModalScenarioResponse => {
        switch (scenario) {
            case ModalScenarios.REMOVING_USER_FROM_GROUP:
                return {
                    verbiage: `Are you sure you want to remove ${targetMember?.displayName ?? 'this user'} from this group?`,
                    action: removeUserFromGroup,
                };
            case ModalScenarios.REVOKING_USER_MODERATOR_STATUS:
                return {
                    verbiage: `Are you sure you want to revoke ${targetMember?.displayName ?? 'this user'}'s moderator status?`,
                    action: toggleModeratorStatus,
                };
            default:
                return {
                    verbiage: `Are you sure you want to grant ${targetMember?.displayName ?? 'this user'} moderator status?`,
                    action: toggleModeratorStatus,
                };
        }
    };

    return (
        <div className="hidden md:block w-full md:w-1/4 self-start ml-3" style={{ marginTop: topSpacing }}>
            <GroupMembersModal isOpen={modalOpen} closeModal={() => setModalOpen(false)} currentModalScenario={currentModalScenario} />
            <div className="flex flex-col justify-center items-center relative bg-blue-500 h-20 py-3 rounded-t-md">
                {groupName.editing ? (
                    <div className="w-full text-center">
                        <input
                            ref={groupNameInputRef}
                            type="text"
                            className="shadow appearance-none border rounded w-2/3 mx-auto py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            value={groupName.value}
                            onChange={e => {
                                e.persist();
                                setGroupName(prev => ({ ...prev, value: e.target.value }));
                            }}
                        />
                    </div>
                ) : (
                    <h3 className="text-white text-2xl font-semibold truncate font-semibold">{truncate(groupName?.value || '', 20)}</h3>
                )}
                {userIsModerator && (
                    <div className="flex flex-row justify-center items-center">
                        {groupName.editing && (
                            <button className="text-white text-sm mr-2" onClick={() => setGroupName({ editing: false, value: group?.name })}>
                                Cancel
                            </button>
                        )}
                        <button
                            onClick={groupName.editing ? updateGroupName : () => setGroupName(prev => ({ ...prev, editing: true }))}
                            className={`text-${Theme.offWhite} text-sm flex flex-row items-center`}
                        >
                            {!groupName.editing && <IconManager type={IconType.PENCIL} size={16} className="mr-1" stroke="#F8F8F8" />}
                            {groupName.editing ? 'Save' : 'Edit name'}
                        </button>
                    </div>
                )}
            </div>
            <div className="w-full border rounded-b-md border-gray-500 p-3">
                {loading ? (
                    <LoadingSpinner type="page" />
                ) : !!group?.members.length ? (
                    <GroupMemberList
                        members={group?.members}
                        setTargetMember={setTargetMember}
                        initiateGrantModeratorStatus={() => openModal(ModalScenarios.GRANTING_USER_MODERATOR_STATUS)}
                        initiateRevokeModeratorStatus={() => openModal(ModalScenarios.REVOKING_USER_MODERATOR_STATUS)}
                        initiateRemoveUser={() => openModal(ModalScenarios.REMOVING_USER_FROM_GROUP)}
                        userIsModerator={userIsModerator}
                    />
                ) : (
                    <div className="mx-auto mt-5 p-2 text-center">
                        <h3 className="text-lg">
                            There aren't any members in this group.{' '}
                            <button className="text-blue-500 bg-transparent hover:text-blue-700 font-semibold">Invite some</button>
                        </h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GroupSidePanel;
