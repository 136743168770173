import React, { useContext, useEffect, useState } from 'react';
import { FormSubmission, RFC } from '../types/generalTypes';
import { Error, URLParams } from '../types/networkTypes';
import SplashPageContainer from '../components/SplashPageContainer';
import SubmitButton from '../components/SubmitButton';
import { Link, navigate } from '@reach/router';
import { AuthRoutes, NonAuthRoutes } from '../routes';
import { loginUser, requestPasswordReset } from '../api/auth';
import ErrorMessage from '../components/ErrorMessage';
import useURLParams from '../hooks/useURLParams';
import { minPasswordLength } from '../globals';
import { UserContext } from '../context/UserContextProvider';
import { Analytics } from '../util/Analytics';

const ForgotPassword: RFC = () => {
    const [email, setEmail] = useState<string>('');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [tempPassword, userEmailFromUrl] = useURLParams([URLParams.PASSWORD, URLParams.EMAIL]);
    const [newPassword, setNewPassword] = useState<{ valid?: boolean; value: string }>({
        value: '',
    });
    const [newPasswordRequested, setNewPasswordRequested] = useState<boolean>(false);
    const { currentUser, setCurrentUser } = useContext(UserContext);

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        setSubmitting(true);
        try {
            if (!!tempPassword && !!userEmailFromUrl) {
                const user = await loginUser({
                    email: userEmailFromUrl,
                    password: tempPassword,
                    newPassword: newPassword.value,
                });
                setCurrentUser(user);
            } else {
                Analytics.Event.ForgotPassword.track();
                await requestPasswordReset(email);
                setNewPasswordRequested(true);
            }
        } catch (error) {
            error = error.json ? await error.json() : error;
            setNewPasswordRequested(false);
            console.log(error);
            setError(error);
        }
        setSubmitting(false);
    };

    useEffect(() => {
        (async () => {
            try {
                if (currentUser) {
                    await navigate(AuthRoutes.GROUP_LIST);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [currentUser]);

    return (
        <SplashPageContainer>
            <form className="px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
                {newPasswordRequested ? (
                    <div className="flex flex-col justify-center">
                        <h3 className="text-lg text-gray-700 text-center">
                            An email has been sent to the provided address containing a link to
                            reset your password
                        </h3>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="120"
                            height="120"
                            fill="#00BF71"
                            className="mx-auto"
                        >
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z " />
                        </svg>
                        <Link
                            className="font-bold text-sm mt-2 mx-auto text-blue-500 hover:text-blue-700 pt-5"
                            to={NonAuthRoutes.LOGIN}
                        >
                            Return to login
                        </Link>
                    </div>
                ) : (
                    <>
                        <div className="mx-auto text-center">
                            <h4 className="block font-bold text-xl mb-3 text-blue-800">
                                {!!tempPassword && !!userEmailFromUrl
                                    ? 'Please set your preferred password'
                                    : 'Reset Password'}
                            </h4>
                            {!tempPassword && !userEmailFromUrl && (
                                <p className="text-sm text-gray-500">
                                    Please enter the email associated with your account
                                </p>
                            )}
                        </div>
                        <div className="mb-4">
                            {!!tempPassword && !!userEmailFromUrl ? (
                                <>
                                    <input
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                        value={newPassword.value}
                                        onChange={e => setNewPassword({ value: e.target.value })}
                                        type="password"
                                        onBlur={() =>
                                            setNewPassword(prev => ({
                                                ...prev,
                                                valid: prev.value.length >= minPasswordLength,
                                            }))
                                        }
                                    />
                                    {newPassword.valid === false && (
                                        <p className="text-red-500 text-xs italic">
                                            Your password must be at least {minPasswordLength} chars
                                        </p>
                                    )}
                                </>
                            ) : (
                                <input
                                    type="email"
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            )}
                        </div>
                        <div className="text-center">
                            <SubmitButton
                                loading={submitting}
                                className="bg-blue-500 w-full block hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                disabled={
                                    !!tempPassword
                                        ? !newPassword || newPassword.value.length < 8
                                        : !email
                                }
                            >
                                SUBMIT
                            </SubmitButton>
                            <Link
                                className="font-bold text-sm mt-2 text-blue-500 hover:text-blue-700 ml-auto pt-5"
                                to={NonAuthRoutes.LOGIN}
                            >
                                Return to login
                            </Link>
                        </div>
                    </>
                )}
            </form>
            {error && <ErrorMessage code={error?.code} />}
        </SplashPageContainer>
    );
};

export default ForgotPassword;
