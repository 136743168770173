import React, { useContext } from 'react';
import { RFC } from '../types/generalTypes';
import { GroupMember } from '../types/groupTypes';
import { UserContext } from '../context/UserContextProvider';
import Accordion from './Accordion';
import IconManager, { IconType } from './IconManager';
import { Link } from '@reach/router';
import { AuthRoutes } from '../routes';

interface Props {
    members: GroupMember[];
    setTargetMember: (member: GroupMember | undefined) => void;
    userIsModerator?: boolean;
    initiateRevokeModeratorStatus: () => void;
    initiateGrantModeratorStatus: () => void;
    initiateRemoveUser: () => void;
}

const GroupMemberList: RFC<Props> = ({
    members,
    setTargetMember,
    userIsModerator,
    initiateGrantModeratorStatus,
    initiateRemoveUser,
    initiateRevokeModeratorStatus,
}) => {
    const { currentUser } = useContext(UserContext);

    return (
        <ul className="list-none">
            {members.map((member, i) => (
                <li className={`w-full ${i !== members.length - 1 ? 'border-b border-gray-500' : ''}`} key={member._id}>
                    {member._id !== currentUser?._id && userIsModerator ? (
                        <Accordion
                            onClick={() => setTargetMember(member)}
                            title={() => (
                                <span className="flex flex-row items-center justify-between w-full text-left">
                                    <Link
                                        to={AuthRoutes.MEMBER_DETAILS + `/${member._id}`}
                                        className="text-md text-blue-600 hover:text-blue-700"
                                        onClick={e => e.stopPropagation()}
                                    >
                                        {member.displayName}
                                    </Link>
                                    {member.moderator ? (
                                        <IconManager type={IconType.GROUP_MODERATOR} className="ml-1" stroke="#4EBC7A" />
                                    ) : (
                                        <IconManager type={IconType.USER} className="ml-1" stroke="#1560AC" />
                                    )}
                                    {currentUser?._id === member._id && <span className="text-gray-600 text-sm ml-2 mt-1">(You)</span>}
                                </span>
                            )}
                        >
                            <div className="flex flex-col justify-between items-center w-full">
                                <button
                                    onClick={member.moderator ? () => initiateRevokeModeratorStatus() : () => initiateGrantModeratorStatus()}
                                    className="bg-blue-600 text-xs text-white rounded-md px-2 py-1 hover:bg-blue-700 font-semibold my-2"
                                >
                                    {member.moderator ? 'Revoke' : 'Grant'} moderator status
                                </button>
                                <button
                                    className="bg-red-600 text-xs text-white rounded-md px-2 py-1 hover:bg-red-700 font-semibold my-2"
                                    onClick={initiateRemoveUser}
                                >
                                    Remove from group
                                </button>
                            </div>
                        </Accordion>
                    ) : (
                        <div className="md:w-3/4 px-2 py-3 flex flex-row justify-between">
                            <span>
                                <Link
                                    to={AuthRoutes.PROFILE}
                                    className="text-md ml-2 text-blue-600 hover:text-blue-700"
                                    onClick={e => e.stopPropagation()}
                                >
                                    {member.displayName}
                                </Link>
                                {currentUser?._id === member._id && <span className="text-gray-600 text-sm ml-2 mt-1">(You)</span>}
                            </span>
                            {member.moderator ? (
                                <IconManager type={IconType.GROUP_MODERATOR} className="ml-1" stroke="#4EBC7A" />
                            ) : (
                                <IconManager type={IconType.USER} className="ml-1" stroke="#1560AC" />
                            )}
                        </div>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default GroupMemberList;
