export enum AuthRoutes {
    CREATE_ENTRY = '/entries/add',
    GROUP_LIST = '/groups',
    PROFILE = '/profile',
    MEMBER_DETAILS = '/user',
    REMINDERS = '/reminders',
    BUG_REPORT = '/report-bug',
    APPROVAL_QUEUE = '/admin/feed',
}

export enum NonAuthRoutes {
    POSITIVITY_FEED = '/',
    ABOUT = '/about',
    LOGIN = '/login',
    ADMIN_LOGIN = '/admin/login',
    ENTRIES = '/public/entries',
    REGISTER = '/register',
    FORGOT_PASSWORD = '/password-reset',
    REDEEM_INVITATION = '/redeem',
    VERIFICATION_SUCCESS = '/verification-success',
    EMAIL_UNSUBSCRIBE = '/email/unsubscribe'
}
