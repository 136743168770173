export enum GroupSidePanelModalScenarios {
    GRANTING_USER_MODERATOR_STATUS = 'GRANTING_USER_MODERATOR_STATUS',
    REVOKING_USER_MODERATOR_STATUS = 'REVOKING_USER_MODERATOR_STATUS',
    REMOVING_USER_FROM_GROUP = 'REMOVING_USER_FROM_GROUP',
    RESET_MODAL_STATE = 'RESET_MODAL_STATE',
}

export const initialGroupSidePanelModalState = {
    [GroupSidePanelModalScenarios.GRANTING_USER_MODERATOR_STATUS]: false,
    [GroupSidePanelModalScenarios.REVOKING_USER_MODERATOR_STATUS]: false,
    [GroupSidePanelModalScenarios.REMOVING_USER_FROM_GROUP]: false,
};
export type ModalScenarioResponse = { verbiage: string; action: () => void };

export default (
    state = initialGroupSidePanelModalState,
    action: { type: GroupSidePanelModalScenarios; callback?: () => void }
) => {
    if (action.type === GroupSidePanelModalScenarios.RESET_MODAL_STATE) {
        return initialGroupSidePanelModalState;
    } else {
        action.callback?.();
        return { ...initialGroupSidePanelModalState, [action.type]: true };
    }
};
