import React, { Component, ReactNode } from 'react';
import { RFC } from '../types/generalTypes';
import { createPortal } from 'react-dom';
import { Theme } from '../theme';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    children: ReactNode;
}
const Modal: RFC<Props> = ({ isOpen, closeModal, children }) => (
    <Portal>
        {isOpen && (
            <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
                <div className="absolute w-full h-full bg-gray-900 opacity-50 z-30" />
                <div
                    className={`bg-white w-11/12 md:w-1/2 lg:max-w-md mx-auto bg-${Theme.offWhite} rounded shadow-lg z-50 overflow-auto md:py-3`}
                    style={{ maxHeight: '800px' }}
                >
                    <div className="py-4 px-6">
                        <div className="flex justify-end items-center pb-3">
                            <button className="cursor-pointer z-40" onClick={closeModal}>
                                <svg
                                    className="fill-current text-gray-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                >
                                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                                </svg>
                            </button>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        )}
    </Portal>
);

class Portal extends Component {
    private readonly el: HTMLDivElement;
    constructor(props: any) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount = () => {
        const modalRoot = document.getElementById('modal');

        modalRoot?.appendChild(this.el);
    };

    componentWillUnmount = () => {
        const modalRoot = document.getElementById('modal');

        modalRoot?.removeChild(this.el);
    };

    render() {
        const { children } = this.props;
        return createPortal(children, this.el);
    }
}

export default Modal;
