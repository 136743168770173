export const API_URL = (() => {
    switch (process.env.REACT_APP_ENV) {
        case 'prod':
            return 'https://api.the3goodthings.org';
        case 'client':
            return 'https://api-client.the3goodthings.org';
        default:
            return 'https://api-internal.the3goodthings.org';
    }
})();

export namespace Environment {
    export function get() {
        switch (process.env.REACT_APP_ENV) {
            case 'prod':
                return Environment.prod;
            case 'client':
                return Environment.client;
            case 'internal':
                return Environment.internal;
            default:
                return Environment.dev;
        }
    }

    export function isProd() { return process.env.REACT_APP_ENV === "prod"; }
    export function isInternal() { return process.env.REACT_APP_ENV === "internal"; }
}

export enum Environment {
    dev = 1,
    internal,
    client,
    prod
}
