import React, { useContext, useRef, useState } from 'react';
import { Entry, Privacy } from '../types/entryTypes';
import { RFC } from '../types/generalTypes';
import {
    copyToClipboard,
    renderFullEntryDateFromMongoId,
    renderRelativeEntryDateFromMongoId,
} from '../utils';
import { toggleEntryVisibility } from '../api/group';
import { AuthRoutes, NonAuthRoutes } from '../routes';
import { UserContext } from '../context/UserContextProvider';
import UserAvatar from './UserAvatar';
import { Link, navigate } from '@reach/router';
import useOutsideClick from '../hooks/useOutsideClick';
import { Theme } from '../theme';
import IconManager, { IconType } from './IconManager';
import { toggleEntryLovedStatus } from '../api/entry';
import { Analytics } from '../util/Analytics';

interface Props {
    entry: Entry;
    groupId?: string;
    userIsModerator?: boolean;
    updateEntryInLocalState?: (updatedEntry: Entry) => void;
    shouldLinkToMemberProfile?: boolean;
    preventLinking?: boolean;
    viewingMemberProfile?: boolean;
    initiateDelete?: () => void;
    initiateEdit?: () => void;
}

const Entry: RFC<Props> = ({
    entry,
    groupId,
    userIsModerator = false,
    updateEntryInLocalState,
    initiateDelete,
    preventLinking,
    initiateEdit,
    shouldLinkToMemberProfile = false,
}) => {
    const { currentUser } = useContext(UserContext);
    const [entryActionsVisible, setEntryActionsVisible] = useState<boolean>(false);
    const actionButtonRef = useRef<HTMLButtonElement>(null);
    const [updateEntryError, setUpdateEntryError] = useState<{ entryId?: string; error: boolean }>({
        error: false,
    });
    useOutsideClick({ ref: actionButtonRef, callback: () => setEntryActionsVisible(false) });

    const toggleEntryHidden = async (): Promise<void> => {
        if (groupId) {
            try {
                (!entry.hidden
                    ? Analytics.Event.EntryHidden
                    : Analytics.Event.EntryUnhidden
                ).track();
                updateEntryInLocalState?.({ ...entry, hidden: !entry.hidden });
                await toggleEntryVisibility({ groupId, entryId: entry._id });
            } catch (error) {
                //revert back to previous entry state
                updateEntryInLocalState?.(entry);
                error = error.json ? await error.json() : error;
                setUpdateEntryError({ entryId: entry._id, error: true });
                console.log(error);
            }
        }
    };

    const toggleLovedStatus = async (): Promise<void> => {
        try {
            (!entry.lovedByCurrentUser
                ? Analytics.Event.EntryLoved
                : Analytics.Event.EntryUnloved
            ).track();
            updateEntryInLocalState?.({
                ...entry,
                lovedByCurrentUser: !entry.lovedByCurrentUser,
                lovedByCount: !entry.lovedByCurrentUser
                    ? entry.lovedByCount + 1
                    : entry.lovedByCount - 1,
            });
            await toggleEntryLovedStatus({ entryId: entry._id, love: !entry.lovedByCurrentUser });
        } catch (error) {
            //revert back to previous entry state
            updateEntryInLocalState?.(entry);
            error = error.json ? await error.json() : error;
            setUpdateEntryError({ entryId: entry._id, error: true });
            console.log(error);
        }
    };

    const renderPrivacySelection = (privacy: Privacy) => {
        switch (privacy) {
            case Privacy.PRIVATE:
                return 'Private to you';
            case Privacy.PUBLIC:
                return 'Shared publicly';
            case Privacy.SHARED:
                return 'Shared with groups';
        }
    };

    const EntryActionButton = (): JSX.Element | null => {
        if (userIsModerator && entry.author._id !== currentUser?._id) {
            return (
                <>
                    <button
                        className="flex my-2 text-sm text-gray-600 flex-row items-center"
                        onClick={async e => {
                            e.stopPropagation();
                            await toggleEntryHidden();
                        }}
                    >
                        {entry.hidden ? (
                            <IconManager
                                type={IconType.SHOW_ENTRY}
                                size={30}
                                stroke="#788CA2"
                                strokeWidth={1}
                                className="mr-2"
                            />
                        ) : (
                            <IconManager
                                type={IconType.HIDE_ENTRY}
                                fill="#788CA2"
                                className="mr-2"
                            />
                        )}
                        <span>{entry.hidden ? 'Show' : 'Hide'}</span>
                    </button>
                    {entry.privacy === Privacy.PUBLIC && (
                        <button
                            className="flex my-2 text-sm text-gray-600 flex-row items-center"
                            onClick={e => {
                                e.stopPropagation();
                                copyToClipboard(
                                    `${window.location.origin}/public/entries/${entry._id}`
                                );
                            }}
                        >
                            <IconManager
                                type={IconType.SHARE_LINK}
                                stroke="#788CA2"
                                className="mr-2"
                            />
                            Copy link
                        </button>
                    )}
                </>
            );
        } else if (entry.author._id === currentUser?._id) {
            return (
                <div>
                    <button
                        className="flex text-sm text-gray-600 flex-row items-center mb-2"
                        onClick={e => {
                            e.stopPropagation();
                            initiateDelete?.();
                        }}
                    >
                        <IconManager
                            type={IconType.DELETE_ENTRY}
                            size={22}
                            className="mr-1"
                            stroke="#788CA2"
                        />
                        <span>Delete</span>
                    </button>
                    <button
                        className="flex text-sm text-gray-600 flex-row items-center"
                        onClick={e => {
                            e.stopPropagation();
                            initiateEdit?.();
                        }}
                    >
                        <IconManager
                            type={IconType.EDIT_ENTRY_PRIVACY}
                            size={22}
                            className="mr-1"
                            stroke="#788CA2"
                        />
                        <span>Edit Visibility</span>
                    </button>
                </div>
            );
        } else {
            return (
                <button
                    className="flex my-2 text-sm text-gray-600 flex-row items-center"
                    onClick={e => {
                        e.stopPropagation();
                        copyToClipboard(`${window.location.origin}/public/entries/${entry._id}`);
                    }}
                >
                    <IconManager type={IconType.SHARE_LINK} stroke="#788CA2" className="mr-2" />
                    Copy link
                </button>
            );
        }
    };
    return (
        <div
            className={`p-5 rounded-md border border-gray-300 bg-gray-200 md:border-gray-200 md:bg-gray-100 shadow mb-5 ${
                entry.privacy === Privacy.PUBLIC && !preventLinking
                    ? 'md:cursor-pointer md:hover:bg-gray-200'
                    : ''
            }`}
            onClick={
                entry.privacy === Privacy.PUBLIC && !preventLinking
                    ? async () => {
                          await navigate(NonAuthRoutes.ENTRIES + `/${entry._id}`);
                      }
                    : undefined
            }
        >
            <div className="flex flex-row justify-start items-center px-3 relative">
                {currentUser ? (
                    shouldLinkToMemberProfile ? (
                        <Link
                            onClick={e => e.stopPropagation()}
                            to={
                                entry.author._id === currentUser?._id
                                    ? AuthRoutes.PROFILE
                                    : AuthRoutes.MEMBER_DETAILS + `/${entry.author._id}`
                            }
                        >
                            <UserAvatar
                                displayName={entry.author.displayName}
                                profileImageUrl={entry.author.profileImage}
                                backgroundColor={entry.author.backgroundColor}
                                avatarSize={entry.author.profileImage ? '75px' : '70px'}
                            />
                        </Link>
                    ) : (
                        <UserAvatar
                            displayName={entry.author.displayName}
                            profileImageUrl={entry.author.profileImage}
                            backgroundColor={entry.author.backgroundColor}
                            avatarSize={entry.author.profileImage ? '75px' : '70px'}
                        />
                    )
                ) : (
                    <svg height="75" width="75" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="45" fill="#C9C9C9" />
                    </svg>
                )}
                <div className="ml-3 mt-4 text-lg text-left">
                    {shouldLinkToMemberProfile ? (
                        <Link
                            onClick={e => e.stopPropagation()}
                            to={
                                entry.author._id === currentUser?._id
                                    ? AuthRoutes.PROFILE
                                    : AuthRoutes.MEMBER_DETAILS + `/${entry.author._id}`
                            }
                            className="font-bold block text-blue-600 hover:text-blue-800"
                        >
                            {entry.author.displayName}
                        </Link>
                    ) : (
                        <h3 className="font-bold block">{entry.author.displayName}</h3>
                    )}
                    <span
                        className="text-gray-600 text-sm"
                        title={renderFullEntryDateFromMongoId(entry._id)}
                    >
                        {renderRelativeEntryDateFromMongoId(entry._id)}
                    </span>
                    <span className="block text-blue-500 flex flex-row items-center text-xs mt-1 md:text-sm">
                        {renderPrivacySelection(entry.privacy)}
                    </span>
                </div>
                <div className="ml-auto">
                    <button
                        ref={actionButtonRef}
                        className="cursor-pointer"
                        onClick={e => {
                            e.stopPropagation();
                            setEntryActionsVisible(!entryActionsVisible);
                        }}
                    >
                        <IconManager type={IconType.ENTRY_ACTION_BUTTON} stroke="#4A5568" />
                    </button>
                </div>
                <div
                    className={`${
                        entryActionsVisible ? 'block' : 'hidden'
                    } absolute flex flex-col justify-start items-start border shadow-md w-3/5 md:w-1/5 p-3 rounded-md bg-${
                        Theme.offWhite
                    } right-0`}
                    style={{ top: '50px' }}
                >
                    <EntryActionButton />
                </div>
            </div>
            {entry.hidden && (
                <span className="block text-orange-400 p-2 flex flex-row items-center text-xs ml-3 md:text-sm">
                    <IconManager
                        type={IconType.HIDE_ENTRY}
                        size={17}
                        className="mr-1"
                        fill="#FFAA3E"
                    />
                    Visible only to moderators
                </span>
            )}
            {updateEntryError && entry._id === updateEntryError.entryId && (
                <p className="text-red-500 text-sm text-center">
                    An error occurred while updating the entry. Please check your internet
                    connection & try again.
                </p>
            )}
            <ul className="py-3 px-5 list-disc text-left flex flex-col flex-wrap">
                {entry.items.map((goodThing, i) => (
                    <li className="block mt-3 py-2 w-full" key={i}>
                        <p className="text-lg block" style={{ wordBreak: 'break-word' }}>
                            {goodThing}
                        </p>
                    </li>
                ))}
            </ul>
            {/*only show the loved by count to the user if there's at least one*/}
            {(currentUser?._id !== entry.author._id ||
                (currentUser._id === entry.author._id && entry.lovedByCount > 0)) && (
                <button
                    className={`mr-2 cursor-${
                        currentUser?._id !== entry.author._id
                            ? 'pointer border border-gray-300 hover:bg-gray-100 rounded-md bg-white'
                            : 'default'
                    } px-2 py-1 flex flex-row items-center ml-auto`}
                    onClick={async e => {
                        //ensure user can't "love" their own entry -- narcissism much?
                        if (currentUser?._id !== entry.author._id) {
                            e.stopPropagation();
                            await toggleLovedStatus();
                        }
                    }}
                >
                    {entry.lovedByCount > 0 && (
                        <span className="text-xs text-gray-600 mr-1">{entry.lovedByCount}</span>
                    )}
                    <IconManager
                        type={IconType.HEART}
                        stroke="#f56565"
                        fill={
                            entry.lovedByCurrentUser || currentUser?._id === entry.author._id
                                ? '#f56565'
                                : 'none'
                        }
                        size={26}
                    />
                </button>
            )}
        </div>
    );
};

export default Entry;
