import { useEffect, useState, Dispatch, SetStateAction } from 'react';

export default (key: string): [boolean, Dispatch<SetStateAction<boolean>>] => {
    const [sessionState, setSessionState] = useState<boolean>(!!sessionStorage.getItem(key));

    useEffect(() => {
        const storageListener = async (e: StorageEvent): Promise<void> => {
            setSessionState(!!e.storageArea?.getItem(key));
        };
        window.addEventListener('storage', storageListener);

        return () => {
            window.removeEventListener('storage', storageListener);
        };
    }, []);

    useEffect(() => {
        if (sessionState) {
            sessionStorage.setItem(key, key);
        }
    }, [sessionState]);

    return [sessionState, setSessionState];
};
