import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { RFC } from '../types/generalTypes';
interface Props {
    children: ReactNode;
    gridGap?: string;
    className?: string;
    colWidth?: string;
}

const AutoGrid: RFC<Props> = ({ children, gridGap = '1.5rem', className, colWidth = '260px' }) => (
    <Grid gridGap={gridGap} className={className} colWidth={colWidth}>
        {children}
    </Grid>
);

export default AutoGrid;

const Grid = styled.div<Pick<Props, 'gridGap' | 'colWidth'>>`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${({ colWidth }) => colWidth}, 1fr));
    grid-gap: ${({ gridGap }) => gridGap};
    align-items: stretch;
    padding: 1rem;
    align-content: space-around;
    justify-content: space-between;
`;
