import React from 'react';
import Modal from './Modal';
import { RFC } from '../types/generalTypes';
import { ModalScenarioResponse } from '../reducers/groupSidePanelModalReducer';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    currentModalScenario?: ModalScenarioResponse;
}

const GroupMembersModal: RFC<Props> = ({ isOpen, closeModal, currentModalScenario }) => (
    <Modal isOpen={isOpen} closeModal={closeModal}>
        <div className="w-full px-5 py-3">
            <h3 className="font-semibold text-center text-gray-700">{currentModalScenario?.verbiage}</h3>
            <div className="mt-2 mx-auto flex flex-row justify-center items-center w-full p-2">
                <button className="bg-transparent font-bold text-gray-500 mr-2 px-2 py-1" onClick={closeModal}>
                    Cancel
                </button>
                <button className="bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white px-2 py-1" onClick={currentModalScenario?.action}>
                    Yes
                </button>
            </div>
        </div>
    </Modal>
);
export default GroupMembersModal;
