export enum GroupDetailsModalScenarios {
    SHARING_GROUP_INVITE = 'SHARING_GROUP_INVITE',
    VIEWING_MEMBERS = 'VIEWING_MEMBERS',
    LEAVING_GROUP = 'LEAVING_GROUP',
    DELETING_OWN_ENTRY = 'DELETING_OWN_ENTRY',
    EDITING_ENTRY_PRIVACY = 'EDITING_ENTRY_PRIVACY',
    RESET_MODAL_STATE = 'RESET_MODAL_STATE',
}

export const initialGroupDetailModalState = {
    [GroupDetailsModalScenarios.SHARING_GROUP_INVITE]: false,
    [GroupDetailsModalScenarios.VIEWING_MEMBERS]: false,
    [GroupDetailsModalScenarios.LEAVING_GROUP]: false,
    [GroupDetailsModalScenarios.DELETING_OWN_ENTRY]: false,
    [GroupDetailsModalScenarios.EDITING_ENTRY_PRIVACY]: false,
};

export default (
    state = initialGroupDetailModalState,
    action: { type: GroupDetailsModalScenarios; callback?: () => void }
) => {
    if (action.type === GroupDetailsModalScenarios.RESET_MODAL_STATE) {
        return initialGroupDetailModalState;
    } else {
        action.callback?.();
        return { ...initialGroupDetailModalState, [action.type]: true };
    }
};
