import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import {
    FormSubmission,
    ImageFilePreview,
    InputChange,
    ReactSelectOption,
    ReactSelectValue,
    RFC,
} from '../types/generalTypes';
import { UserContext } from '../context/UserContextProvider';
import LoadingSpinner from '../components/LoadingSpinner';
import { timeZones } from '../timeZones';
import { ChangePasswordRequest, Error, FetchRequest } from '../types/networkTypes';
import { Entry as EntryType, Privacy } from '../types/entryTypes';
import { deleteEntry, editEntryPrivacy, getEntries } from '../api/entry';
import { AuthRoutes } from '../routes';
import { Link } from '@reach/router';
import Entry from '../components/Entry';
import ErrorMessage from '../components/ErrorMessage';
import UserAvatar from '../components/UserAvatar';
import { UpdateUserFormState, UpdateUserRequest } from '../types/userTypes';
import Select from '../components/Select';
import { CirclePicker } from 'react-color';
import { deleteProfileImage, getProfileImageUploadUrl, updateUser } from '../api/user';
import Modal from '../components/Modal';
import { changePassword } from '../api/auth';
import SubmitButton from '../components/SubmitButton';
import modalReducer, {
    initialProfileModalState,
    ProfileModalScenarios,
    ProfileModalScenarios as ModalScenarios,
} from '../reducers/profileModalReducer';
import InfoBanner from '../components/InfoBanner';
import { getGroups } from '../api/group';
import EntryPrivacySection from '../components/EntryPrivacySection';
import MoreEntriesLoader from '../components/MoreEntriesLoader';
import ImageUploader from '../components/ImageUploader';
import editProfileReducer, {
    EditProfileScenarios,
    initialEditProfileState,
} from '../reducers/editProfileReducer';
import { Analytics } from '../util/Analytics';
import isAuthed from '../hooks/isAuthed';

interface ChangePasswordForm extends ChangePasswordRequest {
    passwordConfirm: '';
}

enum ChangePasswordFormKeys {
    password = 'password',
    newPassword = 'newPassword',
    passwordConfirm = 'passwordConfirm',
}

const Profile: RFC = () => {
    isAuthed();
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [entries, setEntries] = useState<FetchRequest<EntryType[], Error>>({
        fetching: true,
        data: [],
        error: null,
    });
    const [editingProfile, setEditingProfile] = useState<boolean>(false);
    const [profile, setProfile] = useState<UpdateUserRequest>();
    const [selectedTimeZone, setSelectedTimeZone] = useState<ReactSelectOption>();
    const [updateProfileError, setUpdateProfileError] = useState<Error | null>(null);
    const [changePasswordStatus, setChangePasswordStatus] = useState<{
        success?: boolean;
        error: Error | null;
        submitting: boolean;
    }>({
        error: null,
        submitting: false,
    });
    const [formValid, setFormValid] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalScenario, dispatchProfileModal] = useReducer(
        modalReducer,
        initialProfileModalState
    );
    const [changePasswordForm, setChangePasswordForm] = useState<ChangePasswordForm>({
        password: '',
        newPassword: '',
        passwordConfirm: '',
    });
    const [changePasswordFormValid, setChangePasswordFormValid] = useState<boolean>(false);
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
    const [passwordTooShort, setPasswordTooShort] = useState<boolean>();
    const [entryInFocus, setEntryInFocus] = useState<EntryType>();
    const [encourageGroupCreationAlertVisible, setEncourageGroupCreationAlertVisible] = useState<
        boolean
    >(false);
    const [page, setPage] = useState<{ current: number; totalPages?: number }>({ current: 1 });
    const [loadingMoreEntries, setLoadingMoreEntries] = useState<boolean>(false);
    const [editProfileScenario, dispatchEditProfile] = useReducer(
        editProfileReducer,
        initialEditProfileState
    );
    const [uploadedImageFile, setUploadedImageFile] = useState<ImageFilePreview>();
    const [submittingProfileUpdates, setSubmittingProfileUpdates] = useState<boolean>(false);
    const displayNameInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        (async () => {
            if (currentUser) {
                setProfile({
                    reminders: currentUser.reminders,
                    displayName: currentUser.displayName,
                    backgroundColor: currentUser.backgroundColor,
                    timeZone: currentUser.timeZone,
                    profileImage: currentUser.profileImage,
                });
                // If we decide to support "Non-Integer" timezones, this will not work properly
                setSelectedTimeZone(
                    timeZones.find(
                        ({ value }) => Number(value.split(':')[0]) === currentUser?.timeZone
                    )
                );
                try {
                    const [{ entries, pages }, userGroups] = await Promise.all([
                        getEntries({ page: page.current }),
                        getGroups(),
                    ]);
                    setEncourageGroupCreationAlertVisible(!userGroups.length);
                    setPage(prev => ({ ...prev, totalPages: pages }));
                    //line 87-113 are to ensure that each entry.author.profileImage is updated appropriately and pagination is persisted
                    //it definitely needs to be simplified/refactored
                    if (
                        currentUser?.profileImage &&
                        entries.find(
                            ({ author }) =>
                                !author.profileImage ||
                                author.profileImage !== currentUser.profileImage
                        )
                    ) {
                        setEntries(prev => ({
                            ...prev,
                            data: prev.data.map(entry => ({
                                ...entry,
                                author: { ...entry.author, profileImage: currentUser.profileImage },
                            })),
                        }));
                    }
                    //@ts-ignore
                    setEntries(prev => {
                        let updatedEntriesWithProfileImage: EntryType[] | null = null;
                        if (
                            currentUser?.profileImage &&
                            entries.find(
                                ({ author }) =>
                                    !author.profileImage ||
                                    author.profileImage !== currentUser.profileImage
                            )
                        ) {
                            updatedEntriesWithProfileImage = entries.map(entry => ({
                                ...entry,
                                author: { ...entry.author, profileImage: currentUser.profileImage },
                            }));
                        }
                        return {
                            ...prev,
                            data:
                                page.current === 1
                                    ? updatedEntriesWithProfileImage ?? entries
                                    : [
                                          ...prev.data,
                                          updatedEntriesWithProfileImage?.flat() ?? entries.flat(),
                                      ],
                        };
                    });
                } catch (error) {
                    error = error.json ? await error.json() : error;
                    console.log(error);
                    setEntries(prev => ({ ...prev, error }));
                }
                setEntries(prev => ({ ...prev, fetching: false }));
            }
        })();
    }, [currentUser, page.current]);

    const loadMoreEntries = () => {
        setLoadingMoreEntries(true);
        setPage(prev => ({ ...prev, current: prev.current + 1 }));
        setTimeout(() => {
            setLoadingMoreEntries(false);
        }, 1000);
    };

    useEffect(() => {
        setPasswordsMatch(changePasswordForm.passwordConfirm === changePasswordForm.newPassword);
    }, [changePasswordForm.newPassword, changePasswordForm.passwordConfirm]);

    useEffect(() => {
        setFormValid(!!profile?.displayName && !!profile.timeZone);
    }, [profile?.displayName, profile?.timeZone]);

    const updateUserProfile = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        try {
            setSubmittingProfileUpdates(true);
            //remove profileImage from profile props before updating user because profileImage is updated through its own endpoint
            let { profileImage, ...profileProps } = profile!;
            //this is a hack to have displayName input be uncontrolled to prevent typing from re-rendering the component tree
            //in which it's rendered
            if (displayNameInputRef.current?.value !== profileProps.displayName) {
                profileProps = { ...profileProps, displayName: displayNameInputRef.current?.value };
            }
            if (displayNameInputRef.current?.value !== profileProps.displayName) {
                profileProps.displayName = displayNameInputRef.current?.value;
            }
            const updatedUser = await updateUser(profileProps);
            const profileImageUploadUrl = uploadedImageFile
                ? await getProfileImageUploadUrl()
                : null;
            if (profileImageUploadUrl) {
                await fetch(profileImageUploadUrl, {
                    method: 'PUT',
                    body: uploadedImageFile,
                    headers: { 'Content-Type': 'image/jpeg' },
                });
                //update currentUser profileImage
                setCurrentUser({ ...updatedUser, profileImage: uploadedImageFile?.preview });
                setUploadedImageFile(undefined);
            } else {
                setCurrentUser(updatedUser);
            }
            setEditingProfile(false);
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setUpdateProfileError(error);
        }
        setSubmittingProfileUpdates(false);
    };

    const handleChangePassword = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        setChangePasswordStatus(prev => ({ ...prev, submitting: true }));
        Analytics.Event.ChangedPassword.track();
        try {
            delete changePasswordForm.passwordConfirm;
            await changePassword(changePasswordForm);
            setChangePasswordStatus({ submitting: false, error: null, success: true });
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setChangePasswordStatus({ success: false, error, submitting: false });
        }
        changePasswordForm.passwordConfirm = '';
        setChangePasswordStatus(prev => ({ ...prev, submitting: false }));
    };

    useEffect(() => {
        setChangePasswordFormValid(
            changePasswordForm.password.length >= 8 &&
                changePasswordForm.newPassword.length >= 8 &&
                changePasswordForm?.passwordConfirm?.length >= 8 &&
                changePasswordForm.newPassword === changePasswordForm.passwordConfirm
        );
    }, [
        changePasswordForm.password,
        changePasswordForm.newPassword,
        changePasswordForm.passwordConfirm,
    ]);

    const cancelEdit = () => {
        setEditingProfile(false);
        setProfile(currentUser!);
    };

    useEffect(() => {
        if (!modalOpen) {
            setChangePasswordStatus({ submitting: false, error: null });
            dispatchProfileModal({ type: ModalScenarios.RESET_MODAL_STATE });
        }
    }, [modalOpen]);

    useEffect(() => {
        if (!editingProfile) {
            dispatchEditProfile({ type: EditProfileScenarios.RESET_EDIT_PROFILE_SCENARIO });
        }
    }, [editingProfile]);

    const handleChangeTimeZone = (timeZone: ReactSelectValue) => {
        Analytics.Event.ChangedTimezone.track();
        const timeZoneStr = (timeZone as ReactSelectOption).value.split(':')[0];
        setProfile(prev => ({
            ...prev,
            timeZone: timeZoneStr.startsWith('-')
                ? Number(timeZoneStr)
                : Number(timeZoneStr.slice(0)),
        }));
        setSelectedTimeZone(timeZone as ReactSelectOption);
    };

    const handleChangePasswordFormInput = (e: InputChange) => {
        e.persist();
        setChangePasswordForm(prev => ({
            ...prev,
            [e.target.name as ChangePasswordFormKeys]: e.target.value,
        }));
    };

    const openModal = (scenario: ModalScenarios) =>
        dispatchProfileModal({ type: scenario, callback: () => setModalOpen(true) });

    const handleEntryDelete = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        if (entryInFocus) {
            try {
                Analytics.Event.EntryDeleted.track();
                await deleteEntry(entryInFocus._id);
                setEntries(prev => ({
                    ...prev,
                    data: prev.data.filter(({ _id }) => _id !== entryInFocus._id),
                }));
            } catch (error) {
                error = error.json ? await error.json() : error;
                console.log(error);
                setEntries(prev => ({ ...prev, error }));
            }
        }
    };

    const handleUpdateEntryPrivacy = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        if (entryInFocus) {
            try {
                Analytics.Event.EntryPrivacyUpdated.track();
                const updatedEntry = await editEntryPrivacy({
                    entryId: entryInFocus._id,
                    privacy: entryInFocus.privacy,
                });
                updateEntryInLocalState(updatedEntry);
            } catch (error) {
                error = error.json ? await error.json() : error;
                console.log(error);
                setEntries(prev => ({ ...prev, error }));
            }
        }
    };

    const handleDeleteProfileImage = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        try {
            Analytics.Event.DeletedProfileImage.track();
            await deleteProfileImage();
            setUploadedImageFile(undefined);
            dispatchEditProfile({ type: EditProfileScenarios.REMOVING_PROFILE_IMAGE });
            setProfile({ ...profile, profileImage: undefined });
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setUpdateProfileError(error);
        }
    };

    const handleEntryPrivacyInput = (e: InputChange) => {
        e.persist();
        setEntryInFocus(prev => (prev ? { ...prev, privacy: e.target.value as Privacy } : prev));
    };

    const updateEntryInLocalState = (updatedEntry: EntryType) =>
        setEntries(prev => ({
            ...prev,
            data: prev.data.map(entry => (entry._id === updatedEntry._id ? updatedEntry : entry)),
        }));

    const asyncSetEntryInFocus = (entry: EntryType): Promise<EntryType> => {
        return new Promise(resolve => {
            setEntryInFocus(entry);
            resolve(entry);
        });
    };
    const EditProfileSection: React.FC = (): JSX.Element => {
        const UserInfo: RFC = () => (
            <div className="text-left" key={Math.random()}>
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor={UpdateUserFormState.displayName}
                >
                    <span className="text-gray-700">Name</span>
                    <input
                        type="text"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        name={UpdateUserFormState.displayName}
                        defaultValue={profile?.displayName}
                        ref={displayNameInputRef}
                        // value={profile?.displayName}
                        // onChange={handleInput}
                    />
                </label>
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor={UpdateUserFormState.timeZone}
                >
                    <span className="text-gray-700">Time Zone</span>
                    <Select
                        onChange={handleChangeTimeZone}
                        options={timeZones}
                        selectedOption={selectedTimeZone}
                    />
                </label>
            </div>
        );
        switch (
            Object.keys(editProfileScenario).filter(
                scenario => editProfileScenario[scenario]
            )[0] as EditProfileScenarios
        ) {
            case EditProfileScenarios.ADDING_PROFILE_IMAGE:
            case EditProfileScenarios.CHANGING_PROFILE_IMAGE:
                return (
                    <div className="p-3">
                        <div className="text-center mx-auto p-4">
                            <button
                                className="text-blue-500 mx-auto hover:text-blue-700 font-semibold mb-2"
                                onClick={e => {
                                    e.preventDefault();
                                    dispatchEditProfile({
                                        type: EditProfileScenarios.RESET_EDIT_PROFILE_SCENARIO,
                                    });
                                }}
                            >
                                {currentUser?.profileImage || uploadedImageFile ? 'Change' : 'Add'}{' '}
                                profile picture
                            </button>
                            {uploadedImageFile ? (
                                <div className="flex flex-col justify-center text-left">
                                    <UserAvatar
                                        uploadedProfileImage={uploadedImageFile}
                                        className="mx-auto mb-2"
                                    />
                                    <UserInfo />
                                </div>
                            ) : (
                                <ImageUploader setUploadedImageFile={setUploadedImageFile} />
                            )}
                        </div>
                    </div>
                );
            case EditProfileScenarios.CHOOSING_AVATAR_COLOR:
            case EditProfileScenarios.REMOVING_PROFILE_IMAGE:
                return (
                    <div className="p-3 text-center">
                        <span className="text-gray-600 font-semibold">Choose avatar color</span>
                        <div className="p-3 mx-auto flex flex-row justify-center my-3">
                            <CirclePicker
                                onChange={({ hex }) =>
                                    setProfile(prev => ({ ...prev, backgroundColor: hex }))
                                }
                            />
                        </div>
                        <UserInfo />
                    </div>
                );
            default:
                return (
                    <div className="p-3">
                        <div className="text-center mx-auto p-4">
                            <button
                                className="text-blue-500 mx-auto hover:text-blue-700 font-semibold"
                                onClick={e => {
                                    e.preventDefault();
                                    dispatchEditProfile({
                                        type: EditProfileScenarios.ADDING_PROFILE_IMAGE,
                                    });
                                }}
                            >
                                {currentUser?.profileImage ? 'Change' : 'Add'} profile picture
                            </button>
                            <br />
                            <span className="text-gray-600 text-sm">or</span>
                            <br />
                            {!currentUser?.profileImage ? (
                                <span className="text-gray-600 font-semibold mb-3 block">
                                    Change avatar color
                                </span>
                            ) : (
                                <button
                                    onClick={e => {
                                        e.preventDefault();
                                        openModal(ProfileModalScenarios.DELETING_PROFILE_IMAGE);
                                    }}
                                    className="text-red-500 hover:text-red-600 font-semibold mx-auto mb-3 block"
                                >
                                    Remove profile picture
                                </button>
                            )}
                            {!currentUser?.profileImage && (
                                <div className="pl-2">
                                    <CirclePicker
                                        onChange={({ hex }) =>
                                            setProfile(prev => ({ ...prev, backgroundColor: hex }))
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <UserInfo />
                    </div>
                );
        }
    };

    return (
        <div className="px-5">
            {!currentUser || entries.fetching ? (
                <LoadingSpinner type="page" />
            ) : (
                <div className="max-w-3xl mx-auto">
                    <div className="w-full p-2 flex rounded-md flex-col justify-center items-center">
                        <UserAvatar
                            displayName={profile?.displayName}
                            profileImageUrl={profile?.profileImage}
                            backgroundColor={profile?.backgroundColor}
                            viewingProfile={true}
                        />
                        <div className="flex flex-row justify-center items-center relative">
                            {editingProfile ? (
                                submittingProfileUpdates ? (
                                    <LoadingSpinner type="page" />
                                ) : (
                                    <form
                                        className="flex flex-col mt-2 justify-center bg-gray-100 p-2 border border-gray-200 w-full md:max-w-lg shadow rounded-md"
                                        onSubmit={updateUserProfile}
                                    >
                                        <EditProfileSection />
                                        <div className="flex flex-row justify-center my-2">
                                            <button
                                                className="text-gray-500 hover:text-gray-600 mr-2 mt-2"
                                                onClick={cancelEdit}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                disabled={!formValid}
                                                type="submit"
                                                className={`mt-2 bg-blue-600 hover:bg-blue-700 text-white font-bold px-2 rounded-md ${
                                                    !formValid
                                                        ? 'opacity-50 cursor-not-allowed'
                                                        : ''
                                                }`}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                )
                            ) : (
                                <div className="flex flex-col justify-center">
                                    <h3 className="text-xl block text-center font-bold block">
                                        {profile?.displayName}
                                    </h3>
                                    <span className="text-gray-500 text-center">
                                        {selectedTimeZone?.label}
                                    </span>
                                    <h3 className="text-gray-700 font-bold">
                                        {currentUser?.email}
                                    </h3>
                                </div>
                            )}
                        </div>
                        {updateProfileError && (
                            <p className="text-red-500 text-lg">
                                Something went wrong while updating your profile. Please check your
                                internet connection and try again.
                            </p>
                        )}
                        {!editingProfile && (
                            <button
                                onClick={() => setEditingProfile(true)}
                                className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold px-2 py-1 rounded-md"
                            >
                                Edit Profile
                            </button>
                        )}
                        {!submittingProfileUpdates && (
                            <button
                                onClick={() => openModal(ModalScenarios.CHANGING_PASSWORD)}
                                className="text-blue-500 hover:text-blue-700 mt-1"
                            >
                                Change password
                            </button>
                        )}
                    </div>
                    {entries.error && <ErrorMessage code={entries.error?.code} />}
                    {!!entries.data.length ? (
                        <div className="mt-5">
                            {encourageGroupCreationAlertVisible && (
                                <div className="w-full flex flex-row justify-center items-center mb-4">
                                    <InfoBanner
                                        dismiss={() => setEncourageGroupCreationAlertVisible(false)}
                                        emphasis="It looks like you aren't a member of any groups"
                                    >
                                        <>
                                            <Link
                                                to={AuthRoutes.GROUP_LIST}
                                                className="underline text-blue-500 hover:text-blue-600 font-semibold"
                                            >
                                                Create a group
                                            </Link>{' '}
                                            and invite some friends so you all can share your three
                                            good things with one another
                                        </>
                                    </InfoBanner>
                                </div>
                            )}
                            <ul className="list-disc">
                                {entries.data.map(entry => (
                                    <Entry
                                        entry={entry}
                                        key={entry._id}
                                        updateEntryInLocalState={updateEntryInLocalState}
                                        initiateDelete={async () => {
                                            await asyncSetEntryInFocus(entry);
                                            openModal(ModalScenarios.DELETING_ENTRY);
                                        }}
                                        initiateEdit={async () => {
                                            await asyncSetEntryInFocus(entry);
                                            openModal(ModalScenarios.EDITING_ENTRY_PRIVACY);
                                        }}
                                    />
                                ))}
                            </ul>
                            <MoreEntriesLoader
                                initialPageFetching={entries.fetching}
                                loadingMoreEntries={loadingMoreEntries}
                                loadMoreEntries={loadMoreEntries}
                                activePage={page.current}
                                totalPages={page.totalPages}
                            />
                        </div>
                    ) : (
                        <div className="text-center text-gray-500 mt-5">
                            It looks like you don't have any entries.
                            <Link to={AuthRoutes.CREATE_ENTRY} className="text-blue-500 block">
                                Create one
                            </Link>
                        </div>
                    )}
                </div>
            )}
            {modalOpen && (
                <Modal isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
                    <div className="w-full px-5 py-3">
                        {modalScenario[ModalScenarios.CHANGING_PASSWORD] && (
                            <>
                                <h2 className="text-center text-xl font-bold">
                                    {changePasswordStatus.success
                                        ? 'Your password has been successfully changed'
                                        : 'Change Password'}
                                </h2>
                                {changePasswordStatus.success ? (
                                    <div className="mx-auto text-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="120"
                                            height="120"
                                            fill="#00BF71"
                                            className="mx-auto"
                                        >
                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z " />
                                        </svg>
                                        <button
                                            className="bg-transparent mx-auto text-blue-500 hover:text-blue-700"
                                            onClick={() => setModalOpen(false)}
                                        >
                                            Close
                                        </button>
                                    </div>
                                ) : (
                                    <form
                                        onSubmit={handleChangePassword}
                                        className="p-2 flex flex-col"
                                    >
                                        <div className="w-full px-3">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor={ChangePasswordFormKeys.password}
                                            >
                                                <span className="text-gray-700">
                                                    Current Password
                                                </span>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                                    type="password"
                                                    name={ChangePasswordFormKeys.password}
                                                    value={changePasswordForm.password}
                                                    onChange={handleChangePasswordFormInput}
                                                />
                                            </label>
                                        </div>
                                        <div className="w-full px-3">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor={ChangePasswordFormKeys.newPassword}
                                            >
                                                <span className="text-gray-700">New Password</span>
                                                <input
                                                    className={`shadow appearance-none border ${
                                                        passwordTooShort ? 'border-red-500' : ''
                                                    } rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
                                                    type="password"
                                                    name={ChangePasswordFormKeys.newPassword}
                                                    value={changePasswordForm.newPassword}
                                                    onChange={handleChangePasswordFormInput}
                                                    onBlur={() =>
                                                        setPasswordTooShort(
                                                            changePasswordForm.newPassword.length <
                                                                8
                                                        )
                                                    }
                                                />
                                                {passwordTooShort === true && (
                                                    <p className="text-red-500 text-sm italic font-normal">
                                                        Passwords must be at least 8 characters
                                                    </p>
                                                )}
                                            </label>
                                        </div>
                                        <div className="w-full px-3">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor={ChangePasswordFormKeys.passwordConfirm}
                                            >
                                                <span className="text-gray-700">
                                                    Confirm New Password
                                                </span>
                                                <input
                                                    className={`shadow appearance-none border ${
                                                        !!changePasswordForm.passwordConfirm &&
                                                        !passwordsMatch
                                                            ? 'border-red-500'
                                                            : !!changePasswordForm.passwordConfirm
                                                            ? 'border-green-500'
                                                            : ''
                                                    } rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline`}
                                                    type="password"
                                                    name={ChangePasswordFormKeys.passwordConfirm}
                                                    value={changePasswordForm.passwordConfirm}
                                                    onChange={handleChangePasswordFormInput}
                                                    onBlur={() =>
                                                        setPasswordTooShort(
                                                            changePasswordForm.newPassword.length >
                                                                8
                                                        )
                                                    }
                                                />
                                                {!!changePasswordForm.passwordConfirm && (
                                                    <p
                                                        className={`text-${
                                                            passwordsMatch ? 'green' : 'red'
                                                        }-500 italic font-normal text-sm`}
                                                    >
                                                        {passwordsMatch
                                                            ? 'Passwords match!'
                                                            : 'Passwords must match'}
                                                    </p>
                                                )}
                                            </label>
                                        </div>
                                        <SubmitButton
                                            disabled={!changePasswordFormValid}
                                            loading={changePasswordStatus.submitting}
                                            className="bg-green-500 hover:bg-green-700 mt-5 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                                        >
                                            CHANGE PASSWORD
                                        </SubmitButton>
                                    </form>
                                )}
                            </>
                        )}
                        {(modalScenario[ModalScenarios.DELETING_ENTRY] ||
                            modalScenario[ModalScenarios.DELETING_PROFILE_IMAGE]) && (
                            <form
                                onSubmit={async e => {
                                    modalScenario[ModalScenarios.DELETING_ENTRY]
                                        ? await handleEntryDelete(e)
                                        : await handleDeleteProfileImage(e);
                                    setModalOpen(false);
                                }}
                            >
                                <h3 className="font-semibold text-center text-gray-700">
                                    {modalScenario[ModalScenarios.DELETING_ENTRY]
                                        ? 'Are you sure you want to delete this entry?'
                                        : 'Are you sure you want to remove your profile image?'}
                                </h3>
                                <div className="mt-2 mx-auto flex flex-row justify-center items-center w-full p-2">
                                    <button
                                        className="bg-transparent font-bold text-gray-500 mr-2 px-2 py-1"
                                        onClick={() => setModalOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white px-2 py-1"
                                    >
                                        Yes
                                    </button>
                                </div>
                            </form>
                        )}
                        {modalScenario[ModalScenarios.EDITING_ENTRY_PRIVACY] && entryInFocus && (
                            <form
                                onSubmit={async e => {
                                    await handleUpdateEntryPrivacy(e);
                                    setModalOpen(false);
                                }}
                                className="text-center"
                            >
                                <EntryPrivacySection
                                    privacySelection={entryInFocus.privacy}
                                    handlePrivacySelection={handleEntryPrivacyInput}
                                />
                                <button
                                    type="submit"
                                    className="px-2 py-1 text-lg font-bold text-white bg-green-500 hover:bg-green-700 border-none rounded-md w-full md:w-2/3 mx-auto my-2"
                                >
                                    SAVE CHANGES
                                </button>
                            </form>
                        )}
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Profile;
