import React, { FormEvent, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { ButtonClick, FormSubmission, RFC } from '../types/generalTypes';
import PageHeader from '../components/PageHeader';
import { UserContext } from '../context/UserContextProvider';
import { ReminderOptions } from '../types/userTypes';
import { Error, NavStateKeys, VerificationOptions } from '../types/networkTypes';
import LoadingSpinner from '../components/LoadingSpinner';
import { Theme } from '../theme';
import { formatPhoneNumber, validatePhoneNumber } from '../utils';
import Modal from '../components/Modal';
import remindersModalReducer, {
    initialRemindersModalState,
    RemindersModalScenarios as ModalScenarios,
} from '../reducers/remindersModalReducer';
import SubmitButton from '../components/SubmitButton';
import { getCurrentUser, updateUser } from '../api/user';
import SuccessAlert from '../components/SuccessAlert';
import { verificationRequest } from '../api/auth';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import Alert from '../components/Alert';
import { isValid } from 'date-fns';
import { AuthRoutes } from '../routes';
import { Link, useLocation } from '@reach/router';
import IconManager, { IconType } from '../components/IconManager';
import isAuthed from '../hooks/isAuthed';

const Reminders: RFC = () => {
    isAuthed();
    const location = useLocation();
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [reminder, setReminder] = useState<{
        time?: number;
        timeStringValue?: string;
        method?: ReminderOptions;
    }>();
    const [phoneNumber, setPhoneNumber] = useState<{ value: string; valid?: boolean }>({
        value: '',
    });
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [verifyingEmail, setVerifyingEmail] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalScenario, dispatch] = useReducer(remindersModalReducer, initialRemindersModalState);
    const modalInputRef = useRef<HTMLInputElement>(null);
    const [creatingOrUpdatingReminder, setCreatingOrUpdatingReminder] = useState<{
        submitting: boolean;
        error: Error | null;
    }>({
        submitting: false,
        error: null,
    });
    const [formValid, setFormValid] = useState<boolean>(false);
    const [timeError, setTimeError] = useState<JSX.Element | undefined>(undefined);
    const [reminderAlertVisible, setReminderAlertVisible] = useState<boolean>(false);
    const [isUpdatingReminder, setIsUpdatingReminder] = useState<boolean>(false);
    const [userHasConfirmedEarlyReminder, setUserHasConfirmedEarlyReminder] = useState<boolean>(
        false
    );
    const [smsVerificationResentAlertVisible, setSMSVerificationResentAlertVisible] = useState<
        boolean
    >(false);

    const addUserPhoneNumber = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const updatedUser = await updateUser({
                phone: Number(
                    1 +
                        phoneNumber.value
                            .split('')
                            .filter(char => /\d/g.test(char))
                            .join('')
                ),
            });
            await requestVerification(VerificationOptions.PHONE);
            setCurrentUser(updatedUser);
            dispatch({ type: ModalScenarios.TEXT_MESSAGE_VERIFICATION_SENT });
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            dispatch({ type: ModalScenarios.TEXT_MESSAGE_VERIFICATION_ERROR });
        }
        setSubmitting(false);
    };

    const requestVerification = async (attribute: VerificationOptions): Promise<void> => {
        setVerifyingEmail(true);
        try {
            await verificationRequest(attribute);
            attribute === VerificationOptions.EMAIL
                ? dispatch({ type: ModalScenarios.EMAIL_VERIFICATION_SENT })
                : dispatch({ type: ModalScenarios.TEXT_MESSAGE_VERIFICATION_SENT });
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            attribute === VerificationOptions.EMAIL
                ? dispatch({ type: ModalScenarios.EMAIL_VERIFICATION_ERROR })
                : dispatch({ type: ModalScenarios.TEXT_MESSAGE_VERIFICATION_ERROR });
        }
        setVerifyingEmail(false);
    };

    const requestAnotherSMSVerification = async (e: ButtonClick): Promise<void> => {
        e.preventDefault();
        try {
            await requestVerification(VerificationOptions.PHONE);
            setSMSVerificationResentAlertVisible(true);
            setTimeout(() => {
                setSMSVerificationResentAlertVisible(false);
            }, 3000);
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            openModal(ModalScenarios.TEXT_MESSAGE_VERIFICATION_ERROR);
        }
    };

    useEffect(() => {
        if (
            reminder?.method &&
            reminder.time !== undefined &&
            !isNaN(reminder.time) &&
            !timeError
        ) {
            setFormValid(reminder.method !== ReminderOptions.NONE);
        }
    }, [reminder?.time, reminder?.method]);

    useEffect(() => {
        if (!modalOpen) {
            dispatch({ type: ModalScenarios.RESET_MODAL_STATE });
        } else {
            //focus the input because the modal's open
            modalInputRef.current?.focus();
        }
    }, [modalOpen]);

    const setUserReminderOptions = async (): Promise<void> => {
        if (currentUser) {
            setLoading(false);
            if (
                currentUser.reminderTime !== null &&
                currentUser.reminderTime !== undefined &&
                currentUser.reminders !== ReminderOptions.NONE
            ) {
                const date = new Date();
                date.setUTCHours(currentUser.reminderTime / 60);
                date.setUTCMinutes(currentUser.reminderTime % 60);
                const formattedReminderTime = format(date, 'h:mmaaaa');
                setReminder({
                    timeStringValue: formattedReminderTime,
                    time: currentUser.reminderTime,
                    method: currentUser.reminders,
                });
            }
        }
    };

    const shouldSuggestLaterReminderTime = ({
        reminderTime,
        userTimeZone,
    }: {
        reminderTime: number;
        userTimeZone: number;
    }): boolean => {
        let reminderTimeInUserTimeZone = reminderTime + userTimeZone * 60;
        if (reminderTimeInUserTimeZone < 0) {
            reminderTimeInUserTimeZone += 1440;
        }
        if (reminderTimeInUserTimeZone > 1440) {
            reminderTimeInUserTimeZone -= 1440;
        }
        return reminderTimeInUserTimeZone < 19 * 60;
    };

    useEffect(() => {
        (async () => {
            try {
                if (location.state?.[NavStateKeys.EMAIL_VERIFIED]) {
                    const updatedUser = await getCurrentUser();
                    setCurrentUser(updatedUser);
                }
            } catch (error) {
                error = error.json ? await error.json() : error;
                console.log(error);
            }
        })();
    }, [location.state?.[NavStateKeys.EMAIL_VERIFIED]]);

    useEffect(() => {
        (async () => {
            await setUserReminderOptions();
        })();
    }, [currentUser]);

    const setUserReminder = async (): Promise<void> => {
        //if user is trying to set reminder before 7:00pm local time, present confirmation modal with suggestion for later time
        if (
            shouldSuggestLaterReminderTime({
                reminderTime: reminder!.time as number,
                userTimeZone: currentUser!.timeZone,
            }) &&
            !userHasConfirmedEarlyReminder
        ) {
            openModal(ModalScenarios.SUGGEST_REMINDER_TIME_OF_DAY);
        } else {
            setModalOpen(false);
            setCreatingOrUpdatingReminder(prev => ({ ...prev, submitting: true }));
            try {
                const updatedUser = await updateUser({
                    reminders: reminder?.method,
                    reminderTime: reminder?.time,
                });
                setCurrentUser(updatedUser);
                setReminderAlertVisible(true);
                setTimeout(() => {
                    setReminderAlertVisible(false);
                }, 3000);
            } catch (error) {
                console.log(error);
                setCreatingOrUpdatingReminder(prev => ({ ...prev, error }));
            }
            setIsUpdatingReminder(false);
            setUserHasConfirmedEarlyReminder(false);
            setCreatingOrUpdatingReminder(prev => ({ ...prev, submitting: false }));
        }
    };

    const deleteReminder = async (): Promise<void> => {
        try {
            const updatedUser = await updateUser({ reminders: ReminderOptions.NONE });
            setCurrentUser(updatedUser);
            setReminder(prev => ({ ...prev, timeStringValue: undefined, time: undefined }));
        } catch (error) {
            console.log(error);
            setCreatingOrUpdatingReminder(prev => ({ ...prev, error }));
        }
        setModalOpen(false);
    };

    const reminderTimeChanged = (event: FormEvent) => {
        const value = (event.target as HTMLTextAreaElement).value;
        setReminder(prev => ({ ...prev, timeStringValue: value }));
        if (!timeError) {
            return;
        }
        parseTime(value);
    };

    const reminderTimeOnBlur = (event: FormEvent) => {
        const userInput = (event.target as HTMLTextAreaElement).value;
        if (!userInput) {
            return;
        }
        parseTime(userInput);
    };

    useEffect(() => {
        const triggerOnBlur = async (e: any): Promise<void> => {
            if (reminder?.timeStringValue) {
                parseTime(reminder.timeStringValue);
            }
            if (
                e.code === 'Enter' &&
                reminder?.method !== ReminderOptions.NONE &&
                reminder?.timeStringValue &&
                reminder.time !== undefined
            ) {
                await setUserReminder();
            }
        };
        document.addEventListener('keydown', triggerOnBlur);

        return () => {
            document.removeEventListener('keydown', triggerOnBlur);
        };
    }, [reminder?.method, reminder?.timeStringValue]);

    const formats = ['hh:mmaa', 'hh:mma', 'hh:mm aa', 'hh:mm a', 'HH:mm', 'hha'];
    const parseTime = (userInput: string) => {
        const validFormat = formats.find(format => isValid(parse(userInput, format, new Date())));
        if (validFormat || !userInput) {
            showFormatError(false);
            const parsedDate = parse(userInput, validFormat!, new Date());
            setReminder(prev => ({
                ...prev,
                timeStringValue: userInput,
                time: convertReminderTime(parsedDate),
            }));
        } else {
            showFormatError(true);
        }
    };

    const showFormatError = (show: boolean) => {
        if (show) {
            setTimeError(
                <span className="text-sm ml-3 block text-red-600">Time format is invalid</span>
            );
        } else {
            setTimeError(undefined);
        }
    };

    const openModal = (type: ModalScenarios) =>
        dispatch({ type, callback: () => setModalOpen(true) });
    const convertReminderTime = (reminderTime: Date) =>
        reminderTime.getUTCHours() * 60 + reminderTime.getUTCMinutes();
    const setReminderMethod = (method: ReminderOptions) =>
        setReminder(prev => ({ ...prev, method }));

    return (
        <div className="p-5">
            {loading ? (
                <LoadingSpinner type="page" />
            ) : (
                <>
                    <PageHeader
                        title={
                            currentUser?.reminders === ReminderOptions.NONE
                                ? 'Create a reminder'
                                : 'Update your reminder'
                        }
                    />
                    <div className="max-w-3xl ml-1 p-2">
                        <h4 className="text-lg text-gray-600">
                            We know life can be hectic. Let us help remind you to sit down and think
                            of
                            <br className="hidden md:block" /> <b>the 3 good things</b> that
                            happened to you today.
                        </h4>
                        <form
                            className={`border border-gray-500 bg-${Theme.offWhite} p-4 rounded-md max-w-2xl text-gray-800 mt-6 flex flex-row flex-wrap justify-start items-center`}
                            onSubmit={async e => {
                                e.preventDefault();
                                await setUserReminder();
                            }}
                        >
                            {currentUser?.reminders !== ReminderOptions.NONE &&
                            !isUpdatingReminder ? (
                                <>
                                    <h3 className="text-lg">
                                        We have a reminder set for you at{' '}
                                        <span className="font-semibold">
                                            {reminder?.timeStringValue} via{' '}
                                            {currentUser?.reminders === ReminderOptions.EMAIL
                                                ? String(currentUser?.reminders).toLocaleLowerCase()
                                                : String(
                                                      currentUser?.reminders
                                                  ).toLocaleUpperCase()}{' '}
                                        </span>
                                    </h3>{' '}
                                    <div className="flex flex-row justify-start items-center w-full">
                                        <button
                                            className="text-blue-500 hover:text-blue-700 block mt-3 mr-3"
                                            onClick={e => {
                                                e.preventDefault();
                                                setIsUpdatingReminder(true);
                                            }}
                                        >
                                            Update
                                        </button>
                                        <button
                                            className="text-blue-500 hover:text-blue-700 block mt-3"
                                            onClick={e => {
                                                e.preventDefault();
                                                openModal(ModalScenarios.DELETING_REMINDER);
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h3 className="text-md md:text-lg font-semibold">
                                        Send me a reminder at{' '}
                                    </h3>{' '}
                                    <input
                                        type="text"
                                        className={`ml-3 border border-${
                                            timeError ? 'red-500' : 'gray-200'
                                        } px-2 py-1 rounded-md md:w-1/6 ${
                                            !currentUser?.phoneVerified &&
                                            !currentUser?.emailVerified
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                        }`}
                                        disabled={
                                            !currentUser?.phoneVerified &&
                                            !currentUser?.emailVerified
                                        }
                                        value={reminder?.timeStringValue || ''}
                                        placeholder="i.e. 8:00pm"
                                        onChange={reminderTimeChanged}
                                        onBlur={reminderTimeOnBlur}
                                    />{' '}
                                    {timeError}
                                    {!timeError && (
                                        <span className="text-lg font-semibold ml-2">via</span>
                                    )}
                                    <div className="flex flex-col justify-start items-start px-4 md:w-full py-2">
                                        <label
                                            className={`flex flex-row items-center mb-2 ${
                                                currentUser?.emailVerified
                                                    ? 'cursor-pointer'
                                                    : 'cursor-not-allowed'
                                            } justify-center relative`}
                                            htmlFor={ReminderOptions.EMAIL}
                                            onClick={
                                                currentUser?.emailVerified
                                                    ? () => setReminderMethod(ReminderOptions.EMAIL)
                                                    : undefined
                                            }
                                        >
                                            <input
                                                type="radio"
                                                className={`form-radio ${
                                                    currentUser?.emailVerified
                                                        ? 'cursor-pointer'
                                                        : 'cursor-not-allowed'
                                                }`}
                                                disabled={!currentUser?.emailVerified}
                                                checked={reminder?.method === ReminderOptions.EMAIL}
                                                name={ReminderOptions.EMAIL}
                                                onChange={() =>
                                                    setReminderMethod(ReminderOptions.EMAIL)
                                                }
                                            />
                                            <span
                                                className={`mx-2 ${
                                                    !currentUser?.emailVerified ? 'opacity-50' : ''
                                                }`}
                                            >
                                                Email
                                            </span>
                                            <span className="text-sm break-all text-gray-900">
                                                {currentUser?.email}
                                            </span>
                                            {!currentUser?.emailVerified && (
                                                <IconManager
                                                    type={IconType.WARNING_ALERT}
                                                    size={18}
                                                    fill="#ecc94b"
                                                    className="ml-2"
                                                />
                                            )}
                                        </label>
                                        {!currentUser?.emailVerified && (
                                            <span className="text-sm ml-6 block text-yellow-600">
                                                You haven't verified your email yet.{' '}
                                                <button
                                                    className="underline text-blue-500 hover:text-blue-600"
                                                    type="button"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        openModal(
                                                            ModalScenarios.VERIFYING_EMAIL_CONFIRMATION
                                                        );
                                                    }}
                                                >
                                                    Verify your email
                                                </button>{' '}
                                            </span>
                                        )}
                                        <label
                                            className={`flex mt-3 flex-row items-center mb-2 ${
                                                currentUser?.phoneVerified
                                                    ? 'cursor-pointer'
                                                    : 'cursor-not-allowed'
                                            } justify-center relative`}
                                            htmlFor={ReminderOptions.SMS}
                                            onClick={
                                                currentUser?.phoneVerified
                                                    ? () => setReminderMethod(ReminderOptions.SMS)
                                                    : undefined
                                            }
                                        >
                                            <input
                                                type="radio"
                                                className={`form-radio ${
                                                    currentUser?.phoneVerified
                                                        ? 'cursor-pointer'
                                                        : 'cursor-not-allowed'
                                                }`}
                                                disabled={
                                                    !currentUser?.phone ||
                                                    !currentUser?.phoneVerified
                                                }
                                                checked={reminder?.method === ReminderOptions.SMS}
                                                name={ReminderOptions.SMS}
                                                onChange={() =>
                                                    setReminderMethod(ReminderOptions.SMS)
                                                }
                                            />
                                            <span
                                                className={`mx-2 ${
                                                    !currentUser?.emailVerified ? 'opacity-50' : ''
                                                }`}
                                            >
                                                SMS
                                            </span>
                                            {currentUser?.phone ? (
                                                <>
                                                    <span className="text-sm text-gray-900">
                                                        {formatPhoneNumber(currentUser?.phone)}
                                                    </span>
                                                    {!currentUser?.phoneVerified && (
                                                        <IconManager
                                                            type={IconType.WARNING_ALERT}
                                                            size={18}
                                                            fill="#ecc94b"
                                                            className="ml-2"
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <span className="text-sm text-gray-800">
                                                    There isn't a phone number associated with your
                                                    account.{' '}
                                                    <button
                                                        className="underline text-blue-500 hover:text-blue-600"
                                                        type="button"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            openModal(
                                                                ModalScenarios.ADDING_PHONE_NUMBER
                                                            );
                                                        }}
                                                    >
                                                        Add one
                                                    </button>
                                                </span>
                                            )}
                                        </label>
                                        {currentUser?.phone && !currentUser.phoneVerified && (
                                            <span className="text-sm ml-6 block text-yellow-600">
                                                We've sent you an SMS message to verify your phone
                                                number. After clicking the link it contains, refresh
                                                the page to continue.{' '}
                                                <span className="text-gray-600">
                                                    Never got a text?{' '}
                                                </span>
                                                <button
                                                    className="text-blue-500 hover:text-blue-600 font-semibold"
                                                    type="button"
                                                    onClick={requestAnotherSMSVerification}
                                                >
                                                    Resend verification
                                                </button>
                                            </span>
                                        )}
                                        <div className="p-2 mt-2 text-center mx-auto">
                                            <p className="text-sm text-gray-600">
                                                Not receiving your reminders?{' '}
                                                <Link
                                                    className="font-semibold text-blue-500 hover:text-blue-600"
                                                    to={AuthRoutes.BUG_REPORT}
                                                >
                                                    Let us know
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <SubmitButton
                                            loading={creatingOrUpdatingReminder.submitting}
                                            className="bg-blue-500 w-full md:w-2/3 mx-auto block hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                            disabled={!formValid}
                                        >
                                            {isUpdatingReminder
                                                ? 'UPDATE REMINDER'
                                                : 'CREATE REMINDER'}
                                        </SubmitButton>
                                        {isUpdatingReminder && (
                                            <button
                                                className="bg-transparent text-gray-600 mt-2 mx-auto"
                                                onClick={async e => {
                                                    e.preventDefault();
                                                    setIsUpdatingReminder(false);
                                                    await setUserReminderOptions();
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        )}
                                    </div>
                                </>
                            )}
                        </form>
                    </div>
                </>
            )}
            {modalOpen && (
                <Modal isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
                    <div className="w-full px-5 py-3">
                        {modalScenario[ModalScenarios.ADDING_PHONE_NUMBER] && (
                            <form onSubmit={addUserPhoneNumber}>
                                <label htmlFor="groupName" className="block">
                                    <span className="text-gray-700 text-center text-lg mb-4 block">
                                        Please enter the phone number you'd like us to send SMS
                                        reminders to. We'll send you a verification text to confirm
                                        it's you.
                                    </span>
                                    <span className="text-gray-500 text-sm block text-center mx-auto my-2">
                                        US & Canada only
                                    </span>
                                    <div className="flex flex-wrap items-stretch w-full mb-4 relative">
                                        <div className="flex -mr-px">
                                            <span className="flex items-center leading-normal bg-gray-200 rounded rounded-r-none border border-r-0 border-gray-300 px-3 whitespace-no-wrap text-grey-dark text-sm">
                                                +1
                                            </span>
                                        </div>
                                        <input
                                            type="tel"
                                            value={phoneNumber.value}
                                            onChange={e => {
                                                e.persist();
                                                setPhoneNumber(prev => ({
                                                    ...prev,
                                                    value: e.target.value,
                                                }));
                                            }}
                                            className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative focus:border-blue focus:shadow"
                                            onBlur={() =>
                                                setPhoneNumber(prev => ({
                                                    ...prev,
                                                    valid: validatePhoneNumber(prev.value),
                                                }))
                                            }
                                        />
                                    </div>
                                </label>
                                <SubmitButton
                                    loading={submitting}
                                    disabled={!phoneNumber.value}
                                    className="bg-green-500 hover:bg-green-700 mt-5 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                                >
                                    SUBMIT
                                </SubmitButton>
                            </form>
                        )}
                        {modalScenario[ModalScenarios.SUGGEST_REMINDER_TIME_OF_DAY] && (
                            <form
                                onSubmit={async e => {
                                    e.preventDefault();
                                    await setUserReminder();
                                }}
                            >
                                <h3 className="font-semibold text-center text-gray-700">
                                    Research shows that the best results come when your Three Good
                                    Things are recorded at the end of each day. Are you sure you
                                    want to set your reminder for{' '}
                                    <span className="text-blue-500">
                                        {reminder?.timeStringValue}?
                                    </span>
                                </h3>
                                <div className="mt-2 mx-auto flex flex-row justify-center items-center w-full p-2">
                                    <button
                                        className="bg-transparent font-bold text-gray-500 mr-2 px-2 py-1"
                                        onClick={e => {
                                            e.preventDefault();
                                            setModalOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white px-2 py-1"
                                        type="submit"
                                        onClick={() => setUserHasConfirmedEarlyReminder(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </form>
                        )}
                        {modalScenario[ModalScenarios.VERIFYING_EMAIL_CONFIRMATION] && (
                            <div className="relative">
                                <h3 className="font-semibold text-center text-gray-700">
                                    Are you sure you want us to send you an email with a link to
                                    verify you have access to this account?
                                </h3>
                                {verifyingEmail ? (
                                    <LoadingSpinner type="page" />
                                ) : (
                                    <div className="mt-2 mx-auto flex flex-row justify-center items-center w-full p-2">
                                        <button
                                            className="bg-transparent font-bold text-gray-500 mr-2 px-2 py-1"
                                            onClick={() => setModalOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white px-2 py-1"
                                            onClick={() =>
                                                requestVerification(VerificationOptions.EMAIL)
                                            }
                                        >
                                            Yes
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        {(modalScenario[ModalScenarios.EMAIL_VERIFICATION_SENT] ||
                            modalScenario[ModalScenarios.TEXT_MESSAGE_VERIFICATION_SENT]) && (
                            <div className="mb-2 mx-auto flex flex-col justify-center items-center w-full p-2">
                                <SuccessAlert
                                    message={`Success! We've sent you an ${
                                        modalScenario[ModalScenarios.EMAIL_VERIFICATION_SENT]
                                            ? 'email with a link to verify your email address'
                                            : 'SMS message with a link to verify your phone number'
                                    } `}
                                />
                                <button
                                    className="bg-transparent text-blue-500 hover:text-blue-700"
                                    onClick={() => setModalOpen(false)}
                                >
                                    Close
                                </button>
                            </div>
                        )}
                        {(modalScenario[ModalScenarios.EMAIL_VERIFICATION_ERROR] ||
                            modalScenario[ModalScenarios.TEXT_MESSAGE_VERIFICATION_ERROR]) && (
                            <>
                                <h3 className="font-semibold text-center text-red-500">
                                    An error occurred while attempting to verify your{' '}
                                    {modalScenario[ModalScenarios.EMAIL_VERIFICATION_ERROR]
                                        ? 'email'
                                        : 'phone number'}
                                    . Please check your internet connection and try again.
                                </h3>
                                <div className="mt-2 mx-auto flex flex-row justify-center items-center w-full p-2">
                                    <button
                                        className="bg-transparent text-blue-500 hover:text-blue-700"
                                        onClick={() => setModalOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </>
                        )}
                        {modalScenario[ModalScenarios.DELETING_REMINDER] && (
                            <>
                                <h3 className="font-semibold text-center text-gray-700">
                                    Are you sure you want to delete your daily reminder?
                                </h3>
                                <div className="mt-2 mx-auto flex flex-row justify-center items-center w-full p-2">
                                    <button
                                        className="bg-transparent font-bold text-gray-500 mr-2 px-2 py-1"
                                        onClick={() => setModalOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white px-2 py-1"
                                        onClick={deleteReminder}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </Modal>
            )}
            {reminderAlertVisible && (
                <Alert
                    message={`We've set up your reminder for ${reminder?.timeStringValue}`}
                    close={() => setReminderAlertVisible(false)}
                    type="success"
                    className="mx-auto flex flex-row flex-wrap"
                />
            )}
            {smsVerificationResentAlertVisible && (
                <Alert
                    message={`We've sent you another SMS verification`}
                    close={() => setSMSVerificationResentAlertVisible(false)}
                    type="success"
                    className="w-11/12 md:w-1/3 mx-auto flex flex-row flex-wrap"
                />
            )}
        </div>
    );
};

export default Reminders;
