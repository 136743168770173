import { BugReportRequest, PublicUser, UpdateUserRequest, User } from '../types/userTypes';
import TokenManager from './TokenManager';
import FetchWrapper from './FetchWrapper';
import { GroupInvitationType } from '../types/invitationTypes';

//allows user to update THEMSELVES
export const updateUser = async (request: UpdateUserRequest): Promise<User> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');
    const propertiesToUpdate: UpdateUserRequest = {};
    for (const key in request) {
        if (request[key as keyof UpdateUserRequest] !== null || request[key as keyof UpdateUserRequest] !== undefined) {
            propertiesToUpdate[key] = request[key];
        }
    }
    const res = await FetchWrapper({
        path: 'user',
        method: 'PATCH',
        token,
        body: propertiesToUpdate,
    });

    return await res.json();
};

export const getUser = async (userId: string): Promise<PublicUser> => {
    //this endpoint can be both auth and non-auth, so we don't throw an error if there isn't a token
    const token: string | null = await TokenManager.getAccessToken();

    const res = await FetchWrapper({
        path: `user/${userId}`,
        token,
    });

    return await res.json();
};

export const getCurrentUser = async (): Promise<User> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: 'user/current',
        token,
    });

    return await res.json();
};

export const leaveGroup = async (groupIdToLeave: string): Promise<void> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    await FetchWrapper({
        path: `group/${groupIdToLeave}/leave`,
        method: 'POST',
        token,
    });
};

export const getInvitations = async (): Promise<GroupInvitationType[]> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: `user/invitations`,
        token,
    });
    const invitations = (await res.json()) as GroupInvitationType[];
    //populate user that sent invite
    return await Promise.all(invitations.map(async invitation => ({ ...invitation, user: await getUser(invitation.userId) })));
};

export const createBugReport = async (request: BugReportRequest): Promise<void> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    await FetchWrapper({
        path: 'bugreport',
        method: 'POST',
        token,
        body: request,
    });
};

export const getPresignedUrl = async (request: { fileName: string; fileType: string }): Promise<string> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: 'presignedurl',
        method: 'POST',
        token,
        body: request,
    });

    const { presignedUrl } = await res.json();
    return presignedUrl;
};

export const getProfileImageUploadUrl = async (): Promise<string> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: 'user/photo',
        token,
    });
    return await res.json();
};

export const deleteProfileImage = async (): Promise<void> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    await FetchWrapper({
        path: 'user/photo',
        method: 'DELETE',
        token,
    });
};

export const unsubscribeEmail = async (email: string): Promise<void> => {
    await FetchWrapper({
        path: 'user/email/unsubscribe',
        method: 'POST',
        body: { email },
    });
};
