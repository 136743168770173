import { API_URL } from './env';
const slashifyUrlStart = (url: string): string => (url[0] === '/' ? url : `/${url}`);
const prependApiUrl = (endpoint: string) => `${API_URL}${slashifyUrlStart(endpoint)}`;
type headers = Object;
type cache = 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | 'only-if-cached';
type method = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';
type mode = 'same-origin' | 'no-cors' | 'cors' | 'navigate';
type redirect = 'follow' | 'manual' | 'error';
type referrer = 'client' | 'no-referrer';

interface GenericRequest {
    path: string;
    params?: Object;
    body?: Object;
    token?: string | null | undefined;
    headers?: headers;
    mode?: mode;
    method?: method;
    cache?: cache;
    redirect?: redirect;
    referrer?: referrer;
}

interface FetchConfig {
    headers: any;
    cache: cache;
    method: method;
    mode: mode;
    redirect: redirect;
    referrer: referrer;
    body?: string; //expects it to be result of JSON.stringify()
}

export default async function FetchWrapper(request: GenericRequest) {
    const {
        path,
        body,
        method = 'GET',
        headers: suppliedHeaders = {},
        cache = 'default',
        mode = 'cors',
        token = null,
        redirect = 'follow',
        referrer = 'client',
    } = request;

    if (!path) {
        throw new Error(`'path' property not specified for fetch call`);
    }
    const url: string = prependApiUrl(path);

    const fetchConfig: Partial<FetchConfig> = {
        headers: buildRequestHeaders(suppliedHeaders, token),
        cache,
        method,
        mode,
        redirect,
        referrer,
    };

    if (method !== 'GET') {
        fetchConfig.body = JSON.stringify(body);
    }

    const response = await fetch(url, fetchConfig);

    if (response.ok) {
        return response;
    }
    throw response;
}

function buildRequestHeaders(additionalHeaders: any, token: string | null): any {
    const authHeaders = token ? { Authorization: token } : {};
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...authHeaders,
        ...additionalHeaders,
    };
}
