import React, { ReactNode } from 'react';
import { RFC } from '../types/generalTypes';
import CopyRightFooter from './CopyRightFooter';

const PublicPageContainer: RFC<{ children: ReactNode }> = ({ children }) => (
    <div className="mx-auto w-full min-h-screen flex flex-col splash-background">
        <div className="w-full flex-grow py-4 px-5 mx-auto">{children}</div>
        <CopyRightFooter />
    </div>
);

export default PublicPageContainer;
