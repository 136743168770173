import React, { useRef, useState } from 'react';
import { RFC } from '../types/generalTypes';
import { Entry } from '../types/entryTypes';
import UserAvatar from './UserAvatar';
import { copyToClipboard, renderFullEntryDateFromMongoId, renderRelativeEntryDateFromMongoId } from '../utils';
import { Link, navigate } from '@reach/router';
import { AuthRoutes, NonAuthRoutes } from '../routes';
import useOutsideClick from '../hooks/useOutsideClick';
import { Theme } from '../theme';
import IconManager, { IconType } from './IconManager';

interface Props {
    entry: Entry;
    isAdmin?: boolean;
    toggleEntry?: () => void;
    preventLinking?: boolean;
}

const PublicEntry: RFC<Props> = ({ entry, preventLinking, isAdmin, toggleEntry }) => {
    const [entryActionsVisible, setEntryActionsVisible] = useState<boolean>(false);
    const actionButtonRef = useRef<HTMLButtonElement>(null);
    useOutsideClick({ ref: actionButtonRef, callback: () => setEntryActionsVisible(false) });

    return (
        <div
            className={`p-5 rounded-md border border-gray-300 bg-gray-200 md:border-gray-200 md:bg-gray-100 ${
                !preventLinking ? 'md:cursor-pointer md:hover:bg-gray-200' : ''
            } shadow mb-5`}
            onClick={
                preventLinking
                    ? undefined
                    : async () => {
                          await navigate(NonAuthRoutes.ENTRIES + `/${entry._id}`);
                      }
            }
        >
            <div className="flex flex-row justify-start items-center px-3 relative">
                <Link onClick={e => e.stopPropagation()} to={AuthRoutes.MEMBER_DETAILS + `/${entry.author._id}`}>
                    <UserAvatar displayName={entry.author.displayName} backgroundColor={entry.author.backgroundColor} profileImageUrl={entry.author.profileImage} />
                </Link>
                <div className="ml-3 text-lg text-left">
                    <Link
                        onClick={e => e.stopPropagation()}
                        to={AuthRoutes.MEMBER_DETAILS + `/${entry.author._id}`}
                        className="font-bold block text-blue-600 hover:text-blue-800"
                    >
                        {entry.author.displayName}
                    </Link>
                    <span className="text-gray-600 text-sm" title={renderFullEntryDateFromMongoId(entry._id)}>
                        {renderRelativeEntryDateFromMongoId(entry._id)}
                    </span>
                </div>
                <div className="ml-auto">
                    {isAdmin ? (
                        <button
                            onClick={e => {
                                e.stopPropagation();
                                toggleEntry?.();
                            }}
                            className={`text-sm px-2 py-1 bg-${entry.approved ? 'red' : 'green'}-500 hover:bg-${
                                entry.approved ? 'red' : 'green'
                            }-700 text-white rounded-md border-none font-semibold`}
                        >
                            {entry.approved ? 'Disapprove' : 'Approve'}
                        </button>
                    ) : (
                        <button
                            ref={actionButtonRef}
                            className="cursor-pointer"
                            onClick={e => {
                                e.stopPropagation();
                                setEntryActionsVisible(!entryActionsVisible);
                            }}
                        >
                            <IconManager type={IconType.ENTRY_ACTION_BUTTON} stroke="#4A5568" />
                        </button>
                    )}
                </div>

                <div
                    className={`${
                        entryActionsVisible ? 'block' : 'hidden'
                    } absolute flex flex-col justify-start items-start border shadow-md w-3/5 md:w-1/5 p-3 rounded-md bg-${Theme.offWhite} right-0`}
                    style={{ top: '50px' }}
                >
                    <button
                        className="flex my-2 text-sm text-gray-600 flex-row items-center"
                        onClick={e => {
                            e.stopPropagation();
                            copyToClipboard(`${window.location.origin}/public/entries/${entry._id}`);
                        }}
                    >
                        <IconManager type={IconType.SHARE_LINK} stroke="#788CA2" className="mr-2" />
                        Copy link
                    </button>
                </div>
            </div>
            <ul className="py-3 px-5 list-disc text-left flex flex-col flex-wrap">
                {entry.items.map((goodThing, i) => (
                    <li className="block mt-3 py-2 w-full" key={i}>
                        <p className="text-lg block" style={{ wordBreak: 'break-word' }}>
                            {goodThing}
                        </p>
                    </li>
                ))}
            </ul>
            {/*only show the loved by count to the user if there's at least one*/}
            <div className="flex flex-row items-center justify-end">
                {entry.lovedByCount > 0 && (
                    <span className="mr-2 cursor-default px-2 py-1 flex flex-row items-center">
                        {entry.lovedByCount > 0 && <span className="text-xs text-gray-600 mr-1">{entry.lovedByCount}</span>}
                        <IconManager type={IconType.HEART} stroke="#f56565" fill="#f56565" size={26} />
                    </span>
                )}
            </div>
        </div>
    );
};

export default PublicEntry;
