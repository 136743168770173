import React, { useContext, useEffect, useState } from 'react';
import { RFC } from '../types/generalTypes';
import useURLParams from '../hooks/useURLParams';
import SplashPageContainer from '../components/SplashPageContainer';
import SuccessAlert from '../components/SuccessAlert';
import { Link, navigate } from '@reach/router';
import { AuthRoutes, NonAuthRoutes } from '../routes';
import { UserContext } from '../context/UserContextProvider';
import { verificationConfirm } from '../api/auth';
import { ReminderOptions } from '../types/userTypes';
import LoadingSpinner from '../components/LoadingSpinner';
import { NavStateKeys, URLParams } from '../types/networkTypes';

const VerificationSuccess: RFC = () => {
    const [verificationCode] = useURLParams(URLParams.VERIFICATION_CODE);
    const { currentUser } = useContext(UserContext);
    const [verifiedAttribute, setVerifiedAttribute] = useState<ReminderOptions>();
    const [pageLoading, setPageLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            try {
                if (verificationCode) {
                    setVerifiedAttribute(await verificationConfirm(verificationCode));
                } else {
                    setPageLoading(false);
                }
            } catch (error) {
                console.log(error);
                setPageLoading(false);
            }
            setPageLoading(false);
        })();
    }, [verificationCode]);

    useEffect(() => {
        if (currentUser && verifiedAttribute) {
            setPageLoading(false);
        }
    }, [currentUser, verifiedAttribute]);

    return (
        <SplashPageContainer>
            {pageLoading ? (
                <LoadingSpinner type="page" />
            ) : (
                <div className="max-w-3xl bg-gray-100 px-4 rounded-md shadow-md py-6">
                    {!verifiedAttribute ? (
                        <div className="p-5 text-center">
                            <h4 className="text-lg text-gray-600 font-semibold text-center">
                                Hmm, something's not right.
                            </h4>
                            <Link
                                to={NonAuthRoutes.LOGIN}
                                className="text-blue-500 hover:text-blue-700 mt-3 block"
                            >
                                Return to login
                            </Link>
                        </div>
                    ) : (
                        <>
                            <SuccessAlert
                                message={`Success! We've successfully verified your ${String(
                                    verifiedAttribute
                                ).toLocaleLowerCase()}`}
                            />
                            <div className="mt-3 p-2 w-full text-center">
                                {currentUser ? (
                                    <p className="text-gray-600 text-lg">
                                        <span
                                            onClick={async () => {
                                                await navigate(AuthRoutes.REMINDERS, {
                                                    state: { [NavStateKeys.EMAIL_VERIFIED]: true },
                                                });
                                            }}
                                            className="cursor-pointer text-blue-500 hover:text-blue-700 font-semibold"
                                        >
                                            Set a reminder
                                        </span>{' '}
                                        or refresh the web page where you requested the verification
                                    </p>
                                ) : (
                                    <p className="text-gray-600 text-lg">
                                        <Link
                                            to={NonAuthRoutes.LOGIN}
                                            className="text-blue-500 hover:text-blue-700 font-semibold"
                                        >
                                            Sign in
                                        </Link>{' '}
                                        to create a reminder, or refresh the web page where you
                                        requested the verification
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </SplashPageContainer>
    );
};

export default VerificationSuccess;
