import React from 'react';
import { RFC } from '../types/generalTypes';
import LoadingSpinner from './LoadingSpinner';
import LoadMoreButton from './LoadMoreButton';

interface Props {
    initialPageFetching: boolean;
    loadingMoreEntries: boolean;
    loadMoreEntries: () => void;
    totalPages?: number;
    activePage: number;
}

const MoreEntriesLoader: RFC<Props> = ({
    loadMoreEntries,
    initialPageFetching,
    loadingMoreEntries,
    activePage,
    totalPages,
}) => (
    <div className="w-full text-center">
        {!initialPageFetching && loadingMoreEntries ? (
            <LoadingSpinner type="page" />
        ) : (
            !initialPageFetching &&
            totalPages &&
            activePage < totalPages && <LoadMoreButton onClick={loadMoreEntries} />
        )}
    </div>
);

export default MoreEntriesLoader;
