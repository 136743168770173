import React from 'react';
import { ImageFilePreview, RFC } from '../types/generalTypes';
import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner';
import ImageUploader from './ImageUploader';

interface Props {
    uploadedGroupImageUrl?: string;
    groupName?: string;
    loading: boolean;
    setUploadedCoverPhoto: (imageFile: ImageFilePreview) => void;
    isAddingImageWithoutPrevImage?: boolean;
    isAddingImageWithPrevImage?: boolean;
    cancelChangingImage?: () => void;
    handleUpload: () => void;
    clearBannerImage: () => void;
    deleteBannerImage: () => void;
}

const GroupImageBanner: RFC<Props> = ({
    uploadedGroupImageUrl,
    loading,
    setUploadedCoverPhoto,
    isAddingImageWithoutPrevImage,
    isAddingImageWithPrevImage,
    cancelChangingImage,
    handleUpload,
    clearBannerImage,
    groupName,
    deleteBannerImage,
}) => {
    if (loading) {
        return <LoadingSpinner type="page" />;
    } else if (uploadedGroupImageUrl) {
        return (
            <>
                <Container url={uploadedGroupImageUrl}>
                    <h3 className="block absolute bottom-0 left-0 text-3xl font-bold ml-3 mb-3 text-white">
                        {groupName}
                    </h3>
                </Container>
                {(isAddingImageWithoutPrevImage || isAddingImageWithPrevImage) &&
                    uploadedGroupImageUrl && (
                        <div className="w-full flex flex-row items-center justify-end p-2 mt-2">
                            {isAddingImageWithPrevImage ? (
                                <button
                                    className="text-gray-600 block hover:text-gray-700 mr-2"
                                    onClick={cancelChangingImage}
                                >
                                    Cancel
                                </button>
                            ) : (
                                <button
                                    className="text-gray-600 block hover:text-gray-700 mr-2"
                                    onClick={clearBannerImage}
                                >
                                    Choose another image
                                </button>
                            )}
                            <button
                                onClick={handleUpload}
                                className="text-white bg-blue-500 hover:bg-blue-600 font-semibold px-2 py-1 rounded-md border-none"
                            >
                                Save
                            </button>
                        </div>
                    )}
            </>
        );
    } else {
        return (
            <>
                <ImageUploader
                    setUploadedImageFile={setUploadedCoverPhoto}
                    requiredDimensions={{ width: 500, height: 300 }}
                />
                <p className="text-sm mt-2 text-gray-600">
                    Please ensure banner image is at least 500x300 pixels
                </p>
                {isAddingImageWithPrevImage && (
                    <div className="w-full flex flex-row items-center justify-end p-2 mt-2">
                        <button
                            onClick={deleteBannerImage}
                            className="text-white text-sm bg-red-500 hover:bg-red-600 font-semibold px-2 py-1 rounded-md border-none"
                        >
                            Delete previous banner
                        </button>
                    </div>
                )}
            </>
        );
    }
};

export default GroupImageBanner;

const Container = styled.div<{ url?: string }>`
    width: 100%;
    object-fit: contain;
    object-position: center;
    background: linear-gradient(rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.2)),
        url(${({ url }) => url}) no-repeat center;
    padding-top: 30%;
    position: relative;
    background-size: cover;
    border-radius: 5px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.9);
`;
