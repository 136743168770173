import React from 'react';
import { render } from 'react-dom';
import './styles/index.css';
import App from './App';
import UserContextProvider from './context/UserContextProvider';
import { Analytics } from './util/Analytics';

Analytics.initialize('UA-350639-26');

render(
    <UserContextProvider>
        <App />
    </UserContextProvider>,
    document.getElementById('app')
);
