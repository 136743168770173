import React, { useContext, useEffect, useState } from 'react';
import { Error, FetchRequest } from '../types/networkTypes';
import { Entry as EntryType } from '../types/entryTypes';
import { getPublicEntry } from '../api/entry';
import { RFC } from '../types/generalTypes';
import { Link, RouteComponentProps } from '@reach/router';
import PublicEntry from '../components/PublicEntry';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';
import { UserContext } from '../context/UserContextProvider';
import { NonAuthRoutes } from '../routes';
import Entry from '../components/Entry';
// @ts-ignore
import Logo from '../img/3GT-logo.png';

const SinglePublicEntry: RFC<{ id?: string } & RouteComponentProps> = ({ id }) => {
    const [entry, setEntry] = useState<FetchRequest<EntryType | undefined, Error>>({
        fetching: true,
        data: undefined,
        error: null,
    });
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    const data = await getPublicEntry(id);
                    setEntry(prev => ({ ...prev, data }));
                } catch (error) {
                    error = error.json ? await error.json() : error;
                    console.log(error);
                    setEntry(prev => ({ ...prev, error }));
                }
                setEntry(prev => ({ ...prev, fetching: false }));
            }
        })();
    }, [id]);

    const PageContent: RFC = () => {
        if (entry.error) {
            return <ErrorMessage code={entry.error?.code} />;
        } else if (entry.data) {
            return (
                <>
                    <div className="max-w-3xl md:px-5 mx-auto">
                        {currentUser === null && (
                            <div className="mx-auto text-center mb-5">
                                <img
                                    src={Logo}
                                    alt="The 3 good things logo"
                                    className="mx-auto md:mb-4 h-24 md:h-32"
                                />
                            </div>
                        )}
                        {currentUser ? (
                            <Entry
                                entry={entry.data}
                                updateEntryInLocalState={updatedEntry =>
                                    setEntry(prev => ({
                                        ...prev,
                                        data:
                                            prev.data?._id === updatedEntry._id
                                                ? updatedEntry
                                                : prev.data,
                                    }))
                                }
                                shouldLinkToMemberProfile={true}
                                preventLinking={true}
                            />
                        ) : (
                            <PublicEntry entry={entry.data} preventLinking={true} />
                        )}
                        <div className="w-full mt-5 text-center">
                            {currentUser === null && (
                                <>
                                    <p className="text-sm md:text-lg text-gray-600">
                                        Have an account?{' '}
                                        <Link
                                            to={NonAuthRoutes.LOGIN}
                                            className="text-blue-500 hover:text-blue-700 font-semibold"
                                        >
                                            Login
                                        </Link>
                                    </p>
                                    <p className="text-sm md:text-lg block text-gray-600 mt-2">
                                        Otherwise{' '}
                                        <Link
                                            className="text-blue-500 hover:text-blue-700 font-semibold"
                                            to={NonAuthRoutes.REGISTER}
                                        >
                                            create an account
                                        </Link>{' '}
                                        to get started with focusing on your three good things.
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </>
            );
        }
        return <LoadingSpinner type="page" />;
    };

    return (
        <div className="p-5">
            <PageContent />
        </div>
    );
};

export default SinglePublicEntry;
