import React, { useEffect, useState } from 'react';
import { RFC } from '../types/generalTypes';
import SplashPageContainer from '../components/SplashPageContainer';
import useURLParams from '../hooks/useURLParams';
import { URLParams } from '../types/networkTypes';
import { unsubscribeEmail } from '../api/user';
import LoadingSpinner from '../components/LoadingSpinner';

const EmailUnsubscribe: RFC = () => {
    const [email] = useURLParams(URLParams.EMAIL);
    const [pageLoading, setPageLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            try {
                if (email) {
                    await unsubscribeEmail(email);
                }
            } catch (error) {
                console.log(error);
            }
            setPageLoading(false);
        })();
    }, [email]);
    return (
        <SplashPageContainer>
            {pageLoading ? (
                <LoadingSpinner type="page" />
            ) : (
                <div className="max-w-3xl bg-gray-100 px-4 rounded-md shadow-md py-6">
                    <h3 className="text-blue-900 text-xl font-semibold text-center mb-2">
                        Sorry to see you go!
                    </h3>
                    <p className="text-gray-600">
                        We've removed your daily reminder to post your Three Good Things.
                    </p>
                </div>
            )}
        </SplashPageContainer>
    );
};

export default EmailUnsubscribe;
