import React, { useContext, useEffect, useState } from 'react';
import { RFC } from '../types/generalTypes';
import { Error, FetchRequest } from '../types/networkTypes';
import { Entry } from '../types/entryTypes';
import { getAllPublicEntries, toggleEntryApproval } from '../api/entry';
import PublicEntry from '../components/PublicEntry';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';
import { UserContext } from '../context/UserContextProvider';
import PageHeader from '../components/PageHeader';
import MoreEntriesLoader from '../components/MoreEntriesLoader';
import isAuthed from '../hooks/isAuthed';

const ApprovalQueue: RFC = () => {
    isAuthed();
    const [entries, setEntries] = useState<FetchRequest<Entry[], Error>>({
        fetching: true,
        data: [],
        error: null,
    });
    const [page, setPage] = useState<{ current: number; totalPages?: number }>({ current: 1 });
    const [loadingMoreEntries, setLoadingMoreEntries] = useState<boolean>(false);
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            if (currentUser && currentUser.isAdmin) {
                try {
                    const { entries, total } = await getAllPublicEntries({ page: page.current });
                    setEntries(prev => ({ ...prev, data: entries }));
                    setPage(prev => ({ ...prev, totalPages: total, foo: 'bar' }));
                } catch (error) {
                    error = error.json ? await error.json() : error;
                    console.log(error);
                    setEntries(prev => ({ ...prev, error }));
                }
                setEntries(prev => ({ ...prev, fetching: false }));
            } else if (currentUser) {
                window.history.back();
            }
        })();
    }, [currentUser]);

    const toggleEntry = async (entryId: string) => {
        try {
            const updatedEntry = await toggleEntryApproval(entryId);
            setEntries(prev => ({
                ...prev,
                data: prev.data.map(entry =>
                    entry._id === updatedEntry._id ? updatedEntry : entry
                ),
            }));
        } catch (error) {
            console.log(error);
            setEntries(prev => ({ ...prev, error }));
        }
    };
    const loadMoreEntries = () => {
        setLoadingMoreEntries(true);
        setPage(prev => ({ ...prev, current: prev.current + 1 }));
        setTimeout(() => {
            setLoadingMoreEntries(false);
        }, 1000);
    };

    return (
        <div className="px-5">
            <div className="w-full md:max-w-3xl mx-auto md:p-5 mt-5">
                <PageHeader title="Approval Queue" />
                {entries.error && <ErrorMessage className="mb-2" code={entries.error.code} />}
                {entries.fetching ? (
                    <LoadingSpinner type="page" />
                ) : (
                    entries.data.map(entry => (
                        <PublicEntry
                            key={entry._id}
                            entry={entry}
                            isAdmin={true}
                            toggleEntry={async () => await toggleEntry(entry._id)}
                        />
                    ))
                )}
                <MoreEntriesLoader
                    initialPageFetching={entries.fetching}
                    loadingMoreEntries={loadingMoreEntries}
                    loadMoreEntries={loadMoreEntries}
                    activePage={page.current}
                    totalPages={page.totalPages}
                />
            </div>
        </div>
    );
};

export default ApprovalQueue;
