import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';

interface Props extends RouteComponentProps {
    to: string;
    className?: string;
}

const NavLink: React.FC<Props> = (props): JSX.Element => (
    <Link
        {...props}
        getProps={({ isCurrent }) => {
            return {
                style: {
                    fontWeight: isCurrent ? 700 : 400,
                },
            };
        }}
    />
);

export default NavLink;
