import React, { useContext, useEffect, useState } from 'react';
import SplashPageContainer from '../components/SplashPageContainer';
import { validateEmail } from '../utils';
import { FormSubmission, InputChange, RFC } from '../types/generalTypes';
import { Error, NavStateKeys, URLParams } from '../types/networkTypes';
import SubmitButton from '../components/SubmitButton';
import { AuthRoutes, NonAuthRoutes } from '../routes';
import ErrorMessage from '../components/ErrorMessage';
// @ts-ignore
import Logo from '../img/3GT-logo.png';
import { UserContext } from '../context/UserContextProvider';
import { loginUser } from '../api/auth';
import { Link, navigate, RouteComponentProps } from '@reach/router';
import { getGroupNameByInvitation, redeemInvitation } from '../api/group';
import useURLParams from '../hooks/useURLParams';
import { minPasswordLength } from '../globals';
import { Environment } from '../api/env';
import isInternal = Environment.isInternal;
import { Analytics } from '../util/Analytics';

enum StateKeys {
    email = 'email',
    password = 'password',
}

const initialFormState = {
    [StateKeys.email]: isInternal() ? 'internal+1@crosscomm.com' : '',
    [StateKeys.password]: isInternal() ? 'SpaceJam2019' : '',
};
type Credential = { missing: boolean | null; valid: boolean | null };
const initialCredentialState: Credential = { missing: null, valid: null };

const Login: RFC<{ isAdmin?: boolean } & RouteComponentProps> = ({ isAdmin }) => {
    const [form, setForm] = useState<{ email: string; password: string }>(initialFormState);
    const [emailStatus, setEmailStatus] = useState<Credential>(initialCredentialState);
    const [passwordStatus, setPasswordStatus] = useState<Credential>(initialCredentialState);
    const [formValid, setFormValid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [invitationId] = useURLParams([URLParams.INVITE_ID]);
    const [groupNameUserWasInvitedTo, setGroupNameUserWasInvitedTo] = useState<string>();

    const handleInput = (e: InputChange) => {
        e.persist();
        error && setError(null);
        setEmailStatus(initialCredentialState);
        setPasswordStatus(initialCredentialState);
        setForm(prev => ({ ...prev, [e.target.name as StateKeys]: e.target.value }));
    };

    const handleLogin = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        error && setError(null);
        setLoading(true);
        Analytics.Event.UserLoggedIn.track();
        try {
            const user = await loginUser({
                email: form.email.trim(),
                password: form.password.trim(),
            });
            setCurrentUser(user);
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setError(error);
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        setFormValid(
            !!form.email &&
                validateEmail(form.email) &&
                !!form.password &&
                form.password.length >= minPasswordLength
        );
    }, [form.email, form.password]);

    useEffect(() => {
        (async () => {
            try {
                if (invitationId) {
                    setGroupNameUserWasInvitedTo(await getGroupNameByInvitation(invitationId));
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [invitationId]);

    useEffect(() => {
        (async () => {
            if (currentUser) {
                let groupIdUserWasInvitedTo: string | undefined = undefined;
                if (invitationId) {
                    groupIdUserWasInvitedTo = await redeemInvitation(invitationId);
                }
                groupIdUserWasInvitedTo
                    ? await navigate(AuthRoutes.GROUP_LIST + `/${groupIdUserWasInvitedTo}`, {
                          state: { [NavStateKeys.INVITE_ACCEPTED]: true },
                      })
                    : await navigate(AuthRoutes.GROUP_LIST);
            }
        })();
    }, [currentUser]);

    return (
        <SplashPageContainer>
            <div className="mx-auto text-center">
                <img
                    src={Logo}
                    alt="The 3 good things logo"
                    className="mx-auto md:mb-4 h-24 md:h-auto"
                />
            </div>
            {isAdmin && (
                <div className="flex flex-row justify-center items-center">
                    <h3 className="text-xl font-semibold text-blue-600">Admin Dashboard</h3>
                </div>
            )}
            {groupNameUserWasInvitedTo && (
                <div className="w-11/12 mt-2 md:mt-0 mx-auto border border-blue-500 rounded-md shadow p-4 text-center bg-blue-100">
                    <h4 className="text-blue-700">
                        It looks like you've been invited to{' '}
                        <span className="font-semibold">{groupNameUserWasInvitedTo}</span>. If you
                        have an account, login to join the group, otherwise{' '}
                        <Link
                            to={
                                invitationId
                                    ? `${NonAuthRoutes.REGISTER}?id=${invitationId}`
                                    : NonAuthRoutes.REGISTER
                            }
                            className="font-semibold underline"
                        >
                            create an account
                        </Link>{' '}
                        to get started
                    </h4>
                </div>
            )}
            <form className="px-8 pt-6 pb-8 mb-4" onSubmit={handleLogin}>
                <div className="mb-4">
                    <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor={StateKeys.email}
                    >
                        Email
                    </label>
                    <input
                        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline ${
                            emailStatus.missing ||
                            (emailStatus.missing === false && !validateEmail(form.email))
                                ? 'border-red-500'
                                : 'input-border'
                        }`}
                        type="email"
                        name={StateKeys.email}
                        value={form.email}
                        onChange={handleInput}
                        onBlur={() => {
                            if (!form.email) {
                                return setEmailStatus({ missing: true, valid: null });
                            } else if (form.email && !validateEmail(form.email)) {
                                setEmailStatus({ missing: null, valid: false });
                            } else {
                                setEmailStatus(initialCredentialState);
                            }
                        }}
                    />
                    {emailStatus.missing && (
                        <p className="text-red-500 text-xs italic">Please enter your email.</p>
                    )}
                    {emailStatus.missing === null && emailStatus.valid === false && (
                        <p className="text-red-500 text-xs italic">
                            Please enter a valid email address.
                        </p>
                    )}
                </div>
                <div className="mb-6">
                    <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor={StateKeys.password}
                    >
                        Password
                    </label>
                    <input
                        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline ${
                            passwordStatus.missing ||
                            (!passwordStatus.missing && passwordStatus.valid === false)
                                ? 'border-red-500'
                                : 'input-border'
                        }`}
                        type="password"
                        name={StateKeys.password}
                        value={form.password}
                        onChange={handleInput}
                        onBlur={() => {
                            if (!form.password) {
                                setPasswordStatus({ missing: true, valid: null });
                            } else if (form.password && form.password.length < minPasswordLength) {
                                setPasswordStatus({ missing: null, valid: false });
                            } else {
                                setPasswordStatus(initialCredentialState);
                            }
                        }}
                    />
                    {passwordStatus.missing && (
                        <p className="text-red-500 text-xs italic">Please enter a password.</p>
                    )}
                    {passwordStatus.missing === null && passwordStatus.valid === false && (
                        <p className="text-red-500 text-xs italic">
                            Your password must be at least {minPasswordLength} characters
                        </p>
                    )}
                </div>
                <div className="flex items-center justify-between">
                    <SubmitButton
                        loading={loading}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        disabled={!formValid}
                    >
                        LOGIN
                    </SubmitButton>
                    <Link
                        className="inline-block align-baseline font-bold text-sm text-blue-700 hover:text-blue-800 ml-auto pt-5"
                        to={NonAuthRoutes.FORGOT_PASSWORD}
                    >
                        Forgot Password?
                    </Link>
                </div>
                {error && <ErrorMessage code={error?.code} />}
            </form>
            <div className="mx-auto my-4 text-center text-blue-800">
                Don't have an account?{' '}
                <Link
                    to={
                        invitationId
                            ? `${NonAuthRoutes.REGISTER}?id=${invitationId}`
                            : NonAuthRoutes.REGISTER
                    }
                    className="font-bold text-blue-700 hover:text-blue-800"
                >
                    Sign up
                </Link>
            </div>
        </SplashPageContainer>
    );
};

export default Login;
