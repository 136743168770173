import React, { useCallback, useState } from 'react';
import { ImageFilePreview, RFC } from '../types/generalTypes';
import { useDropzone } from 'react-dropzone';
import IconManager, { IconType } from './IconManager';

interface Props {
    setUploadedImageFile: (file: ImageFilePreview) => void;
    requiredDimensions?: { height: number; width: number };
}

const ImageUploader: RFC<Props> = ({ setUploadedImageFile, requiredDimensions }) => {
    const [imageTooLarge, setImageTooLarge] = useState<boolean>(false);

    const onDrop = useCallback(async ([uploadedImage]: File[]) => {
        const blob = uploadedImage.slice(0, uploadedImage.size, uploadedImage.type);
        const modifiedImageFile = new File([blob], `${Date.now()}_${uploadedImage.name}`, { type: uploadedImage.type });
        const imageUrl = URL.createObjectURL(modifiedImageFile);
        const imageToProcess = new Image();
        imageToProcess.addEventListener('load', () => {
            if (requiredDimensions && (imageToProcess.width < requiredDimensions.width || imageToProcess.height < requiredDimensions.height)) {
                setImageTooLarge(true);
            } else {
                setUploadedImageFile(Object.assign(modifiedImageFile, { preview: imageUrl }));
            }
        });
        imageToProcess.src = imageUrl;
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/png', multiple: false });

    return (
        <div
            {...getRootProps()}
            className="px-3 py-4 border-2 border-dashed cursor-pointer border-gray-400 rounded-md text-center text-gray-700 text-md bg-gray-100"
        >
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <div className="p-2 flex flex-col justify-center items-center">
                    <p className="mb-3 block">
                        Drag & drop some image files here, or <span className="text-blue-500 hover:text-blue-700 font-semibold">click</span> to a
                        select an image file
                    </p>
                    <IconManager type={IconType.IMAGE} size={100} stroke="#90cdf4" />
                </div>
            )}
            {imageTooLarge && (
                <div className="m-auto">
                    <p className="text-sm text-red-400 text-center">
                        The image you've uploaded is too small. Please ensure images are at least {requiredDimensions?.width}x
                        {requiredDimensions?.height} pixels
                    </p>
                </div>
            )}
        </div>
    );
};

export default ImageUploader;
