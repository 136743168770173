import React from 'react';
import { RFC } from '../types/generalTypes';

interface Props {
    hint: string;
    additionalClassNames?: string;
}

function deriveClassNames(additionalClassNames: string | undefined): string {
    let classNames = 'text-gray-500 text-sm font-normal text-center';
    if (additionalClassNames) {
        classNames += ` ${additionalClassNames}`;
    }
    return classNames;
}

const InlineHint: RFC<Props> = ({ hint, additionalClassNames }) => (
    <p className={deriveClassNames(additionalClassNames)}>{hint}</p>
);

export default InlineHint;
