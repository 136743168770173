import { RefObject, useEffect } from 'react';

type Args = {
    ref: RefObject<any>;
    callback: () => void;
};

const useOutsideClick = ({ ref, callback }: Args) => {
    const handleOutsideClick = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);
};

export default useOutsideClick;
