import React, { useContext, useEffect, useRef, useState } from 'react';
import useViewport from '../hooks/useViewport';
import { UserContext } from '../context/UserContextProvider';
import { Error } from '../types/networkTypes';
import useOutsideClick from '../hooks/useOutsideClick';
import TokenManager from '../api/TokenManager';
import { clearSessionStoredVariables } from '../utils';
import { Link, navigate } from '@reach/router';
import { AuthRoutes, NonAuthRoutes } from '../routes';
import { mobileBreakPoint } from '../globals';
import IconManager, { IconType } from '../components/IconManager';
import UserAvatar from '../components/UserAvatar';
import ErrorMessage from '../components/ErrorMessage';
import { RFC } from '../types/generalTypes';
import styled from 'styled-components';
import { Analytics } from '../util/Analytics';
// @ts-ignore
import Logo from '../img/3GT-logo.png';

const AuthNav: RFC = () => {
    const [{ width: windowWidth }] = useViewport();
    const expandableNavRef = useRef<HTMLDivElement>(null);
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [error, setError] = useState<Error | null>(null);
    const [navItemsExpanded, setNavItemsExpanded] = useState<boolean>(false);
    const [mobileNavVisible, setMobileNavVisible] = useState<boolean>(false);
    useOutsideClick({ ref: expandableNavRef, callback: () => setNavItemsExpanded(false) });

    const handleSignout = async (): Promise<void> => {
        try {
            TokenManager.clearTokens();
            clearSessionStoredVariables();
            setCurrentUser(null);
            Analytics.Event.UserSignedOut.track();
            await navigate(NonAuthRoutes.LOGIN);
        } catch (error) {
            error = error.json ? await error.json() : error;
            console.log(error);
            setError(error);
        }
    };

    useEffect(() => {
        setMobileNavVisible(windowWidth <= mobileBreakPoint);
    }, [windowWidth]);

    const mobileLogo =
        windowWidth <= 764 ? (
            <img src={Logo} alt="The Three Good Things" style={{ margin: 'auto' }} width={250} />
        ) : null;
    return (
        <>
            <div className="w-full flex flex-row justify-between items-center md:py-3 relative border-b-2 border-gray-300 md:px-3">
                <Link to={AuthRoutes.GROUP_LIST} className="md:inline-block hidden">
                    <img src={Logo} alt="The Three Good Things" width={275} />
                </Link>
                <DesktopNav className="text-xl text-blue-900 relative">
                    <div className="flex flex-col w-full">
                        <div className="ml-auto px-3">
                            <div className="flex flex-row items-center">
                                <Link to={AuthRoutes.CREATE_ENTRY}>
                                    <button
                                        className="rounded-lg font-semibold hover:bg-blue-600 px-2 mr-3 py-1 bg-blue-500 flex flex-row items-center text-white shadow-md"
                                        style={{ left: '-20px', bottom: '-40px', fontSize: '1rem' }}
                                    >
                                        <IconManager
                                            type={IconType.CREATE_ENTRY}
                                            size={26}
                                            stroke="#fff"
                                        />
                                        <span>Create Entry</span>
                                    </button>
                                </Link>
                                <Link to={AuthRoutes.GROUP_LIST}>
                                    <button
                                        className="bg-transparent font-semibold flex flex-row items-center border-none text-blue-500 px-2 py-1"
                                        style={{ fontSize: '1rem' }}
                                    >
                                        <IconManager
                                            type={IconType.GROUPS}
                                            size={25}
                                            className="mr-1"
                                        />
                                        <span>Groups</span>
                                    </button>
                                </Link>
                                <span className="text-blue-500"> | </span>
                                <Link to={NonAuthRoutes.POSITIVITY_FEED}>
                                    <button
                                        className="bg-transparent font-semibold flex flex-row items-center border-none text-blue-500 px-2 py-1"
                                        style={{ fontSize: '1rem' }}
                                    >
                                        <IconManager
                                            type={IconType.POSITIVITY_FEED}
                                            size={25}
                                            className="mr-1"
                                        />
                                        <span>Positivity Feed</span>
                                    </button>
                                </Link>
                                <div className="inline-flex ml-4 relative">
                                    <UserAvatar
                                        displayName={currentUser?.displayName}
                                        backgroundColor={currentUser?.backgroundColor}
                                        avatarSize="70px"
                                        className="cursor-pointer"
                                        profileImageUrl={currentUser?.profileImage}
                                        onClick={() => setNavItemsExpanded(!navItemsExpanded)}
                                        ref={!mobileNavVisible ? expandableNavRef : null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ExpandableNav
                        signout={handleSignout}
                        expanded={navItemsExpanded}
                        isMobileNav={mobileNavVisible}
                        isAdmin={currentUser?.isAdmin}
                    />
                </DesktopNav>
                <MobileNav className="w-full bg-blue-500 py-3 flex relative flex-row text-center justify-center items-center">
                    <div
                        className="inline-flex flex-row items-center absolute top-0 left-0 mt-5 ml-5"
                        ref={mobileNavVisible ? expandableNavRef : null}
                    >
                        <button
                            className="bg-blue-600 p-1 rounded-full"
                            style={{ padding: '.5rem .6rem' }}
                            onClick={() => setNavItemsExpanded(!navItemsExpanded)}
                        >
                            <IconManager type={IconType.HAMBURGER_MENU} size={32} stroke="#fff" />
                        </button>
                    </div>
                    <ExpandableNav
                        expanded={navItemsExpanded}
                        signout={handleSignout}
                        isMobileNav={mobileNavVisible}
                        isAdmin={currentUser?.isAdmin}
                    />
                    <Link
                        to={AuthRoutes.CREATE_ENTRY}
                        className="inline-flex flex-row items-center"
                    >
                        <button className="bg-white p-1 rounded-full">
                            <IconManager type={IconType.CREATE_ENTRY} size={50} stroke="#009AE7" />
                        </button>
                    </Link>
                    <div className="absolute top-0 right-0 mt-5 mr-5">
                        <Link to={AuthRoutes.PROFILE}>
                            <UserAvatar
                                backgroundColor={currentUser?.backgroundColor}
                                profileImageUrl={currentUser?.profileImage}
                                className="rounded-full shadow-md border border-white cursor-pointer"
                                avatarSize="3.2em"
                                displayName={currentUser?.displayName}
                            />
                        </Link>
                    </div>
                </MobileNav>
            </div>
            {mobileLogo}
            {error && <ErrorMessage code={error?.code} />}
        </>
    );
};

interface ExpandableNavProps {
    signout: () => void;
    isAdmin?: boolean;
    expanded: boolean;
    isMobileNav: boolean;
}

const ExpandableNav: RFC<ExpandableNavProps> = ({ expanded, isAdmin, signout, isMobileNav }) => (
    <div
        className={`w-3/5 md:w-2/3 p-3 ${
            expanded ? 'flex' : 'hidden'
        } flex-col z-50 absolute bg-gray-100 border mr-3 rounded-md shadow-md text-left ${
            !isMobileNav ? 'right-0' : ''
        }`}
        style={{ top: '75px', left: isMobileNav ? '10px' : '' }}
    >
        <Link
            to={AuthRoutes.PROFILE}
            className="w-full text-lg text-blue-700 flex flex-row justify-between items-center hover:text-blue-800 px-2"
        >
            Profile <IconManager type={IconType.USER} />
        </Link>
        {isMobileNav && (
            <>
                <Link
                    to={AuthRoutes.GROUP_LIST}
                    className="w-full text-lg text-blue-700 flex flex-row justify-between items-center hover:text-blue-800 px-2"
                >
                    Groups <IconManager type={IconType.GROUPS} />
                </Link>
                <Link
                    to={NonAuthRoutes.POSITIVITY_FEED}
                    className="w-full text-lg text-blue-700 flex flex-row justify-between items-center hover:text-blue-800 px-2"
                >
                    Positivity Feed <IconManager type={IconType.POSITIVITY_FEED} />
                </Link>
            </>
        )}
        <Link
            to={AuthRoutes.REMINDERS}
            className="w-full text-lg text-blue-700 flex flex-row justify-between items-center hover:text-blue-800 px-2"
        >
            Reminders <IconManager type={IconType.REMINDERS} />
        </Link>
        <Link
            to={NonAuthRoutes.ABOUT}
            className="w-full text-lg text-blue-700 flex flex-row justify-between items-center hover:text-blue-800 px-2"
        >
            About 3GT <IconManager type={IconType.ABOUT_3GT} fill="#006DB5" />
        </Link>
        {isAdmin && (
            <Link
                to={AuthRoutes.APPROVAL_QUEUE}
                className="w-full text-lg text-green-500 flex flex-row justify-between items-center hover:text-green-700 px-2"
            >
                Approval Queue{' '}
                <IconManager type={IconType.APPROVAL_QUEUE} stroke="#00BF71" fill="#fff" />
            </Link>
        )}
        <hr className="bg-gray-600 my-2" style={{ paddingTop: '1px' }} />
        <div className="w-full text-center flex flex-row justify-between items-center px-2 flex-wrap">
            <Link
                to={AuthRoutes.BUG_REPORT}
                className="text-blue-700 flex flex-row text-sm justify-between items-center hover:text-blue-800 mb-2 md:mb-0"
            >
                <IconManager type={IconType.REPORT_BUG} size={19} fill="#006DB5" />
                Report bug
            </Link>
            <button
                className="bg-transparent flex flex-row justify-center items-center mb-2 md:mb-0 ml-1"
                onClick={signout}
            >
                <IconManager type={IconType.SIGN_OUT} size={20} fill="#006DB5" />
                <span className="bg-transparent text-blue-700 hover:text-blue-800 text-sm mr-1">
                    Sign Out
                </span>
            </button>
        </div>
    </div>
);

const DesktopNav = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 765px) {
        display: none;
    }
`;

const MobileNav = styled.div`
    display: none;
    @media (max-width: 764px) {
        display: block;
    }
`;

export default AuthNav;
