import { NavStateKeys } from '../types/networkTypes';
import { useEffect } from 'react';
import { useLocation } from '@reach/router';

export default ({
    stateItem,
    showAlert,
    dismissAlert,
    timeout = 5000,
}: {
    stateItem: NavStateKeys;
    showAlert: () => void;
    dismissAlert: () => void;
    timeout?: number;
}) => {
    const location = useLocation();
    useEffect(() => {
        if (location.state?.[stateItem]) {
            showAlert();
            setTimeout(() => {
                dismissAlert();
            }, timeout);
        }
        return () => {
            location.state = {};
        };
    }, [location.state]);
};
