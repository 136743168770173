import { PublicUser } from './userTypes';

export interface Entry {
    _id: string;
    items: [string, string, string];
    author: PublicUser;
    privacy: Privacy;
    approved?: boolean;
    hidden?: boolean;
    lovedByCount: number
    lovedByCurrentUser?: boolean
}

export enum Privacy {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
    SHARED = 'SHARED',
}

export interface GetEntriesQuery {
    userId?: string;
    groupId?: string;
    limit?: number;
    page?: number;
}

interface PaginatedResponse {
    total: number;
    limit: number;
    page: number;
    pages: number;
}

export interface GetEntriesResponse extends PaginatedResponse {
    entries: Entry[];
}

export interface CreateEntryRequest {
    items: [string, string, string];
    privacy: Privacy;
}

export interface GetPublicEntriesQuery {
    limit?: number;
    page?: number;
}

export interface GetPublicEntriesResponse extends PaginatedResponse {
    entries: Entry[];
}
