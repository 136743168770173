import React from 'react';
//@ts-ignore
import CCLogo from '../img/CClogo_full_notag.png';
import { Analytics } from '../util/Analytics';

export default () => (
    <footer className="flex flex-col w-full py-5 justify-center mx-auto items-center">
        <div className="mb-5">
            <p className="text-center text-sm text-gray-600">A community project by</p>
            <a
                href="https://crosscomm.com/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={Analytics.trackOutbound}
            >
                <img
                    src={CCLogo}
                    alt="CrossComm"
                    className="mx-auto"
                    style={{ height: 'auto', width: '150px' }}
                />
            </a>
        </div>
        <p className="text-center text-gray-500 text-xs">
            &copy;2020 CrossComm Inc. All rights reserved.
        </p>
    </footer>
);
