import React, { useContext, useEffect, useState } from 'react';
import { FormSubmission, RFC } from '../types/generalTypes';
import TextareaAutosize from 'react-textarea-autosize';
import SubmitButton from '../components/SubmitButton';
import Modal from '../components/Modal';
import { createBugReport, getPresignedUrl } from '../api/user';
import { UserContext } from '../context/UserContextProvider';
import SuccessAlert from '../components/SuccessAlert';
import { Link } from '@reach/router';
import { AuthRoutes } from '../routes';
import { truncate } from '../utils';
import ImageUploader from '../components/ImageUploader';
import SplashPageContainer from '../components/SplashPageContainer';
import { Analytics } from '../util/Analytics';
import isAuthed from '../hooks/isAuthed';

const BugReport: RFC = () => {
    isAuthed();
    const [description, setDescription] = useState<string>('');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { currentUser } = useContext(UserContext);
    const [presignedS3Url, setPresignedS3Url] = useState<string>();
    const [uploadedImageFile, setUploadedImageFile] = useState<File>();
    const [reportSubmitted, setReportSubmitted] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        setSubmitting(true);
        Analytics.Event.BugReportFiled.track();
        try {
            await createBugReport({
                description,
                screenshot: uploadedImageFile?.name,
                data: {
                    name: currentUser?.displayName,
                    email: currentUser?.email,
                    userId: currentUser?._id,
                },
            });
            setReportSubmitted(true);
        } catch (error) {
            console.log(error);
        }
        setSubmitting(false);
    };

    useEffect(() => {
        if (reportSubmitted) {
            setDescription('');
            setPresignedS3Url('');
            setUploadedImageFile(undefined);
        }
    }, [reportSubmitted]);

    useEffect(() => {
        (async () => {
            try {
                if (uploadedImageFile) {
                    setPresignedS3Url(
                        await getPresignedUrl({
                            fileName: uploadedImageFile.name,
                            fileType: uploadedImageFile.type,
                        })
                    );
                    setModalOpen(false);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [uploadedImageFile]);

    useEffect(() => {
        (async () => {
            try {
                if (presignedS3Url && uploadedImageFile) {
                    await fetch(presignedS3Url, { method: 'PUT', body: uploadedImageFile });
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [presignedS3Url]);

    return (
        <SplashPageContainer>
            {!reportSubmitted && (
                <h1 className="text-3xl font-semibold text-blue-800 text-center mb-2 block">
                    Report a bug
                </h1>
            )}
            <form className="px-8 pt-6 pb-8 mb-4 mx-2" onSubmit={handleSubmit}>
                {reportSubmitted ? (
                    <div className="flex flex-col justify-center items-center">
                        <SuccessAlert message="Success! Your bug report has been submitted. We'll take a look shortly." />
                        <button
                            className="text-blue-500 hover:text-blue-700 mb-2"
                            onClick={e => {
                                e.preventDefault();
                                setReportSubmitted(false);
                            }}
                        >
                            Report another bug
                        </button>
                        <Link
                            to={AuthRoutes.GROUP_LIST}
                            className="text-sm text-gray-600 hover:text-gray-800"
                        >
                            Return to your Groups list
                        </Link>
                    </div>
                ) : (
                    <>
                        <div className="mb-1">
                            <h2 className="text-lg text-gray-800 font-semibold">
                                Describe the issue
                            </h2>
                            <span className="text-gray-600 text-sm">
                                What were you doing when the bug happened?
                            </span>
                            <TextareaAutosize
                                type="text"
                                className={`form-input mt-1 block w-full outline-none rounded border-gray-200 bg-white`}
                                maxLength={280}
                                minRows={3}
                                onChange={e => setDescription(e.target.value)}
                            />
                            <div
                                className={`flex flex-row justify-${
                                    uploadedImageFile ? 'between' : 'end'
                                } items-center pt-3`}
                            >
                                {uploadedImageFile && (
                                    <span className="text-gray-600 text-sm block italic">
                                        <b>
                                            {truncate(
                                                uploadedImageFile?.name
                                                    .split('_')
                                                    .slice(1)
                                                    .join(''),
                                                20
                                            )}
                                        </b>{' '}
                                        attached
                                    </span>
                                )}

                                <button
                                    className={`flex flex-row items-center bg-${
                                        uploadedImageFile ? 'red' : 'blue'
                                    }-500 hover:bg-${
                                        uploadedImageFile ? 'red' : 'blue'
                                    }-700 text-white font-semibold px-2 py-1 text-sm rounded-md`}
                                    onClick={e => {
                                        e.preventDefault();
                                        uploadedImageFile
                                            ? setUploadedImageFile(undefined)
                                            : setModalOpen(true);
                                    }}
                                >
                                    {!uploadedImageFile ? (
                                        <>
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.1716 7L8.58579 13.5858C7.80474 14.3668 7.80474 15.6332 8.58579 16.4142C9.36684 17.1953 10.6332 17.1953 11.4142 16.4142L17.8284 9.82843C19.3905 8.26633 19.3905 5.73367 17.8284 4.17157C16.2663 2.60948 13.7337 2.60948 12.1716 4.17157L5.75736 10.7574C3.41421 13.1005 3.41421 16.8995 5.75736 19.2426C8.1005 21.5858 11.8995 21.5858 14.2426 19.2426L20.5 13"
                                                    stroke="#fff"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            Add attachment
                                        </>
                                    ) : (
                                        <>
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6 18L18 6M6 6L18 18"
                                                    stroke="#fff"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            Clear attachment
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                        <SubmitButton
                            className="bg-green-500 hover:bg-green-700 mt-5 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                            loading={submitting}
                            disabled={!description}
                        >
                            SUBMIT REPORT
                        </SubmitButton>
                        <Link
                            to={AuthRoutes.GROUP_LIST}
                            className="text-sm text-center mt-2 block text-blue-500 hover:text-blue-700"
                        >
                            Return to your Groups list
                        </Link>
                    </>
                )}
            </form>
            {modalOpen && (
                <Modal isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
                    <ImageUploader setUploadedImageFile={setUploadedImageFile} />
                </Modal>
            )}
        </SplashPageContainer>
    );
};

export default BugReport;
