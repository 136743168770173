import React from 'react';
import { RFC } from '../types/generalTypes';
import { NonAuthRoutes } from '../routes';
import NavLink from './NavLink';

const NonAuthNav: RFC = () => (
    <div className="w-full p-2 md:p-5 flex flex-row justify-end items-center">
        <div>
            <NavLink to={NonAuthRoutes.ABOUT} className="mr-4 text-blue-500 hover:text-blue-700">
                About 3GT
            </NavLink>
            <NavLink
                to={NonAuthRoutes.POSITIVITY_FEED}
                className="mr-4 text-blue-500 hover:text-blue-700"
            >
                Positivity Feed
            </NavLink>
            <NavLink to={NonAuthRoutes.LOGIN} className="mr-2 text-blue-500 hover:text-blue-700">
                Login
            </NavLink>
            <NavLink to={NonAuthRoutes.REGISTER} className="ml-2 text-blue-500 hover:text-blue-700">
                Sign Up
            </NavLink>
        </div>
    </div>
);

export default NonAuthNav;
