import React from 'react';
import styled from 'styled-components';
import { RFC } from '../types/generalTypes';

const NotFound: RFC = () => (
    <Container>
        <div className="h-screen w-screen bg-blue-600 flex justify-center content-center items-center flex-wrap">
            <p className="font-sans text-white error-text">404</p>
        </div>
        <div className="absolute w-screen bottom-0 mb-6 text-white text-center font-sans text-xl">
            <p className="text-white text-center block mb-32 message">
                Sorry, the page you're looking for doesn't exist
            </p>
            <button
                className="bg-white hover:bg-green-500 text-blue-600 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => window.history.back()}
            >
                Go back to safety
            </button>
        </div>
    </Container>
);

export default NotFound;

const Container = styled.div`
    .error-text {
        font-size: 130px;
    }

    @media (min-width: 768px) {
        .error-text {
            font-size: 220px;
        }
    }

    @media (max-width: 415px) {
        .message {
            margin-bottom: 2em;
        }
    }

    @media (max-width: 320px) {
        .message {
            margin-bottom: auto;
        }
    }
`;
