import { nowInUnixEpochTime } from '../utils';
import jwtDecode from 'jwt-decode';
import FetchWrapper from './FetchWrapper';
import { RefreshResponse } from '../types/networkTypes';

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';

class TokenManager {
    timeoutId: number | null = null;
    accessExpirationHandler: (args?: any) => any = () => {};

    clearTokens = (): void => {
        this.setAccessToken(null);
        this.setRefreshToken(null);
    };

    setAccessToken = (token: string | null): void => {
        return localStorage.setItem(ACCESS_TOKEN, token || '');
    };

    setRefreshToken = (token: string | null): void => {
        return localStorage.setItem(REFRESH_TOKEN, token || '');
    };

    getRefreshToken = (): string | null => {
        return localStorage.getItem(REFRESH_TOKEN);
    };

    getAccessToken = async (): Promise<string | null> => {
        const accessToken: string | null = localStorage.getItem(ACCESS_TOKEN);
        if (!accessToken) return null;
        if (!this.tokenIsValid(accessToken) && !!this.getRefreshToken()) {
            await this.reauthenticate();
        }
        return localStorage.getItem(ACCESS_TOKEN);
    };

    tokenIsValid = (token: string): boolean => {
        try {
            return nowInUnixEpochTime() < jwtDecode<{ exp: number }>(token).exp;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    getTimeToAccessExpiration = async (): Promise<false | number> => {
        const token = await this.getAccessToken();
        if (!token) return false;
        const decoded = jwtDecode<{ exp: number }>(token);
        return decoded.exp - nowInUnixEpochTime();
    };

    setAccessTokenExpirationHandler = async (func: any): Promise<boolean> => {
        // It's possible that we don't have a refresh token when this gets called
        // so we return true/false indicating successful handler setting.
        try {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            const time: number | false = await this.getTimeToAccessExpiration();
            if (!time) {
                return false;
            }
            const timeInMilliseconds: number = time * 1000;
            this.accessExpirationHandler = async (): Promise<void> => {
                await this.reauthenticate();
                if (!this.getAccessToken()) {
                    func();
                }
            };
            this.timeoutId = setTimeout(this.accessExpirationHandler, timeInMilliseconds);

            return true;
        } catch (e) {
            e = e.json ? await e.json() : e;
            console.error(e);
            return false;
        }
    };

    reauthenticate = async (): Promise<void> => {
        try {
            const res = await FetchWrapper({
                path: 'auth/refresh',
                mode: 'cors',
                method: 'POST',
                body: {
                    refreshToken: this.getRefreshToken(),
                },
            });
            const { jwt }: RefreshResponse = await res.json();
            return this.setAccessToken(jwt.accessToken);
        } catch (error) {
            // User session is timed out or internal server error. In either
            // case the app is not available to the user
            error = error.json ? await error.json() : error;
            console.error(error);
            this.clearTokens();
        }
    };
}

export default new TokenManager();
