import React from 'react';
import { RFC } from '../types/generalTypes';
import styled from 'styled-components';
type AlertType = 'success' | 'failure';

interface Props {
    message: string;
    className?: string;
    close: () => void;
    type: AlertType;
}

const Alert: RFC<Props> = ({ message, className, close, type }) => (
    <FadeInContainer className={className}>
        <div
            className={`bg-${type === 'success' ? 'green' : 'red'}-100 border border-${
                type === 'success' ? 'green' : 'red'
            }-400 text-${
                type === 'success' ? 'green' : 'red'
            }-700 px-4 py-3 rounded relative w-full`}
            role="alert"
        >
            <span className="block sm:inline ml-1">{message}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg
                    className={`fill-current h-6 w-6 text-${
                        type === 'success' ? 'green' : 'red'
                    }-500`}
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    onClick={close}
                >
                    <title>Close</title>
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
            </span>
        </div>
    </FadeInContainer>
);

export default Alert;

const FadeInContainer = styled.div`
    @keyframes fadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
    position: absolute;
    top: 10%;
    left: 20%;
    width: 50%;
    animation-timing-function: ease-in-out;
    transform-origin: bottom center;
    animation-name: fadeOut;
    background-color: transparent;
    max-width: 500px;
    @media (max-width: 720px) {
        left: 10px;
        width: 95%;
    }
`;
