import { ErrorCodes } from './types/networkTypes';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import formatDistance from 'date-fns/formatDistance';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import format from 'date-fns/format';
import { LAST_GROUP_ID_VIEWED_BY_USER, USER_DISMISSED_REMINDERS_ENCOURAGEMENT } from './globals';

export const nowInUnixEpochTime = (): number => {
    // Time in UNIX Epoch, with decimals rounded up
    return Math.round(new Date().getTime() / 1000);
};

export const buildQueryString = (props: Object): string => {
    const query: string[] = [];
    for (const key in props) {
        if (!!props[key]) {
            query.push(`${key}=${encodeURIComponent(props[key])}`);
        }
    }
    return query.join('&');
};

export const validateEmail = (email: string): boolean => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLocaleLowerCase());
};

export const deriveDateFromMongoId = (id: string): Date => new Date(parseInt(id.slice(0, 8), 16) * 1000);

export const copyToClipboard = (str: string) => {
    const el = document.createElement('textarea'); // Create a <textarea> element
    el.value = str; // Set its value to the string that you want copied
    el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px'; // Move outside the screen to make it invisible
    document.body.appendChild(el); // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection?.()?.rangeCount ?? 0 > 0 // Check if there is any content selected previously
            ? document.getSelection()?.getRangeAt(0) // Store selection if found
            : false; // Mark as false to know no selection existed before
    el.select(); // Select the <textarea> content
    document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el); // Remove the <textarea> element
    if (selected) {
        // If a selection existed before copying
        document.getSelection()?.removeAllRanges(); // Unselect everything on the HTML document
        document.getSelection()?.addRange(selected); // Restore the original selection
    }
};

export const truncate = (str: string, maxChar: number) => (str.length > maxChar ? `${str.substr(0, maxChar)}...` : str);

export const presentErrorBasedOnCode = (code: ErrorCodes): string => {
    const checkInternet = 'Please check your internet connection & try again.';
    switch (code) {
        case ErrorCodes.DATA_VALIDATION:
            return `Something went wrong while processing your request. ${checkInternet}`;
        case ErrorCodes.BAD_USERNAME:
            return "The email you've provided is already associated with an account. Please choose another.";
        case ErrorCodes.PASSWORD_RESET:
            return `Something went wrong while resetting your password. ${checkInternet}`;
        case ErrorCodes.DUPLICATE_DATA:
            return 'It looks like there is some duplicated fields in your request. Be sure that all unique fields are in fact unique.';
        case ErrorCodes.INVALID_INVITATION:
            return 'This invitation has expired. Please request a new one from a group member or moderator.';
        case ErrorCodes.TOO_MANY_ENTRIES:
            return `You've already submitted an entry within 24 hours. Please wait until 6:00 am local time on ${getTomorrow()} before posting again.`;
        case ErrorCodes.INVALID_CREDENTIALS:
            return "The credentials you've provided are invalid. Please try again.";
        case ErrorCodes.AUTH_FAILURE:
            return "We're having trouble authenticating you. Please return to the login page to start a new session.";
        case ErrorCodes.UNAUTHORIZED:
            return 'You are not authorized to perform this action.';
        case ErrorCodes.INVALID_REFRESH_TOKEN:
            return "We're having trouble authenticating you. Please return to the login page to start a new session.";
        case ErrorCodes.NOT_FOUND:
            return `We weren't able to find any resources matching your request. ${checkInternet}`;
        case ErrorCodes.LAST_MODERATOR:
            return 'Because you are the last moderator of this group, you must add another moderator before leaving';
        case ErrorCodes.INTERNAL_SERVER_ERROR:
        default:
            return `An unknown error has occurred. We apologize for the inconvenience. ${checkInternet}`;
    }
};

function getTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // even 32 is acceptable
    return `${tomorrow.getMonth() + 1}/${tomorrow.getDate()}`;
}

export const deriveInitialsFromDisplayName = (displayName: string): string => {
    //doesn't take numbers/special chars into account
    const parsedName = displayName.split(' ');
    const lastIndex = parsedName.length - 1;

    return parsedName?.length > 1
        ? parsedName[0][0].toLocaleUpperCase() + parsedName[lastIndex][0].toLocaleUpperCase()
        : parsedName[0][0].toLocaleUpperCase();
};

export const getTimeZone = (): string => {
    const offset = new Date().getTimezoneOffset();
    let absoluteOffset = Math.abs(offset);
    if (absoluteOffset / 60 > 12) {
        absoluteOffset = 12 * 60; // This is to account for '(GMT +13:00) Apia, Nukualofa' && '(GMT +14:00) Line Islands, Tokelau', and '(GMT +12:45) Chatham Islands'
    }

    const overflow = absoluteOffset % 60;
    if (overflow > 0) {
        // "round" to nearest hour
        absoluteOffset -= overflow;
        absoluteOffset += overflow >= 30 ? 60 : 0;
    }
    return (offset < 0 ? '+' : '-') + ('00' + Math.floor(absoluteOffset / 60)).slice(-2) + ':00';
};

export const formatPhoneNumber = (phoneNumber: string | number): string | undefined => {
    const cleaned = ('' + String(phoneNumber)).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
};

export const validatePhoneNumber = (phoneNumber: string) => /^1?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phoneNumber);

export const renderRelativeEntryDateFromMongoId = (mongoId: string): string => {
    const entryDate = deriveDateFromMongoId(mongoId);
    if (isToday(entryDate)) {
        return 'Today';
    } else if (isYesterday(entryDate)) {
        return 'Yesterday';
    }

    const daysAgo = formatDistance(entryDate, new Date(), { addSuffix: true });
    if (Number(daysAgo.split(' ')[0]) % 7 === 0) {
        const weeks = differenceInWeeks(new Date(), entryDate);
        return `${weeks === 0 ? 1 : weeks} week${weeks === 0 ? '' : 's'} ago`;
    } else {
        return formatDistance(entryDate, new Date(), { addSuffix: true });
    }
};

export const renderFullEntryDateFromMongoId = (mongoId: string): string => {
    const entryDate = deriveDateFromMongoId(mongoId);
    return format(entryDate, 'M/d/yy - h:mm a');
};

export const clearSessionStoredVariables = () => {
    sessionStorage.setItem(USER_DISMISSED_REMINDERS_ENCOURAGEMENT, '');
    sessionStorage.setItem(LAST_GROUP_ID_VIEWED_BY_USER, '');
};
