import { CreateInvitationResponse, GroupResponse, ToggleEntryPath, UpdateGroupRequest } from '../types/groupTypes';
import FetchWrapper from './FetchWrapper';
import TokenManager from './TokenManager';
import { Entry } from '../types/entryTypes';

export const getGroup = async (groupId: string): Promise<GroupResponse> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: `group/${groupId}`,
        token,
    });

    return await res.json();
};

export const getGroups = async (): Promise<GroupResponse[]> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: 'group',
        token,
    });
    return await res.json();
};

// @ts-ignore
window.getGroup = getGroup;

export const createGroup = async (name: string): Promise<GroupResponse> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: 'group',
        method: 'POST',
        token,
        body: { name },
    });

    return await res.json();
};

export const updateGroup = async ({ request, groupId }: { request: UpdateGroupRequest; groupId: string }): Promise<GroupResponse> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: `group/${groupId}`,
        method: 'PATCH',
        token,
        body: request,
    });

    return await res.json();
};

export const createInvitation = async ({ groupId, email }: { groupId: string; email?: string }): Promise<string | void> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: `group/${groupId}/invitation`,
        method: 'POST',
        token,
        body: { email },
    });

    const invitation: CreateInvitationResponse = await res.json();
    if (invitation?.multiUserInviteLink) {
        return invitation.multiUserInviteLink;
    }
};

export const redeemInvitation = async (invitationId): Promise<string> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');
    const res = await FetchWrapper({
        path: `group/redeem?id=${invitationId}`,
        method: 'POST',
        token,
    });
    const { groupId } = await res.json();
    return groupId;
};

export const rejectInvitation = async (invitationId): Promise<void> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    await FetchWrapper({
        path: `group/reject?id=${invitationId}`,
        method: 'DELETE',
        token,
    });
};

export const toggleEntryVisibility = async ({ groupId, entryId }: ToggleEntryPath): Promise<Entry> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: `group/${groupId}/entry/${entryId}`,
        method: 'PATCH',
        token,
    });

    return await res.json();
};

export const getGroupNameByInvitation = async (invitationId: string): Promise<string> => {
    const res = await FetchWrapper({
        path: `groupbyinvitation/${invitationId}`,
    });
    const { groupName } = await res.json();
    return groupName;
};

export const getGroupCoverPhotoUrl = async (groupId: string): Promise<string> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: `group/${groupId}/photo`,
        token,
    });

    return await res.json();
};

export const deleteGroupCoverPhoto = async (groupId: string): Promise<GroupResponse> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: `group/${groupId}/photo`,
        method: 'DELETE',
        token,
    });

    return await res.json();
};
