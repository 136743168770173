import React from 'react';
import { ButtonClick, RFC } from '../types/generalTypes';
import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner';

interface Props {
    loading?: boolean;
    children: string;
    className: string;
    disabled?: boolean;
    onClick?: (e: ButtonClick) => void;
    type?: 'button' | 'submit';
}

const SubmitButton: RFC<Props> = ({
    loading,
    children,
    className,
    disabled = loading,
    onClick,
    type = 'submit',
}) => (
    //if the submit button is disabled & there's an onClick provided, ensure button is of type 'button' to trigger onClick
    <button
        type={type}
        className={className + ` relative ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={onClick}
        disabled={!onClick && disabled}
    >
        <LoadingSpinner type="button" loading={loading ? 1 : 0} />
        <Text loading={loading ? 1 : 0}>{children}</Text>
    </button>
);

export default SubmitButton;

const Text = styled.p<{ loading: number }>`
    font-weight: bold;
    transition: opacity 200ms;
    transition-delay: ${({ loading }) => (!!loading ? '0ms' : '200ms')};
    width: 100%;
    opacity: ${({ loading }) => (!!loading ? 0 : 1)};
`;
