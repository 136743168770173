import React, { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import { RFC } from '../types/generalTypes';
import { truncate } from '../utils';

interface Props {
    title: string | (() => JSX.Element);
    subTitles?: string[];
    children: ReactNode;
    toolTip?: string;
    containerClassName?: string;
    buttonClassName?: string;
    chevronClassName?: string;
    contentClassName?: string;
    chevronLeft?: boolean;
    onClick?: () => void;
}

const Accordion: RFC<Props> = ({
    title,
    children,
    toolTip,
    subTitles,
    containerClassName,
    buttonClassName,
    chevronLeft = false,
    chevronClassName,
    contentClassName,
    onClick,
}) => {
    const [active, setActive] = useState<boolean>(false);
    const [height, setHeight] = useState<string>('0px');
    const [rotate, setRotate] = useState<string>('icon');
    const content = useRef<HTMLDivElement>(null);
    const Title = title;
    const toggle = () => {
        setActive(!active);
        setHeight(active ? '0px' : `${content.current!.scrollHeight}px`);
        setRotate(`icon ${!active ? 'rotate' : ''}`);
    };

    return (
        <Section className={containerClassName || '' + 'w-full'}>
            <AccordionButton
                onClick={() => {
                    onClick?.();
                    toggle();
                }}
                className={buttonClassName}
            >
                {chevronLeft && (
                    <svg
                        className={rotate + ` ${chevronClassName}`}
                        height={20}
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                    >
                        <path
                            fill="#D3D3D3"
                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        />
                    </svg>
                )}
                {typeof title === 'string' ? (
                    <h4 className="font-bold text-lg">{Title}</h4>
                ) : (
                    <Title />
                )}
                {subTitles?.map((subTitle, i) => (
                    <h5 className="text-sm text-gray-500 ml-5" key={i}>
                        {truncate(subTitle, 80)}
                    </h5>
                ))}
                <div className="flex flex-row items-center ml-auto justify-center">
                    <span className="text-blue-100 text-sm mr-5">{toolTip}</span>
                    {!chevronLeft && (
                        <svg
                            className={rotate + ` ${chevronClassName}`}
                            height={20}
                            width={20}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path
                                fill="#D3D3D3"
                                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                            />
                        </svg>
                    )}
                </div>
            </AccordionButton>
            <ContentContainer ref={content} maxHeight={height} className={contentClassName}>
                <ContentText>{children}</ContentText>
            </ContentContainer>
        </Section>
    );
};

export default Accordion;

const AccordionButton = styled.button.attrs({ type: 'button' })`
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none !important;
    outline: none !important;
    transition: background-color 0.3s ease;
    border-radius: 7px;
    .icon {
        margin-left: auto;
        transition: transform 0.3s ease;
    }

    .rotate {
        transform: rotate(90deg);
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContentContainer = styled.div<{ maxHeight: string }>`
    overflow: hidden;
    transition: max-height 0.5s ease;
    max-height: ${({ maxHeight }) => maxHeight};
`;

const ContentText = styled.div`
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
`;
