import React, { ReactNode } from 'react';
import { RFC } from '../types/generalTypes';
import isAuthed from '../hooks/isAuthed';
import CopyRightFooter from './CopyRightFooter';

const AuthPageContainer: RFC<{ children: ReactNode }> = ({ children }) => {
    isAuthed();
    return (
        <div className="mx-auto min-h-screen flex flex-col" style={{ maxWidth: '68rem' }}>
            <div className={`w-full flex-grow mx-auto bg-white`}>{children}</div>
            <CopyRightFooter />
        </div>
    );
};

export default AuthPageContainer;
