import React, { useContext, useEffect, useState } from 'react';
import { RFC } from '../types/generalTypes';
import { RouteComponentProps } from '@reach/router';
import { FetchRequest, Error } from '../types/networkTypes';
import { PublicUser } from '../types/userTypes';
import { getUser } from '../api/user';
import LoadingSpinner from '../components/LoadingSpinner';
import { UserContext } from '../context/UserContextProvider';
import UserAvatar from '../components/UserAvatar';
import ErrorMessage from '../components/ErrorMessage';
import { timeZones } from '../timeZones';
import Entry from '../components/Entry';
import { Entry as EntryType } from '../types/entryTypes';
import { getEntries } from '../api/entry';
import PublicEntry from '../components/PublicEntry';

interface Props extends RouteComponentProps {
    id?: string;
}

const MemberDetails: RFC<Props> = ({ id }) => {
    const [memberData, setMemberData] = useState<
        FetchRequest<{ member: PublicUser | undefined; entries: EntryType[] }, Error>
    >({
        fetching: true,
        data: { member: undefined, entries: [] },
        error: null,
    });
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            try {
                if (id) {
                    const [member, { entries }] = await Promise.all([
                        getUser(id),
                        getEntries({ userId: id }),
                    ]);
                    setMemberData(prev => ({ ...prev, data: { member, entries } }));
                }
            } catch (error) {
                error = error.json ? await error.json() : error;
                console.log(error);
                setMemberData(prev => ({ ...prev, error }));
            }
            setMemberData(prev => ({ ...prev, fetching: false }));
        })();
    }, [id]);

    return (
        <div className="px-5">
            {memberData.fetching ? (
                <LoadingSpinner type="page" />
            ) : memberData.error ? (
                <ErrorMessage code={memberData.error?.code} />
            ) : (
                <div className="max-w-3xl mx-auto">
                    <div className="w-full p-2 flex rounded-md flex-col justify-center items-center">
                        <UserAvatar
                            displayName={memberData.data.member!.displayName}
                            backgroundColor={memberData.data.member!.backgroundColor}
                            profileImageUrl={memberData.data.member?.profileImage}
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <h3 className="text-xl block text-center font-bold block">
                            {memberData.data.member!.displayName}
                        </h3>
                        <span className="text-gray-500 text-center">
                            {
                                timeZones.find(
                                    ({ value }) =>
                                        Number(value.split(':')[0]) ===
                                        memberData.data.member!.timeZone
                                )?.label
                            }
                        </span>
                    </div>
                    {!!memberData.data.entries.length ? (
                        <div className="mt-5 p-3">
                            <ul className="list-disc">
                                {memberData.data.entries.map(entry =>
                                    currentUser ? (
                                        <Entry
                                            entry={entry}
                                            key={entry._id}
                                            viewingMemberProfile={true}
                                            updateEntryInLocalState={updatedEntry =>
                                                setMemberData(prev => ({
                                                    ...prev,
                                                    data: {
                                                        ...prev.data,
                                                        entries: prev.data.entries.map(entry =>
                                                            entry._id === updatedEntry._id
                                                                ? updatedEntry
                                                                : entry
                                                        ),
                                                    },
                                                }))
                                            }
                                        />
                                    ) : (
                                        <PublicEntry entry={entry} key={entry._id} />
                                    )
                                )}
                            </ul>
                        </div>
                    ) : (
                        <div className="text-center text-gray-500 mt-5">
                            It looks like {memberData.data.member?.displayName} doesn't have any
                            entries.
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MemberDetails;
