import React, { useContext } from 'react';
import { RFC } from '../types/generalTypes';
import { Link } from '@reach/router';
import { NonAuthRoutes } from '../routes';
import { UserContext } from '../context/UserContextProvider';
import { Analytics } from '../util/Analytics';

const About: RFC = () => {
    const { currentUser } = useContext(UserContext);
    return (
        <div className="px-5 py-2">
            <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                <div className="flex flex-col w-full justify-center items-start text-center md:text-left">
                    <h1 className="my-4 text-3xl md:text-5xl font-bold leading-tight text-gray-800">
                        Welcome to The 3 Good Things
                    </h1>
                    <p className="ml-3 leading-normal text-lg md:text-2xl mb-8 text-gray-600">
                        Focus on the good things that have happened to you{' '}
                        <span className="font-semibold">today</span>
                    </p>
                </div>
                <div
                    className="max-w-5xl rounded-md shadow mx-4 md:mx-2 py-5 px-2 md:p-8"
                    style={{ backgroundColor: '#F6F6F6' }}
                >
                    <div className="text-gray-700 text-lg ml-2 px-5 pb-4">
                        <h4 className="text-2xl font-semibold">Introduction</h4>
                        <p className="ml-2 my-3">
                            People who are grateful tend to be happier and feel more fulfilled. In
                            fact, past studies have shown that people who wrote down and shared what
                            they were grateful for each night, for just one week, ended up being
                            happier up to six months later.
                        </p>
                        <p className="ml-2 my-3">
                            The 3 Good Things app is an easy-to-use online gratitude journal that
                            lets you jot down three things each day that have gone well or have made
                            you happy. As time passes, you can look back over your entries to
                            reflect on the positive moments in your life.
                        </p>
                        <p className="ml-2 my-3">
                            The 3 Good Things app also allows you to create groups of friends,
                            families, or coworkers so each member can share their own uplifting
                            moments.
                        </p>
                        <p className="ml-2 my-3">
                            Let's continue focusing on the positive. <b>Together</b>.
                        </p>

                        <h4 className="text-2xl font-semibold mt-5">How It Works</h4>
                        <p className="ml-2 my-3">
                            The Three Good Things is all about taking time once a day to write down
                            three good things that happened today.
                        </p>
                        <p className="ml-2 my-3">
                            Once you create an account, you'll be able to create that entry once a
                            day.
                        </p>
                        <p className="ml-2 my-3">
                            You will also be able to join groups of friends or family. You'll be
                            able to see the 3 Good Things they write down, and they will be able to
                            see yours. (If you don't want an entry shared, you can always mark it
                            private).
                        </p>
                        <p className="ml-2 my-3">
                            If you want a gentle nudge each day, you can set a time to be reminded
                            via email or SMS to log in and write your entry. Try to do it every day.
                            Consistency is key!
                        </p>
                        {currentUser === null && (
                            <Link to={NonAuthRoutes.REGISTER}>
                                <button className="bg-blue-500 hover:bg-blue-700 text-white px-2 py-1 text-lg rounded-md font-semibold mt-2">
                                    Get Started
                                </button>
                            </Link>
                        )}
                        <h4 className="text-2xl font-semibold mt-5">Who Made This?</h4>
                        <p className="ml-2 my-3">
                            The 3 Good Things is a Community Project developed by&nbsp;
                            <a
                                href="https://crosscomm.com/"
                                className="underline text-blue-500 hover:text-blue-600 font-semibold"
                                onClick={Analytics.trackOutbound}
                            >
                                CrossComm, a Durham, NC app development studio
                            </a>{' '}
                            with the assistance of Jon Nicolla, Fred Friedman, Michala Ritz and Dr.
                            Arif Kamal of Duke University. As the COVID-19 pandemic was spreading,
                            we set out to do our part to help everyone have a way to write down,
                            reflect on and share the good things during challenging times.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
