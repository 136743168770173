import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { User } from '../types/userTypes';
import { getCurrentUser } from '../api/user';
import TokenManager from '../api/TokenManager';

type UserContextType = {
    currentUser: User | null | undefined;
    setCurrentUser: (user: User | null) => void;
};

export const UserContext = createContext<UserContextType>({
    currentUser: null,
    setCurrentUser: () => {},
});

export default function UserContextProvider({ children }: { children: ReactNode }) {
    const [currentUser, setCurrentUser] = useState<User | null>();

    useEffect(() => {
        (async () => {
            try {
                if (!currentUser && (await TokenManager.getAccessToken())) {
                    return setCurrentUser(await getCurrentUser());
                } else {
                    setCurrentUser(null);
                }
            } catch (error) {
                console.log(error);
                setCurrentUser(null);
            }
        })();
    }, []);
    return (
        <UserContext.Provider value={{ currentUser, setCurrentUser }}>
            {children}
        </UserContext.Provider>
    );
}
