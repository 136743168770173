export interface User {
    _id: string;
    emailVerified: boolean;
    displayName: string;
    isAdmin: boolean;
    timeZone: number;
    reminders: ReminderOptions;
    reminderTime?: number; // minutes after midnight UTC
    phoneVerified: boolean;
    backgroundColor: string; // hex code of user's preferred color
    email: string;
    phone?: number; // with country code
    postEligible: boolean;
    profileImage?: string;
}

export type PublicUser =  Pick<User, 'displayName' | 'backgroundColor' | 'timeZone' | '_id' | 'profileImage'>

export type UpdateUserRequest = Partial<Pick<User, 'displayName' | 'timeZone' | 'phone' | 'backgroundColor' | 'reminders' | 'reminderTime' | 'profileImage'>>

export enum UpdateUserFormState {
    displayName = 'displayName',
    timeZone = 'timeZone',
    phone = 'phone',
    backgroundColor = 'backgroundColor',
    reminders = 'reminders',
    reminderTime = 'reminderTime',
}

export enum ReminderOptions {
    NONE = 'NONE',
    EMAIL = 'EMAIL',
    SMS = 'SMS',
}

export interface BugReportRequest {
    description: string;
    data?: any; // (frontend can provide anything it thinks is relevant here)
    screenshot?: string; // (Uploaded as a part of the request, stored in the DB as S3 identifier)
}

export interface BugReportResponse {
    _id: string;
    description: string;
    data?: any; // (frontend can provide anything it thinks is relevant here)
    screenshot?: string; // (Uploaded as a part of the request, stored in the DB as S3 identifier)
}
