import React, { useEffect, useState } from 'react';
import { redeemInvitation } from '../api/group';
import { Link, navigate } from '@reach/router';
import { AuthRoutes, NonAuthRoutes } from '../routes';
import useURLParams from '../hooks/useURLParams';
import LoadingSpinner from '../components/LoadingSpinner';
import SplashPageContainer from '../components/SplashPageContainer';
import { RFC } from '../types/generalTypes';
import TokenManager from '../api/TokenManager';
import { Error, NavStateKeys, URLParams } from '../types/networkTypes';
import ErrorMessage from '../components/ErrorMessage';

const RedeemInvitation: RFC = () => {
    const [invitationId] = useURLParams(URLParams.INVITE_ID);
    const [error, setError] = useState<Error | null>(null);
    useEffect(() => {
        (async () => {
            try {
                if (invitationId) {
                    if (await TokenManager.getAccessToken()) {
                        const groupIdUserWasInvitedTo = await redeemInvitation(invitationId);
                        await navigate(AuthRoutes.GROUP_LIST + `/${groupIdUserWasInvitedTo}`, {
                            state: { [NavStateKeys.INVITE_ACCEPTED]: true },
                        });
                    } else {
                        await navigate(NonAuthRoutes.LOGIN + `?id=${invitationId}`);
                    }
                }
            } catch (error) {
                error = error.json ? await error.json() : error;
                setError(error);
                console.log(error);
            }
        })();
    }, [invitationId]);
    return (
        <SplashPageContainer>
            {error ? (
                <div className="mx-auto p-2 flex flex-col text-center justify-center">
                    <ErrorMessage code={error?.code} className="mb-3" />
                    <Link
                        to={NonAuthRoutes.LOGIN}
                        className="text-blue-500 hover:text-blue-600 font-semibold"
                    >
                        Return to login
                    </Link>
                </div>
            ) : (
                <LoadingSpinner type="page" />
            )}
        </SplashPageContainer>
    );
};

export default RedeemInvitation;
