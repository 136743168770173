import TokenManager from './TokenManager';
import FetchWrapper from './FetchWrapper';
import { CreateEntryRequest, Entry, GetEntriesQuery, GetPublicEntriesQuery, GetPublicEntriesResponse, Privacy } from '../types/entryTypes';
import { buildQueryString } from '../utils';

export const getEntries = async (query?: GetEntriesQuery): Promise<GetPublicEntriesResponse> => {
    //this endpoint can be both auth and non-auth, so we don't throw an error if there isn't a token
    const token: string | null = await TokenManager.getAccessToken();

    const res = await FetchWrapper({
        path: query ? 'entry'.concat(`?limit=10&${buildQueryString(query)}`) : 'entry',
        token,
    });
    return await res.json();
};

export const createEntry = async (request: CreateEntryRequest): Promise<Entry> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: 'entry',
        method: 'POST',
        token,
        body: request,
    });

    return await res.json();
};

export const deleteEntry = async (entryId: string): Promise<void> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    await FetchWrapper({
        path: `entry/${entryId}`,
        method: 'DELETE',
        token,
    });
};

export const editEntryPrivacy = async ({ entryId, privacy }: { entryId: string; privacy: Privacy }): Promise<Entry> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: `entry/${entryId}`,
        method: 'PATCH',
        token,
        body: { privacy },
    });

    return await res.json();
};

export const getAllPublicEntries = async (query: GetPublicEntriesQuery): Promise<GetPublicEntriesResponse> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: 'entry/public/review'.concat(`?limit=10&${buildQueryString(query)}`),
        token,
    });

    return await res.json();
};

export const getApprovedPublicEntries = async (query: GetPublicEntriesQuery): Promise<GetPublicEntriesResponse> => {
    const token: string | null = await TokenManager.getAccessToken();

    const res = await FetchWrapper({
        path: 'entry/public'.concat(`?limit=10&${buildQueryString(query)}`),
        token,
    });

    return await res.json();
};

export const getPublicEntry = async (entryId: string): Promise<Entry> => {
    const token: string | null = await TokenManager.getAccessToken();

    const res = await FetchWrapper({
        path: `entry/${entryId}/public`,
        token,
    });

    return await res.json();
};

export const toggleEntryApproval = async (entryId: string): Promise<Entry> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: `entry/${entryId}/approval`,
        method: 'POST',
        token,
    });

    return await res.json();
};

export const toggleEntryLovedStatus = async ({ entryId, love }: { entryId: string; love: boolean }): Promise<Entry> => {
    const token: string | null = await TokenManager.getAccessToken();
    if (!token) throw new Error('Token is either missing or invalid');

    const res = await FetchWrapper({
        path: `entry/${entryId}/love`,
        method: 'POST',
        token,
        body: { love }
    });

    return await res.json();
};
