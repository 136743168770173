import ReactGA from 'react-ga';
import React from 'react';

/**
 * Utility method for tracking an event that's easier to use than constructing a params object.
 */
function track(
    category: string,
    action: string,
    label?: string,
    value?: number,
    nonInteraction?: boolean,
    transport?: string
) {
    ReactGA.event({ category, action, label, value, nonInteraction, transport });
}

/**
 * Tracks the outbound link before redirecting the user's browser to the indicated link; use with any
 * `a` tag. Example:
 *
 *      <a href="http://www.website.com" onClick={Analytics.trackOutbound}>...</a>
 */
function trackOutbound(event: React.MouseEvent): boolean {
    const outboundUrl = (event.target as HTMLAnchorElement).href;
    if (outboundUrl) {
        ReactGA.outboundLink({ label: outboundUrl }, () => {
            window.location.assign(outboundUrl);
        });
        return true;
    } else {
        return false;
    }
}

const UtmKeys = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_term'];
function initialize(analyticsId: string) {
    // TODO: I'm pretty sure this isn't how we're actually supposed to track UTM tokens, but I
    // can't find "the right way" right now. Improve this later.
    ReactGA.initialize(analyticsId);

    const queryString = window.location.href.split('?')[1];
    if (queryString) {
        const params = queryString.split('&');
        params.forEach(param => {
            UtmKeys.forEach(key => {
                if (param.startsWith(key)) {
                    const value = param.split('=')[1];
                    track(Category.UTM, key, value);
                }
            });
        });
    }
}

class AnalyticsEvent {
    readonly category: Category;
    readonly action: string;

    constructor(category: Category, action: string) {
        this.category = category;
        this.action = action;
    }

    track() {
        track(this.category, this.action);
    }
}

enum Category {
    App = 'App',
    Entry = 'Entry',
    Group = 'Group',
    User = 'User',
    UTM = 'UTM',
}

class Event {
    // App
    static BugReportFiled = new AnalyticsEvent(Category.App, 'BugReportFiled');

    // Entries
    static EntryCreated = new AnalyticsEvent(Category.Entry, 'Created');
    static EntryDeleted = new AnalyticsEvent(Category.Entry, 'Deleted');
    static EntryHidden = new AnalyticsEvent(Category.Entry, 'Hidden');
    static EntryUnhidden = new AnalyticsEvent(Category.Entry, 'Unhidden');
    static EntryLoved = new AnalyticsEvent(Category.Entry, 'Loved');
    static EntryUnloved = new AnalyticsEvent(Category.Entry, 'Unloved');
    static EntryMoreLoaded = new AnalyticsEvent(Category.Entry, 'MoreLoaded');
    static EntryPrivacyUpdated = new AnalyticsEvent(Category.Entry, 'PrivacyUpdated');

    // Group
    static LeaveGroup = new AnalyticsEvent(Category.Group, 'LeftGroup');
    static InvitationAccepted = new AnalyticsEvent(Category.Group, 'InvitationAccepted');
    static InvitationDeclined = new AnalyticsEvent(Category.Group, 'InvitationDeclined');
    static ModeratorAdded = new AnalyticsEvent(Category.Group, 'ModeratorAdded');
    static ModeratorRemoved = new AnalyticsEvent(Category.Group, 'ModeratorRemoved');

    // Invites
    static InviteSent = new AnalyticsEvent(Category.Group, 'InviteSent');
    static MultiUserInviteCreated = new AnalyticsEvent(Category.Group, 'InviteSent');

    // User
    static ChangedPassword = new AnalyticsEvent(Category.User, 'ChangedPassword');
    static ChangedTimezone = new AnalyticsEvent(Category.User, 'ChangedTimezone');
    static DeletedProfileImage = new AnalyticsEvent(Category.User, 'DeletedProfileImage');
    static ForgotPassword = new AnalyticsEvent(Category.User, 'ForgotPassword');
    static UserLoggedIn = new AnalyticsEvent(Category.User, 'LoggedIn');
    static UserRegistered = new AnalyticsEvent(Category.User, 'Registered');
    static UserSignedOut = new AnalyticsEvent(Category.User, 'SignedOut');
}

export const Analytics = { ...ReactGA, Category, Event, initialize, track, trackOutbound };
