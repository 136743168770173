import React, { ReactNode, useContext } from 'react';
import { UserContext } from '../context/UserContextProvider';
import LoadingSpinner from './LoadingSpinner';
import AuthPageContainer from './AuthPageContainer';
import PublicPageContainer from './PublicPageContainer';
import { RFC } from '../types/generalTypes';

//component will determine whether or not there's an authenticated user and wrap the page in auth nav content accordingly
const PageContainer: RFC<{ children: ReactNode }> = ({ children }) => {
    const { currentUser } = useContext(UserContext);
    if (currentUser === null) {
        return <PublicPageContainer>{children}</PublicPageContainer>;
    } else if (currentUser) {
        return <AuthPageContainer>{children}</AuthPageContainer>;
    }
    return <LoadingSpinner type="page" />;
};

export default PageContainer;
